import { getArrayLength } from "../../utils/formValidators";

const updateErrorObj = (errorObj: any, question: any, validator: any, index?: number) => {
  return [
    ...(!!errorObj?.[question.questionId] ? errorObj[question.questionId] : []),
    `${index !== undefined ? `${index}-` : ""}${validator.errorMessage}`,
  ];
};

export const validateSingleOptionSelectType = (question: any, value: any, errorObj: any) => {
  question.validators.forEach((validator: any) => {
    if (validator.name === "ARRAY_LENGTH_VALIDATOR") {
      if (!value) {
        errorObj[question.questionId] = updateErrorObj(errorObj, question, validator);
      }
    }
  });
};

export const validateTextType = (question: any, value: any, errorObj: any) => {
  question.validators.forEach((validator: any) => {
    if (validator.name === "MIN_LENGTH_VALIDATOR") {
      if (!value || (value && value?.value[0].length < Number(validator.value))) {
        errorObj[question.questionId] = updateErrorObj(errorObj, question, validator);
      }
    } else if (validator.name === "MAX_LENGTH_VALIDATOR") {
      if (!value || (value && value.value[0].length > Number(validator.value))) {
        errorObj[question.questionId] = updateErrorObj(errorObj, question, validator);
      }
    } else if (validator.name === "ARRAY_LENGTH_VALIDATOR") {
      // NEED TO DISCUSS IF SUPPORT FOR `ARRAY_LENGTH_VALIDATOR` IS ACTUALLY REQUIRED HERE
      if (
        !value ||
        (value && value?.value?.length < Number(validator.value)) ||
        !value?.value ||
        value?.value.every((item: any) => !item)
      ) {
        errorObj[question.questionId] = updateErrorObj(errorObj, question, validator);
      }
    }
  });
};

export const validateMultiQuestionType = (question: any, value: any, errorObj: any, inputLength: number) => {
  question.validators.forEach((validator: any) => {
    for (let i = 0; i < inputLength; i++) {
      if (validator.name === "MIN_LENGTH_VALIDATOR") {
        if (!value || (value && value?.value?.[i]?.length < Number(validator.value)) || !value?.value?.[i]) {
          errorObj[question.questionId] = updateErrorObj(errorObj, question, validator, i);
        }
      } else if (validator.name === "MAX_LENGTH_VALIDATOR") {
        if (!value || (value && value?.value?.[i]?.length > Number(validator.value)) || !value?.value?.[i]) {
          errorObj[question.questionId] = updateErrorObj(errorObj, question, validator, i);
        }
      } else if (validator.name === "NULL_VALIDATOR") {
        if (!value || (value && value?.value?.[i]?.length === 0) || !value?.value?.[i]) {
          errorObj[question.questionId] = updateErrorObj(errorObj, question, validator, i);
        }
      } else if (validator.name === "ARRAY_LENGTH_VALIDATOR") {
        if (
          !value ||
          (value && value?.value?.length < inputLength) ||
          !value?.value ||
          value?.value.every((item: any) => !item)
        ) {
          errorObj[question.questionId] = updateErrorObj(errorObj, question, validator, i);
        }
      }
    }
  });
};

const longSurveyValidateForm = (surveyDetails: any, formData: any) => {
  const { questions } = surveyDetails;
  const errorObj: any = {};
  questions.forEach((question: any) => {
    if (question.metaTags.includes("singleOptionSelect")) {
      validateSingleOptionSelectType(question, formData?.[question.questionId], errorObj);
    }
    if (question.metaTags.includes("text")) {
      validateTextType(question, formData?.[question.questionId], errorObj);
    }
    if (question.metaTags.includes("multiQuestion")) {
      question.questions.forEach((subQuestion: any) => {
        const inputsLength = getArrayLength(subQuestion.validators);
        validateMultiQuestionType(subQuestion, formData?.[subQuestion.questionId], errorObj, inputsLength);
      });
    }
  });

  return { isValid: !Object.values(errorObj).length, errorObj };
};

export { longSurveyValidateForm };
