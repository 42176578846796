import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindowF, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import mapStyles from "../../constants/mapStyling";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { GoogleMapGeoCodeApi } from "../../apis/GoogleMapGeoCodeApi";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import MapLoader from "../MapLoader";
import LocationInfoCard from "../LocationInfoCard";
import PinInfoMessage from "../PinInfoMessage";
import DateLocationInfoCard from "../DateLocationInfoCard";
import Dialog from "../Dialog";
import DateStoryDetails from "../DateStoryList/DateStoryDetails";

import mapPin from "./../../assets/icons/map-pin-maroon.svg";
import linePin from "./../../assets/icons/line-pin.svg";

import "./Map.scss";

const DEFAULT_POSITION = { lat: 40.7128, lng: -73.935 };

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const Map = ({ latLng, dateLocations, locationData, geoLocationDescription, children }: any) => {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY || "",
    libraries: ["places"],
  });
  const navigate = useNavigate();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [center, setCenter] = useState<any>(DEFAULT_POSITION);
  const [markerPosition, setMarkerPosition] = useState<any>(DEFAULT_POSITION);
  const [locationDetails, setLocationDetails] = useState<any>();
  const [userLocationDetails, setUserLocationDetails] = useState<any>();
  const [geoLocationDesc, setGeoLocationDesc] = useState<any>();
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [viewStoryDialog, setViewStoryDialog] = useState<boolean>(false);
  const [editStoryData, setEditStoryData] = useState<any>(null);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleOnLoad = (map: any) => {
    setMap(map);
  };

  const handleClick = (event: any) => {
    setShowInfoWindow(true);
  };

  const toggleInfoWindow = () => {
    setShowInfoWindow(!showInfoWindow);
  };

  const showUserLocation = async (position: { lat: number | undefined; lng: number | undefined }) => {
    let currentPosition = position;
    if (!currentPosition || (!!currentPosition && Object.values(currentPosition).includes(undefined))) {
      const response = await UserProfileAPI.getUserProfile(getAuthenticatedUserId(), false);
      if (!response?.location || !response?.location.length) {
        return;
      }
      currentPosition = {
        lat: response.location[1],
        lng: response.location[0],
      };
    }
    setCenter(currentPosition);
    setMarkerPosition(currentPosition);
    updateInfoWindowAddress(currentPosition);
  };

  const updateInfoWindowAddress = async ({ lat, lng }: any, updateAddress: boolean = false) => {
    const result = await GoogleMapGeoCodeApi.getAddressByLatLng(`${lat},${lng}`);
    if (result) {
      const { address_components, formatted_address } = result[0];
      const mainText = address_components[0].long_name;
      const secondaryText = formatted_address;
      // set user default location else update address to new position when user drags pin
      updateAddress
        ? setLocationDetails({
            formattedSuggestion: {
              mainText,
              secondaryText,
            },
          })
        : setUserLocationDetails({
            formattedSuggestion: {
              mainText,
              secondaryText,
            },
          });
      setGeoLocationDesc(result[0]);
      setMarkerPosition({ lat, lng });
      setShowInfoWindow(true);
    }
  };

  const closeViewStoryPopup = () => {
    setViewStoryDialog(false);
  };

  const handleDateLocationCardClick = (count: number, data: any) => {
    if (count === 1) {
      setEditStoryData(data);
      setViewStoryDialog(true);
    } else {
      navigate(`/my-stories?location=${data.location}`);
    }
  };

  const getDatePostion = (data: any, index: number) => {
    const position = { lat: data.location[1], lng: data.location[0] };
    return (
      <MarkerF icon={{ url: linePin }} key={index} position={position}>
        <InfoWindowF
          position={position}
          options={{ pixelOffset: new google.maps.Size(0, 12), disableAutoPan: true, maxWidth: 210, }}
        >
          <DateLocationInfoCard
            locationName={data.locationName}
            experienceCount={data.count}
            onCardClick={() => handleDateLocationCardClick(data.count, data)}
          />
        </InfoWindowF>
      </MarkerF>
    );
  };

  const saveMarkers = (data: any) => {
    const markerArr: any = [];
    if (data.length) {
      data.forEach((item: any, index: number) => {
        markerArr.push(getDatePostion(item, index));
      });
    }

    setMarkers(markerArr);
  };

  useEffect(() => {
    if (dateLocations.length && isLoaded) saveMarkers(dateLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateLocations, isLoaded]);

  useEffect(() => {
    showUserLocation(latLng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng]);

  useEffect(() => {
    locationData && setLocationDetails(locationData);
    geoLocationDescription && setGeoLocationDesc(geoLocationDescription);
    if (latLng && latLng.lat && latLng.lng) {
      setCenter(latLng);
      setMarkerPosition(latLng);
    }
  }, [latLng, locationData, geoLocationDescription]);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerClassName="Map"
        mapContainerStyle={containerStyle}
        options={{
          styles: mapStyles,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        center={center}
        zoom={13}
        onUnmount={onUnmount}
        onLoad={handleOnLoad}
      >
        <>
          {children}
          {markers}
          {(locationDetails || userLocationDetails) && (
            <MarkerF
              icon={{ url: mapPin }}
              position={markerPosition}
              onClick={handleClick}
              draggable={true}
              onDragStart={(e) => {
                setShowInfoWindow(false);
              }}
              onDragEnd={(e) => {
                const lat = e.latLng?.lat();
                const lng = e.latLng?.lng();
                updateInfoWindowAddress({ lat, lng }, true);
              }}
            >
              <>
                {showInfoWindow && (
                  <InfoWindowF
                    options={{
                      pixelOffset: new google.maps.Size(0, -90),
                      maxWidth: 162,
                    }}
                    position={markerPosition}
                  >
                    <PinInfoMessage />
                  </InfoWindowF>
                )}
                {showInfoWindow && (
                  <InfoWindowF
                    options={{
                      pixelOffset: new google.maps.Size(0, 260),
                    }}
                    position={markerPosition}
                    onCloseClick={toggleInfoWindow}
                  >
                    <LocationInfoCard
                      latLng={markerPosition}
                      locationDetails={locationDetails || userLocationDetails}
                      geoLocationDescription={geoLocationDesc}
                    />
                  </InfoWindowF>
                )}
              </>
            </MarkerF>
          )}
        </>
      </GoogleMap>
      {viewStoryDialog && (
        <Dialog onClose={() => closeViewStoryPopup()}>
          <DateStoryDetails storyData={editStoryData} />
        </Dialog>
      )}
    </>
  ) : (
    <MapLoader />
  );
};

export default React.memo(Map);
