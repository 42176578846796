import { subDays, subMonths } from "date-fns";
const now = new Date(new Date().setUTCHours(0,0,0,0));
const offsetInMilliseconds = (new Date()).getTimezoneOffset() * 60000;
const localISOTime = (new Date(Date.now() - offsetInMilliseconds)).toISOString().slice(0, -1);

export const getDropDownRange = (selectedValue: string) => {
  const options: any = {
    last_7_days: { startDate: subDays(now, 7), endDate: localISOTime },
    last_30_days: { startDate: subDays(now, 30), endDate: localISOTime },
    last_12_months: { startDate: subMonths(now, 12), endDate: localISOTime },
  };
  return options[selectedValue];
};
