import { TextField } from "@mui/material";

interface ITextAreaProps {
  name: string;
  helperText?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  placeholder?: string;
  rows?: number;
}

const TextArea: React.FC<ITextAreaProps> = ({
  name,
  label,
  helperText = "",
  value = "",
  disabled = false,
  error = false,
  readOnly = false,
  required = false,
  onChange,
  onBlur = () => null,
  endAdornment = <></>,
  startAdornment = <></>,
  placeholder,
  rows = 2,
}) => {
  return (
    <TextField
      id={name}
      name={name}
      helperText={helperText}
      {...(label ? { label } : { placeholder })}
      value={value}
      variant="filled"
      fullWidth
      disabled={disabled}
      error={error}
      required={required}
      InputProps={{
        endAdornment,
        readOnly,
        disableUnderline: true,
        startAdornment,
      }}
      multiline
      rows={rows}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextArea;
