import { Typography, IconButton, Box } from "@mui/material";

import { toast } from "../ToastNotification/ToastManager";

import twitterIcon from "../../assets/icons/twitter.svg";
import telegramIcon from "../../assets/icons/telegram.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import LinkIcon from "@mui/icons-material/Link";

const SocialShareStory = () => {
  const handleShare = (type: string) => {
    const ahref = `${window.location.origin}/world-date-stories`;
    const encodedAhref = encodeURIComponent(ahref);
    let link;

    switch (type) {
      case "facebook":
        link = `https://www.facebook.com/share.php?u=${encodedAhref}`;
        open(link);
        break;
      case "twitter":
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`;
        open(link);
        break;
      case "telegram":
        link = `https://telegram.me/share/url?url=${encodedAhref}`;
        open(link);
        break;
      case "copylink":
        navigator.clipboard.writeText(ahref);
        toast.show({
          message: "Link copied!",
        });
        break;
      default:
        break;
    }
  };

  const open = (socialLink: string) => {
    window.open(socialLink, "_blank");
  };

  return (
    <Box>
      <Typography sx={{ mb: 2, textAlign: "center" }} variant="subHeading2" component="p" color="redCarnationShade3">
        Share your date story
      </Typography>
      <Box>
        <IconButton
          sx={{ boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)", mr: 0.5 }}
          onClick={() => handleShare("twitter")}
        >
          <img alt="twitter" src={twitterIcon} />
        </IconButton>
        <IconButton
          sx={{ boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)", mr: 0.5, ml: 0.5 }}
          onClick={() => handleShare("facebook")}
        >
          <img alt="facebook" src={facebookIcon} />
        </IconButton>
        <IconButton
          sx={{ boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)", mr: 0.5, ml: 0.5 }}
          onClick={() => handleShare("telegram")}
        >
          <img alt="telegram" src={telegramIcon} />
        </IconButton>
        <IconButton
          sx={{ boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)", ml: 0.5 }}
          onClick={() => handleShare("copylink")}
        >
          <LinkIcon sx={{color: "greyTint8"}}/>
        </IconButton>
      </Box>
    </Box>
  );
};

export default SocialShareStory;
