import { ApexOptions } from "apexcharts";

const genderDonutChartOptions: ApexOptions = {
  chart: { animations: { enabled: false } },

  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: "40",
        labels: {
          show: true,
          name: {
            show: true,
            color: undefined,
            offsetY: 5,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontWeight: "700",
          },
          total: {
            showAlways: true,
            show: true,
            fontSize: "16px",
            fontWeight: "700",
            label: "Gender",
            formatter: function (w) {
              return "Statistics";
            },
          },
        },
      },
    },
  },
  tooltip: { enabled: false },
  dataLabels: { enabled: false },
  legend: { show: false },
  colors: ["#0067AC", "#FA0202", "#218380", "#4B0101", "#AD5962", "#4D4D4D", "#FEB3B3"],
  stroke: {
    width: 0,
  },
};
export default genderDonutChartOptions;
