import { FunctionComponent, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";

import { UserProfileAPI } from "../../../apis/UserProfileAPI";
import { UserProfileMetadataAPI } from "../../../apis/UserProfileMetadataAPI";

import DonutGraph from "../../../components/Graph";
import genderDonutChartOptions from "../../../constants/genderDonutChartOptions";
import skeletonDonutChartOptions from "../../../components/Graph/skeletonDonutChartOptions";
import AdminDashboardCard from "./AdminDashboardCard";
import AverageTrendCard from "./AverageTrendCard";
import GraphNoDataFound from "./GraphNoDataFound";

interface GenderStatisticsGraphProps {}

const GenderStatisticsGraph: FunctionComponent<GenderStatisticsGraphProps> = () => {
  const [graphSeriesData, setGraphSeriesData] = useState<ApexAxisChartSeries>([]);
  const [skeletonChartOptions, setSkeletonChartOptions] = useState<ApexAxisChartSeries>([]);
  const [loadingChartData, toggleChartLoadingState] = useState(true);
  const [averageData, setAverageData] = useState<any>({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 });
  const [genders, setGenders] = useState<any>();

  const getGenderStatistics = async () => {
    try {
      setGraphSeriesData([]);
      // Adding type any for a reason
      // Adding array of numbers is causing issues
      const skeletonValues: any = [100];
      setSkeletonChartOptions([...skeletonValues]);
      toggleChartLoadingState(true);
      const response = await UserProfileAPI.getGenderStatistics();
      const genderStats: any = [];
      genders.forEach((gender: any) => {
        const genderStatData = response.find((item: any) => item.key === gender.genderId);
        if (!!genderStatData) {
          genderStats.push(genderStatData);
        } else {
          genderStats.push({ key: gender.genderId, doc_count: 0 });
        }
      });
      populateExperienceTrendsResponse(genderStats);
    } catch {
      setGraphSeriesData([]);
    } finally {
      toggleChartLoadingState(false);
    }
  };

  const populateExperienceTrendsResponse = (genderResponse?: any) => {
    if (!genderResponse || !genderResponse?.length) return false;
    const sortedGenderResponse = genderResponse.sort((gender1: any, gender2: any) => gender1.key - gender2.key);
    setGraphSeriesData([...sortedGenderResponse.map(({ doc_count }: { doc_count: number }) => doc_count)]);
    const totalValue = sortedGenderResponse.reduce((current: number, acc: any) => {
      return current + acc.doc_count;
    }, 0);
    const averageData: any = {};

    sortedGenderResponse.forEach(({ key, doc_count }: any) => {
      averageData[key] = calculatePercentage(doc_count, totalValue);
    });
    setAverageData(averageData);
    toggleChartLoadingState(false);
  };

  const calculatePercentage = (value: number, total: number) => {
    const percentage = (value / total) * 100;
    return isNaN(percentage) ? 0 : Number(percentage.toFixed(2));
  };

  const getAllGenders = async () => {
    const genders = await UserProfileMetadataAPI.getAllGender();
    genders.sort((gender1: any, gender2: any) => gender1.genderId - gender2.genderId);
    setGenders(genders);
  };

  useEffect(() => {
    if (!genders) getAllGenders();
    if (genders?.length) {
      getGenderStatistics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genders]);

  const noDataContainerStyle = {
    height: 494,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <AdminDashboardCard title="Gender statistics" subtitle="Average of gender statistics using Datehouse">
      {!loadingChartData && (!graphSeriesData?.length || !genders.length) ? (
        <Box sx={noDataContainerStyle}>
          <GraphNoDataFound />
        </Box>
      ) : (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 5 }}>
          <Grid item md={6}>
            {!loadingChartData ? (
              <DonutGraph type="donut" seriesData={graphSeriesData} chartOptions={{ ...genderDonutChartOptions }} />
            ) : (
              <DonutGraph type="donut" seriesData={skeletonChartOptions} chartOptions={skeletonDonutChartOptions} />
            )}
          </Grid>
          <Grid item md={6}>
            {Boolean(!loadingChartData && graphSeriesData?.length) && (
              <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                {genders && genders?.length
                  ? genders?.map((gender: any, index: number) => (
                      <AverageTrendCard
                        key={index}
                        sx={{ width: "calc(50% - 24px)" }}
                        value={`${averageData[gender.genderId] || 0}%`}
                        label={gender.gender}
                        backgroundColor={genderDonutChartOptions?.colors?.[index]}
                      />
                    ))
                  : null}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </AdminDashboardCard>
  );
};

export default GenderStatisticsGraph;
