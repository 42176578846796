import { FC, useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';

import { IOptionValue } from './models';
import {
  DataTypes,
  IQuestion,
  OptionTypes,
  Validators,
} from '../../../../models/survey-management';
import { getArrayLength } from '../../../../utils/formValidators';

import Option from './Option';

const MultiQuestion: FC<{
  question: IQuestion;
  handleFieldsChange: Function;
}> = ({ question, handleFieldsChange }) => {
  const optionInitialState = {
    text: '', // Question text
    value: 0, // Value means number of inputs
  };

  const [subQuestions, setSubQuestions] = useState<{
    [position: number]: IOptionValue;
  }>({
    [1]: {
      ...optionInitialState,
    },
  });

  const totalOptions = useMemo(
    () => Object.keys(subQuestions).length,
    [subQuestions]
  );

  /**
   *
   * @param position
   * @param value
   * @param action
   * @description Handle update and delete of sub question
   */
  const handleChange = (
    position: number,
    value: IOptionValue | null,
    action: 'update' | 'delete' = 'update'
  ) => {
    let updatedOptions: typeof subQuestions = {};
    if (action == 'delete') {
      //  step:1 Crate copy of sub questions
      //  step:2 Delete sub questions
      //  step:3 Create final sub questions
      //  step:4 Update positions
      const options = { ...subQuestions };
      delete options[position];
      Object.entries(options).forEach(
        ([key, value], index) => (updatedOptions[index + 1] = value)
      );
    } else {
      updatedOptions = {
        ...subQuestions,
        ...(value && { [position]: value }),
      };
    }

    // Add validators to all sub questions object
    const questionsWithValidators = Object.entries(updatedOptions).map(
      ([key, value]) => ({
        allowOpenAnswer: false,
        dataType: DataTypes.stringArray,
        isMultiGroup: false,
        metaTags: [OptionTypes.multiTextInput],
        text: value.text,
        order: key,
        validators: [
          {
            name: Validators.nullValidator,
            value: null,
            errorMessage: 'Value cannot be null or empty.',
          },
          {
            name: Validators.arrayLengthValidator,
            value: `${value.value}`, // Value here is dynamic to change number of inputs
            errorMessage: 'Required',
          },
        ],
      })
    );

    // Update final questions list  with all sub questions
    handleFieldsChange({
      ...question,
      questions: questionsWithValidators,
      isMultiGroup: true,
      dataType: DataTypes.multiString,
      metaTags: [OptionTypes.multiQuestion],
      validators: [],
    });
  };

  const addNewOption = () => handleChange(totalOptions + 1, optionInitialState);

  /**
   * @param questions
   * @description Updates local state
   */
  const updatedQuestions = (questions: IQuestion['questions']) => {
    const questionsObj: typeof subQuestions = {};
    questions?.forEach(
      (question, index) =>
        (questionsObj[index + 1] = {
          text: question.text,
          value: getArrayLength(question.validators),
        })
    );
    setSubQuestions({ ...questionsObj });
  };

  useEffect(() => {
    if (question?.questions?.length) {
      updatedQuestions(question?.questions);
    }
  }, [question?.questions]);

  return (
    <>
      {Object.entries(subQuestions).map(([position, value], index) => (
        <Option
          key={position}
          value={value}
          handleChange={handleChange}
          position={Number(index + 1)}
          showDelete={totalOptions > 1}
        />
      ))}
      <Typography
        color="blue"
        variant="subHeading2"
        onClick={addNewOption}
        sx={{
          cursor: 'pointer',
          marginLeft: 2,
        }}
      >
        Add more sub question
      </Typography>
    </>
  );
};

export default MultiQuestion;
