export interface ISurvey {
  isDefault: boolean;
  isDeleted: boolean;
  title: string;
  questions: IQuestion[];
  userId: string;
  surveyId: string;
}

export interface IQuestion {
  isMultiGroup: boolean;
  allowOpenAnswer: boolean;
  text: string;
  dataType: string;
  order: any;
  possibleAnswers: string[];
  metaTags: string[];
  validators: Validator[];
  questions?: IQuestion[];
}

export interface Validator {
  name: string;
  value: string;
  errorMessage: string;
}

export enum OptionTypes {
  singleOptionSelect = 'singleOptionSelect',
  multiOptionSelect = 'multiOptionSelect',
  multiTextInput = 'multiTextInput',
  multiQuestion = 'multiQuestion',
  text = 'text',
}

export enum DataTypes {
  multiString = 'multiString',
  stringArray = 'stringArray',
  string = 'string',
  text = 'text',
}

export enum Validators {
  minLengthValidator = 'MIN_LENGTH_VALIDATOR',
  maxLengthValidator = 'MAX_LENGTH_VALIDATOR',
  nullValidator = 'NULL_VALIDATOR',
  arrayLengthValidator = 'ARRAY_LENGTH_VALIDATOR',
}
