import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import { LongSurveyAPI } from "../../apis/LongSurveyAPI";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import StoryList from "../../components/StoryList";
import BackButton from "../../components/BackButton";
import LongSurveyInfoBanner from "../../components/LongSurveyInfoBanner";

const MyStories = () => {
  const [isLongSurveySubmitted, setIsLongSurveySubmitted] = useState<boolean>(false);
  const [longSurveyBannerLoadedText, setLongSurveyBannerLoadedText] = useState<string>("");

  const checkLongSurveyStatus = async (surveyId: string) => {
    const response = await LongSurveyAPI.getLongSurveyByUserId(getAuthenticatedUserId(), surveyId);
    setLongSurveyBannerLoadedText("loaded");
    if (!!response) {
      setIsLongSurveySubmitted(true);
    }
  };

  useEffect(() => {
    const surveyId = localStorage.getItem("surveyId");
    if (surveyId) {
      checkLongSurveyStatus(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      sx={{
        height: "100%",
        py: { md: "7rem", xs: "3rem" },
        px: { md: "5rem", xs: "1rem" },
        overflow: "auto",
        position: "relative",
      }}
    >
      <BackButton
        sx={{
          position: "absolute",
          left: { md: "5rem", xs: "1rem" },
          top: { md: "7rem", xs: "3rem" },
        }}
      />
      <Grid item xs={0} lg={2}></Grid>
      <Grid item xs={12} lg={8} textAlign="center">
        <Box sx={{ mb: 6 }}>
          <QuestionAnswerIcon sx={{ fontSize: "3rem", color:"darkBurgundy" }} />
          <Typography variant="h1" mt={0.5}>
            My Stories
          </Typography>
          <Typography variant="body1" mt={1} color="greyTint8">
            You can find the list of all the stories completed here
          </Typography>
        </Box>
        {!isLongSurveySubmitted && longSurveyBannerLoadedText === "loaded" && <LongSurveyInfoBanner />}
        <StoryList />
      </Grid>
      <Grid item xs={0} lg={2}></Grid>
    </Grid>
  );
};

export default MyStories;
