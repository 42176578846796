import { Box, Typography } from "@mui/material";
import SingleSelectAnswer from "./SingleSelectAnswer";
import SubQuestions from "./SubQuestions";
import LongAnswer from "./LongAnswer";

interface IQuestionAnswer {
  questionDetails: any;
  errorObj: any;
  onChange: (id: string, value: string, index?: number) => void;
}

const QuestionAnswer = ({
  questionDetails,
  errorObj,
  onChange,
}: IQuestionAnswer) => {
  const { isMultiGroup, allowOpenAnswer } = questionDetails;

  return (
    <Box className="d-flex" mb={5} sx={{ gap: 1 }}>
      <Box>
        <Typography variant="h2" color="blackTint1">
          {questionDetails?.order || ""}.
        </Typography>
      </Box>
      <Box className="w-100">
        <Typography variant="h2" color="blackTint1" mb={3}>
          {questionDetails?.text || ""}
        </Typography>
        {allowOpenAnswer ? (
          <LongAnswer
            id={questionDetails.questionId}
            questionDetails={questionDetails}
            errorMessages={errorObj?.[questionDetails.questionId]}
            onChange={onChange}
          />
        ) : isMultiGroup ? (
          <SubQuestions
            id={questionDetails.questionId}
            questions={questionDetails.questions}
            errorMessages={errorObj}
            onChange={onChange}
          />
        ) : (
          <SingleSelectAnswer
            id={questionDetails.questionId}
            possibleAnswers={questionDetails.possibleAnswers}
            questionDetails={questionDetails}
            errorMessages={errorObj?.[questionDetails.questionId]}
            onChange={onChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default QuestionAnswer;
