import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface GraphNoDataFoundProps {}

const GraphNoDataFound: FunctionComponent<GraphNoDataFoundProps> = () => {
  return (
    <div className="d-flex progress-container justify-content-center align-items-center">
      <Typography variant="h2">No data available</Typography>
    </div>
  );
};

export default GraphNoDataFound;
