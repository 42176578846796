import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Logout from "./../pages/Logout";
import AdminDashboard from "./../pages/AdminPanel/AdminDashboard";
import AdminReports from "./../pages/AdminPanel/AdminReports";
import AdminUsers from "./../pages/AdminPanel/AdminUsers";
import SurveyManagement from "./../pages/AdminPanel/SurveyManagement";

import theme from "../theme";

import PageNotFound from "../pages/PageNotFound";

import PrivateRoute from "./../components/PrivateRoute";

import "./../App.scss";
import "./../assets/scss/global.scss";

const AdminPanelRouter = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute>
                <AdminUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/reports"
            element={
              <PrivateRoute>
                <AdminReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/survey-management"
            element={
              <PrivateRoute>
                <SurveyManagement />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AdminPanelRouter;
