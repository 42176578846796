import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";

import { getAuthenticatedUserId } from "../../../utils/authDetails";
import { IQuestion, ISurvey } from "../../../models/survey-management";
import { defaultQuestion } from "../../../constants/surveyMapping";
import { LongSurveyAPI } from "../../../apis/LongSurveyAPI";

import AdminLayout from "../../../layouts/AdminLayout";
import { toast } from "../../../components/ToastNotification/ToastManager";
import Card from "../../../components/Card";
import Button from "../../../components/Button";

import Loading from "./Loading";
import Question from "./Question";

const SurveyManagement = () => {
  const [questions, setQuestions] = useState<IQuestion[]>([defaultQuestion]);
  const [loading, setLoading] = useState<boolean>(true);
  const [longSurveyFormDetails, setLongSurveyFormDetails] = useState<ISurvey | null>(null);

  const totalQuestions = useMemo(() => questions.length, [questions]);

  /**
   * @param question
   * @description this method updates final list of question
   */
  const handleChangeInQuestion = useCallback(
    (question: IQuestion) => {
      const questionIndex = questions.findIndex((currentQuestion) => currentQuestion.order === question.order);
      const questionList = [...questions];
      if (questionIndex > -1) {
        questionList[questionIndex] = question;
      }
      setQuestions([...questionList]);
    },
    [questions]
  );

  /**
   * @param actionType
   * @param question
   * @description this method update add new card, create
   * duplicate of card and delete card
   */

  const handleCardActions = useCallback(
    (type: "add" | "duplicate" | "delete", question?: IQuestion) => {
      // Create deep clone of question array to avoid references issue
      let updatedQuestions = [...JSON.parse(JSON.stringify(questions))];
      switch (type) {
        // Push question at last position
        case "add":
          {
            updatedQuestions.push({ ...defaultQuestion });
          }
          break;
        // Deletes question at specific position
        case "delete":
          {
            updatedQuestions = updatedQuestions.filter((currQuestion) => currQuestion?.order != question?.order);
          }
          break;
        // Add duplicate card with values at specific position
        case "duplicate":
          {
            updatedQuestions.splice(question?.order, 0, question);
          }
          break;
      }
      // Update Order of all question
      for (let i = 0; i < updatedQuestions.length; i++) {
        updatedQuestions[i].order = i + 1;
      }
      // Set final list of questions
      setQuestions([...JSON.parse(JSON.stringify(updatedQuestions))]);
    },
    [questions]
  );

  const filterQuestions = (questions: any) => {
    const clonedQuestions = [...questions];
    const filteredClonedQuestions = clonedQuestions
      .filter((question) => !!question.text)
      .filter((question) => {
        if (question.possibleAnswers) {
          const clonedPossibleAnswers = [...question.possibleAnswers];
          question.possibleAnswers = clonedPossibleAnswers.filter((answer: string) => !!answer);
        }
        return question;
      });
    return filteredClonedQuestions;
  };

  const handleSaveClick = async () => {
    const filteredQuestions = filterQuestions(questions);

    try {
      if (longSurveyFormDetails?.surveyId) {
        const payload = {
          ...longSurveyFormDetails,
          questions: filteredQuestions,
        };
        await LongSurveyAPI.updateLongSurvey(getAuthenticatedUserId(), payload.surveyId, payload, () => {
          toast.show({
            message: "Updated successfully",
          });
        });
      } else {
        const payload = {
          isDefault: true,
          isDeleted: false,
          title: "Survey management templae" + new Date().toLocaleDateString(),
          questions: filteredQuestions,
        };
        await LongSurveyAPI.createLongSurvey(getAuthenticatedUserId(), payload, () => {
          toast.show({
            message: "Submitted successfully",
          });
        });
      }
    } catch (error: any) {
      toast.show({
        message: "Some error accured",
      });
    }
  };

  const getDefaultLongSurvery = async () => {
    const response = await LongSurveyAPI.getDefaultLongSurvey();
    if (response) {
      setLongSurveyFormDetails(response);
      if (response.questions.length) {
        setQuestions([...JSON.parse(JSON.stringify(response?.questions))] || []);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getDefaultLongSurvery();
  }, []);

  return (
    <AdminLayout>
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Typography mb={1} variant="h1">
          Survey management
        </Typography>
        <Typography mb={1.25} variant="subText2" color="blackTint4" component="p">
          You can manage the survey questions from here
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box pb={12}>
              {/* Iterate over all questions */}
              {questions?.map((question) => (
                <Question
                  key={question.order}
                  question={question}
                  handleActions={handleCardActions}
                  handleChange={handleChangeInQuestion}
                  totalQuestions={totalQuestions}
                />
              ))}
            </Box>
            <Card
              sx={{
                p: 3,
                borderRadius: 6.5,
                display: "flex",
                justifyContent: "end",
                position: "absolute",
                bottom: 10,
                right: 0,
                left: 0,
              }}
            >
              <Button onClick={handleSaveClick} label="Save" />
            </Card>
          </>
        )}
      </Box>
    </AdminLayout>
  );
};

export default SurveyManagement;
