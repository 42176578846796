import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import getCompleteGeoLocationDetails from "../../utils/completeGeoLocationDetails";

import { toast } from "../ToastNotification/ToastManager";
import Dialog from "../Dialog";
import AddStory from "../AddEditStory";

import { ReactComponent as LocationPin } from "./../../assets/icons/location-pin.svg";
import defaultLocationImage from "./../../assets/images/default-location-image.svg";

const LocationInfoCard = ({ latLng, locationDetails, geoLocationDescription }: any) => {
  const [openStoryDialog, setOpenStoryDialog] = useState<boolean>(false);
  const [completeGeoLocationDetails, setCompleteGeoLocationDetails] = useState<any>(null);

  const handleLocationInfoCardClick = () => {
    const { address, country, continent } = completeGeoLocationDetails;
    if (!address || !country || !continent) {
      toast.show({
        message: "Please select valid date location",
        error: true,
      });
      return;
    }
    setOpenStoryDialog(true);
  };

  useEffect(() => {
    if (locationDetails && geoLocationDescription) {
      const completeLocationDetails = getCompleteGeoLocationDetails(locationDetails, geoLocationDescription);

      setCompleteGeoLocationDetails(completeLocationDetails);
    }
  }, [locationDetails, geoLocationDescription]);

  return (
    <>
      <Box sx={{ cursor: "pointer" }} onClick={handleLocationInfoCardClick}>
        <Box
          sx={{
            height: 124,
            backgroundColor: "greyTint9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt={locationDetails.formattedSuggestion.mainText} src={defaultLocationImage} />
        </Box>
        <Box px={3} py={3} sx={{backgroundColor: "black"}}>
          <Typography mb={1} variant="h2">
            {locationDetails.formattedSuggestion.mainText}
          </Typography>
          <Typography sx={{display: "flex", alignItems: "center"}} variant="subText2" color="greyTint8">
            <LocationPin style={{ marginRight: "8px" }} />
            {locationDetails.formattedSuggestion.secondaryText}
          </Typography>
        </Box>
      </Box>
      {openStoryDialog && (
        <Dialog onClose={() => setOpenStoryDialog(false)}>
          <AddStory
            storyAction="add"
            completeGeoLocationDetails={completeGeoLocationDetails}
            storyLocation={[latLng.lng, latLng.lat]}
            onClose={() => setOpenStoryDialog(false)}
          />
        </Dialog>
      )}
    </>
  );
};

export default LocationInfoCard;
