import { Box, Typography } from "@mui/material";

import Button from "../Button";

import loginSignupInfoBannerImage from "./../../assets/images/login-signup-info-banner-image.svg";

type ILoginSignupInfoBannerProps = {
  onLoginClick: () => void;
  onSignUpClick: () => void;
};

const LoginSignupInfoBanner: React.FC<ILoginSignupInfoBannerProps> = ({ onLoginClick, onSignUpClick }) => {
  return (
    <Box p={3} mb={5} sx={{ backgroundColor: "burgundyTint9", borderRadius: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "center",
        }}
      >
        <Box mr={3} sx={{ width: 154, height: 82 }}>
          <img alt="login signup info banner" src={loginSignupInfoBannerImage} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: {
              xs: "center",
              md: "initial",
            },
          }}
          py={1}
          textAlign={{
            xs: "center",
            md: "left",
          }}
        >
          <Box>
            <Typography component="h2" mb={1} variant="subHeading1" color="burgundyShade9">
              Want to read more date stories?
            </Typography>
            <Typography variant="body1">
              <Typography fontWeight={700} component="span">
                Create your
              </Typography>{" "}
              account with Datehouse to read more date stories from all over the world.
            </Typography>
            <Typography mb={2} variant="body1">
              If you are already a Datehouse user, please{" "}
              <Typography fontWeight={700} component="span">
                Login
              </Typography>{" "}
              to your account.
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: 1.5,
                textTransform: "initial",
                mr: 1,
              }}
              variant="outlined"
              color="blackTint1"
              label="Create account"
              textVariant="subHeading3"
              onClick={onSignUpClick}
            />
            <Button
              sx={{
                borderRadius: 1.5,
                textTransform: "initial",
                minHeight: "1.75rem",
                padding: "4px 9px",
              }}
              label="Login"
              textVariant="subHeading3"
              onClick={onLoginClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginSignupInfoBanner;
