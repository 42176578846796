import MuiPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import './style.scss';
type Props = {
  totalPages: number,
  activePage: number,
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void
}

const Pagination = (props: Props) => {
  const {
    totalPages = 10,
    activePage = 1,
    handleChange = () => {}
  } = props;
  return (
    <Stack spacing={2}>
      <MuiPagination count={totalPages} page={activePage} onChange={handleChange} shape="rounded" />
    </Stack>
  )
}

export default Pagination;