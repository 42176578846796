import { FC, useState, ChangeEvent, ReactNode, useEffect } from 'react';
import {
  SelectChangeEvent,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { IOption } from './models';

import CloseIcon from '@mui/icons-material/Close';

const Option: FC<IOption> = ({ position, value, showDelete, handleChange }) => {
  const fieldsList = [
    {
      value: 1,
      label: '1 input field',
    },
    {
      value: 2,
      label: '2 input fields',
    },
    {
      value: 4,
      label: '4 input fields',
    },
  ];

  const [data, setData] = useState({
    question: '',
    fields: 0,
  });

  /**
   * @description Function called on each value
   * change and update local state
   * */

  const handleUpdate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    setData({
      question: event.target.value,
      fields: data.fields,
    });
  };

  /**
   * @description Function called when input losses focus
   * and update value in global list of questions
   * */
  const saveChange = () => {
    handleChange(position, {
      text: data.question,
      value: data.fields,
    });
  };

  /**
   * @description Function called to update total field value
   * and also update in global list of questions
   */
  const handleFieldChange = (event: SelectChangeEvent<number>) => {
    event.preventDefault();
    handleChange(position, {
      text: data.question,
      value: Number(event.target.value),
    });
  };

  const getField = (selected: number) =>
    fieldsList.find((field) => field.value == selected)?.label || '';

  useEffect(() => {
    setData({
      question: value.text,
      fields: value.value,
    });
  }, [value]);
  return (
    <>
      <Box
        className="d-flex"
        sx={{
          padding: 2,
          flexDirection: 'column',
          position: 'relative',
          '& .clearButton': {
            display: 'none',
          },
          '&:hover .clearButton': {
            display: 'flex',
          },
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          value={data.question}
          onChange={handleUpdate}
          onBlur={saveChange}
          placeholder="Enter sub question"
        />
        <FormControl variant="standard" sx={{ mt: 2, maxWidth: 400 }}>
          <Select
            displayEmpty
            value={data.fields}
            onChange={handleFieldChange}
            renderValue={(selected) => {
              if (selected === 0) {
                return <em>Select input fields</em>;
              }
              return getField(selected);
            }}
          >
            {fieldsList.map((field, index) => (
              <MenuItem key={index} value={field.value}>
                {field.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showDelete && (
          <IconButton
            onClick={() => handleChange(position, null, 'delete')}
            className="clearButton"
            sx={{
              position: 'absolute',
              right: 0,
              top: 5,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Option;
