import MuiChip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";

interface IChipProps {
  onClick?: () => void;
  label: string;
  variant?: "outlined" | "filled" | undefined;
  color?: "primary" | "blackTint1" | undefined;
}

const Chip = ({
  onClick,
  label = "",
  variant = "outlined",
  color,
}: IChipProps) => {
  const useStyles = makeStyles({
    root: {
      fontWeight: 600,
      fontSize: "16px",
      borderRadius: "10px",
      borderWidth: color === "primary" ? "2px" : "1px",
      padding: color === "primary" ? 0 : "0 1px",
    },
  });
  const classes = useStyles();
  return (
    <MuiChip
      label={label}
      variant={variant}
      color={color}
      onClick={onClick}
      classes={classes}
    />
  );
};

export default Chip;
