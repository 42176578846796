import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import Dialog from "../Dialog";

import addStorySuccessImage from "./../../assets/images/story_add_success.svg";

import "./SuccessMessageDialog.scss";

const SuccessMessageDialog = ({ onClose, onLogout }: any) => {
  const navigate = useNavigate();

  return (
    <Dialog showClose={false}>
      <Grid
        container
        sx={{
          maxWidth: "100%",
          width: "16.75rem",
          mx: "auto",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <img
            src={addStorySuccessImage}
            className="success_image"
            alt="survey success"
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h2">Submitted Successfully</Typography>
          <Typography variant="body1" mt={2}>
            Your answers are successfully submitted.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} mt={6}>
          <Button
            color="burgundy"
            type="button"
            label="Go to home"
            onClick={() => navigate("/")}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SuccessMessageDialog;
