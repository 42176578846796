import { Typography } from "@mui/material";
import { FunctionComponent } from "react";

import Card from "../../../components/Card";
import ".";

interface AverageTrendCardProps {
  sx?: any;
  label?: string;
  value?: string;
  backgroundColor?: string;
}

const AverageTrendCard: FunctionComponent<AverageTrendCardProps> = ({ sx, label, value, backgroundColor }) => {
  const cardStyle = { p: 1, mb: 3, borderRadius: 4 };
  return (
    <Card sx={{ ...cardStyle, ...sx }} extraClass="AverageExperienceTrend">
      <div className="AverageExperienceTrend_value" style={{ backgroundColor }}>
        <Typography variant="h2">{value}</Typography>
      </div>
      <div className="AverageExperienceTrend_label">
        <Typography variant="subHeading2"> {label}</Typography>
      </div>
    </Card>
  );
};

export default AverageTrendCard;
