import Harmonious from "./../assets/icons/harmonious.svg";
import Stressful from "./../assets/icons/stressful.svg";
import Draining from "./../assets/icons/draining.svg";
import Balanced from "./../assets/icons/balanced.svg";
import Uplifting from "./../assets/icons/uplifting.svg";
import Nurturing from "./../assets/icons/nurturing.svg";
import Revitalizing from "./../assets/icons/revitalizing.svg";
import Soothing from "./../assets/icons/soothing.svg";
import Enchanting from "./../assets/icons/enchanting.svg";
import Empowering from "./../assets/icons/empowering.svg";
import Fulfilling from "./../assets/icons/fulfilling.svg";
import Electrifying from "./../assets/icons/electrifying.svg";


export const getDateRatings = () => {
    return [
        {
            icon: Stressful,
            text: "Stressful",
            rating: 9,
        },
        {
            icon: Draining,
            text: "Draining",
            rating: 8,
        },
        {
            icon: Balanced,
            text: "Balanced",
            rating: 4,
        },
        {
            icon: Uplifting,
            text: "Uplifting",
            rating: 5,
        },
        {
            icon: Nurturing,
            text: "Nurturing",
            rating: 3,
        },
        {
            icon: Revitalizing,
            text: "Revitalizing",
            rating: 7,
        },
        {
            icon: Soothing,
            text: "Soothing",
            rating: 2,
        },
        {
            icon: Enchanting,
            text: "Enchanting",
            rating: 6,
        },
        {
            icon: Empowering,
            text: "Empowering",
            rating: 10,
        },
        {
            icon: Fulfilling,
            text: "Fulfilling",
            rating: 11,
        },
        {
            icon: Electrifying,
            text: "Electrifying",
            rating: 12,
        },
        {
            icon: Harmonious,
            text: "Harmonious",
            rating: 1,
        }
    ]
}

export const getIndexByRating = (rating: number) => {
    return getDateRatings().findIndex((item)=> item.rating === rating)
}

export const getDateRatingByRating = (rating: number) => {
    const index = getIndexByRating(rating);
    if(index === -1) return null;
    return getDateRatings()[index];
}