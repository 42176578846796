import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { useAuth0, User } from "@auth0/auth0-react";

import { UserExperienceAPI } from "../../apis/UserExperienceAPI";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { ISelectedGeoLocationDetails } from "../../models/location";
import { IExperienceDetails } from "../../models/experience";

import Stepper from "../Stepper";
import AddStoryStepOne from "./StepOne";
import AddStoryStepTwo from "./StepTwo";
import AddStoryStepThree from "./StepThree";
import AddStorySuccess from "./AddSuccess";
import { getAuthenticatedUserId } from "../../utils/authDetails";

interface IAddStoryProps {
  storyAction: string;
  storyLocation?: number[];
  completeGeoLocationDetails?: ISelectedGeoLocationDetails;
  editExperienceData?: IExperienceDetails;
  onClose: () => void;
}

const AddStory: React.FC<IAddStoryProps> = ({
  storyAction = "add",
  completeGeoLocationDetails,
  editExperienceData,
  storyLocation = [],
  onClose = () => {},
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [experienceData, setExperienceData] = useState<any>(
    storyAction === "add"
      ? {
          songLink: "",
          location: storyLocation,
          ...completeGeoLocationDetails,
        }
      : editExperienceData
  );
  const [storyCreatedDate, setStoryCreatedDate] = useState<string>("");

  // const { user }: User = useAuth0();

  const changeStep = (step: number, data: any, submitCall: boolean) => {
    setExperienceData({
      ...experienceData,
      ...data,
    });
    if (submitCall) {
      submitStoryHandler({
        ...experienceData,
        ...data,
      });
    } else {
      setCurrentStep(step);
    }
  };

  const updateStoryHandler = async (data: any) => {
    setApiLoading(true);
    try {
      const response = await UserExperienceAPI.updateUserExperience(
        // user.sub.replace("|", "_"),
        // "auth0_63e37f81b4b776e79a48af87",
        getAuthenticatedUserId(),
        data.id,
        {
          ...data,
        },
        () => {
          setCurrentStep(4);
          setStoryCreatedDate(data.createdDate);
        }
      );
      setApiLoading(false);
    } catch (err) {
      return err;
    }
  };

  const addStoryHandler = async (data: any) => {
    console.log(data);
    setApiLoading(true);
    try {
      const response = await UserExperienceAPI.postUserExperience(
        // user.sub.replace("|", "_"),
        // "auth0_63e37f81b4b776e79a48af87",
        getAuthenticatedUserId(),
        {
          ...data,
          createdDate: new Date().toISOString(),
        },
        () => {
          setCurrentStep(4);
        }
      );
      setApiLoading(false);
    } catch (err) {
      return err;
    }
  };

  const submitStoryHandler = (data: any) => {
    if (storyAction === "add") {
      addStoryHandler(data);
    } else {
      updateStoryHandler(data);
    }
  };

  const getUserNickname = async () => {
    const response = await UserProfileAPI.getUserProfile(
      // user.sub.replace("|", "_")
      // "auth0_63e37f81b4b776e79a48af87"
      getAuthenticatedUserId()
    );
    setExperienceData({
      ...experienceData,
      nickName: response.nickname,
      genderId: response.genderId,
      ageId: response.ageId,
    });
    // setSuggestions(response?.hashTags || []);
  };

  useEffect(() => {
    if (storyAction === "add") {
      getUserNickname();
    }
  }, []);

  return (
    <Grid container sx={{ textAlign: "center" }}>
      {currentStep !== 4 && (
        <Grid xs={12} item>
          <Typography variant="h1" sx={{ maxWidth: "100%", width: "26.25rem", mx: "auto" }}>
            Hello, please share your dating experience with us
          </Typography>
          {/* <Stepper steps={["1", "2", "3"]} step={currentStep} /> */}
        </Grid>
      )}
      {currentStep === 1 && <AddStoryStepOne experienceData={experienceData} successHandler={changeStep} />}
      {currentStep === 2 && (
        <AddStoryStepTwo experienceData={experienceData} successHandler={changeStep} backHandler={setCurrentStep} />
      )}
      {currentStep === 3 && (
        <AddStoryStepThree
          successHandler={changeStep}
          experienceData={experienceData}
          backHandler={setCurrentStep}
          storyAction={storyAction}
          apiLoading={apiLoading}
        />
      )}
      {currentStep === 4 && (
        <AddStorySuccess storyAction={storyAction} restartFlow={onClose} storyCreatedDate={storyCreatedDate} />
      )}
    </Grid>
  );
};

export default AddStory;
