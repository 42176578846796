export const REGIONS_OPTIONS = [
  {
    value: "Africa",
    label: "Africa",
  },
  {
    value: "Asia",
    label: "Asia",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Europe",
    label: "Europe",
  },
  {
    value: "North America",
    label: "North America",
  },
  {
    value: "South America",
    label: "South America",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
  },
];
