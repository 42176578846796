import { Box, Typography } from "@mui/material";

const DateLocationCountCard = ({ locationName, experienceCount }: any) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: "0px 0px 10px rgba(207, 206, 206)",
      }}
    >
      <Box pr={2.75} pl={1.5} py={1.5}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography mr={0.5} variant="h2">
            {experienceCount}
          </Typography>
          <Typography mb={0.25} variant="subText2">
            date stories
          </Typography>
        </Box>
        <Typography
          mb={1}
          variant="subHeading3"
          component="div"
          color="greyTint8"
        >
          {locationName.toUpperCase()}
        </Typography>
        <Typography variant="subHeading4" color="secondary.light">
          Click on pin to read stories
        </Typography>
      </Box>
    </Box>
  );
};

export default DateLocationCountCard;
