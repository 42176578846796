import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";

import { PAGE_SIZE } from "../../constants/global";
import { UserExperienceAPI } from "../../apis/UserExperienceAPI";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import StoryListItem from "./StoryListItem";
import EditStory from "../AddEditStory";
import Dialog from "../Dialog";
import StoryListSkeleton from "./StoryListSkeleton";
import DateStoryDetails from "../DateStoryList/DateStoryDetails";
import Pagination from "../common/Pagination";

const StoryList = () => {
  const [searchParams] = useSearchParams();
  const [openStoryDialog, setOpenStoryDialog] = useState<boolean>(false);
  const [viewStoryDialog, setViewStoryDialog] = useState<boolean>(false);
  const [editStoryData, setEditStoryData] = useState<any>(null);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [myLocations, setMyLocations] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalStories, setTotalStories] = useState<number>(0);
  const location = searchParams.get("location");
  const locationCordinates = location?.split(",");

  const setStoryHandler = (story: any) => {
    setEditStoryData(story);
    setOpenStoryDialog(true);
  };

  const viewStoryHandler = (story: any) => {
    setEditStoryData(story);
    setViewStoryDialog(true);
  };

  const closeStoryPopup = () => {
    setEditStoryData(null);
    setOpenStoryDialog(false);
    getMyLocations(1);
  };

  const closeViewStoryPopup = () => {
    setEditStoryData(null);
    setViewStoryDialog(false);
  };

  // Get list of my stories
  const getMyLocations = async (page: number) => {
    setCurrentPage(page);
    setApiLoading(true);
    try {
      const response: any =
        locationCordinates && locationCordinates?.length === 2
          ? await UserExperienceAPI.getUserStoriesLocations(
              getAuthenticatedUserId(),
              locationCordinates.map(Number),
              page - 1
            )
          : await UserExperienceAPI.getUserStories(getAuthenticatedUserId(), page - 1);
      setTotalStories(response.totalCount);
      setTotalPages(Math.ceil(response.totalCount / PAGE_SIZE));
      setMyLocations(response.userDatingExperiences || []);
      setApiLoading(false);
    } catch (error) {
      return error;
    }
  };

  const getToPage = (event: React.ChangeEvent<unknown>, value: number) => {
    getMyLocations(value);
  };

  useEffect(() => {
    getMyLocations(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box sx={{ textAlign: "left", }}>
      <Typography variant="h2">Total short stories ({totalStories})</Typography>
      <Grid container sx={{ mt: 1 }}>
        {apiLoading ? (
          Array(PAGE_SIZE)
            .fill(1)
            .map((element, i) => (
              <Grid item xs={12} sx={{ my: 1.5 }}>
                <StoryListSkeleton key={i} />
              </Grid>
            ))
        ) : myLocations.length ? (
          myLocations.map((location: any, index: number) => (
            <Grid key={index} xs={12} mt={1.5}>
              <StoryListItem
                storyData={location}
                shortVariant={locationCordinates && locationCordinates?.length === 2}
                setEditStoryData={setStoryHandler}
                setViewStoryData={viewStoryHandler}
              />
            </Grid>
          ))
        ) : (
          <Box mt={1.5} textAlign="center">
            No record found
          </Box>
        )}
      </Grid>
      {totalPages ? (
        <Grid xs={12} mt={4}>
          <Pagination activePage={currentPage} totalPages={totalPages} handleChange={getToPage} />
        </Grid>
      ) : null}
      {viewStoryDialog && (
        <Dialog onClose={() => closeViewStoryPopup()}>
          <DateStoryDetails storyData={editStoryData} />
        </Dialog>
      )}
      {openStoryDialog && (
        <Dialog onClose={() => closeStoryPopup()}>
          <EditStory storyAction="edit" editExperienceData={editStoryData} onClose={closeStoryPopup} />
        </Dialog>
      )}
    </Box>
  );
};

export default StoryList;
