// import { useAuth0, User } from "@auth0/auth0-react";
import * as yup from "yup";
import { Formik } from "formik";
import AddStoryStepTwoForm from "./form";

const validationSchema = yup.object({
    rating: yup.number().min(1).required("Please choose")
  //   songUrl: yup.string(),
});

const AddStoryStepTwo = (props: any) => {
    const { successHandler, experienceData, backHandler } = props;

    const values = {
        rating: experienceData.rating || 0
    };
    
    const handleSubmit = (data: any) => {
        successHandler(3, {
            ...data
        });
    };

    const handleBack = () => {
        
    }

    return (
        <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => <AddStoryStepTwoForm backHandler={backHandler} {...props} />}
        </Formik>
    );
};

export default AddStoryStepTwo;
