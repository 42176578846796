import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import LogoutIcon from "@mui/icons-material/Logout";
import PublicIcon from "@mui/icons-material/Public";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import Logo from "../../components/Logo";

import LogoutModal from "../LogoutDialog";
import DropdownMenu from "../DropdownMenu";
import "./NavBar.scss";

const NavBar = () => {
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);

  const options = [
    {
      icon: <FaceIcon />,
      text: "View profile",
      clickHandler: () => navigate("/user-details?mode=edit"),
    },
    {
      icon: <QuestionAnswerIcon />,
      text: "My stories",
      clickHandler: () => navigate("/my-stories"),
    },
    {
      icon: <PublicIcon />,
      text: "World stories map",
      clickHandler: () => navigate("/world-date-stories"),
    },
    {
      icon: <LogoutIcon />,
      text: "Logout",
      color: "redCarnation",
      clickHandler: () => setOpenLogoutDialog(true),
    },
  ];

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <div className="NavBar-logo">
          <Logo width="150" sx={{ width: "max-content" }} />
        </div>
        <DropdownMenu id="home" options={options} />
      </Grid>
      {openLogoutDialog && <LogoutModal onClose={() => setOpenLogoutDialog(false)} />}
    </>
  );
};

export default NavBar;
