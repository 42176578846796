import axios, { AxiosError } from "axios";

export const api = axios.create({
  // baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    withCredentials: false,
  },
});

// defining a custom error handler for all APIs
const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status;

  if (error.code === "ERR_CANCELED") {
    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  // return Promise.reject(error);
  return Promise.reject(error?.response?.data || error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
