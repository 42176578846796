import { useEffect, useState } from "react";

import { LongSurveyAPI } from "../../apis/LongSurveyAPI";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { longSurveyValidateForm, validateSingleOptionSelectType, validateTextType } from "./validations";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import { toast } from "../../components/ToastNotification/ToastManager";
import MainContainer from "../../components/common/MainContainer";
import SuccessMessageDialog from "../../components/SuccessMessageDialog";

import LongSurveyForm from "./form";
import LongSurveySkeleton from "./LongSurveySkeleton";

const LongSurvey = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [longSurveyFormDetails, setLongSurveyFormDetails] = useState<any>();
  const [longSurveyFormData, setLongSurveyFormData] = useState<any>(null);
  const [errorObj, setErrorObj] = useState<any>(null);
  const [userProfileData, setUserProfileData] = useState<any>(null);

  const getDefaultLongSurvery = async () => {
    setLoading(true);
    const response = await LongSurveyAPI.getDefaultLongSurvey();
    setLongSurveyFormDetails(response || []);
    setLoading(false);
  };

  const getUserProfile = async () => {
    const response = await UserProfileAPI.getUserProfile(getAuthenticatedUserId());
    setUserProfileData(response);
  };

  useEffect(() => {
    getDefaultLongSurvery();
    getUserProfile();
  }, []);

  const handleChange = (id: string, value: string, questionDetails: any, index?: number, parentQuestionId?: string) => {
    if (index === undefined) {
      if (questionDetails.metaTags.includes("singleOptionSelect")) {
        setErrorObj({
          ...errorObj,
          [questionDetails.questionId]: validateSingleOptionSelectType(questionDetails, [value], errorObj),
        });
      } else if (questionDetails.metaTags.includes("text")) {
        setErrorObj({
          ...errorObj,
          [questionDetails.questionId]: validateTextType(questionDetails, [value], errorObj),
        });
      }
      setLongSurveyFormData({
        ...longSurveyFormData,
        [id]: { value: [value], validators: questionDetails.validators },
      });
    } else {
      const oldArray = longSurveyFormData?.[id];
      if (!oldArray) {
        const updatedValue = { value: [value], validators: questionDetails.validators, parentQuestionId };
        setLongSurveyFormData({
          ...longSurveyFormData,
          [id]: updatedValue,
        });
      } else {
        const before = oldArray.value.slice(0, index);
        const after = oldArray.value.slice(index + 1);
        const updatedValuesArr = [...before, value, ...after];
        const updatedValue = {
          value: updatedValuesArr,
          validators: questionDetails.validators,
          parentQuestionId,
        };
        setLongSurveyFormData({
          ...longSurveyFormData,
          [id]: updatedValue,
        });
      }
      if (!!errorObj?.[questionDetails.questionId]) {
        const errorsByIndex = errorObj?.[questionDetails.questionId].filter(
          (error: any) => !error.includes(`${index}-`)
        );
        setErrorObj({
          ...errorObj,
          [questionDetails.questionId]: errorsByIndex,
        });
      }
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { isValid, errorObj } = longSurveyValidateForm(longSurveyFormDetails, longSurveyFormData);

    setErrorObj(errorObj);
    if (isValid) {
      submitForm();
    } else {
      toast.show({
        message: "Errors on the page, please resolve to proceed further!",
        error: true,
      });
    }
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      const answers = [];
      for (const property in longSurveyFormData) {
        answers.push({
          questionId: property,
          answers: longSurveyFormData[property].value,
          ...(longSurveyFormData[property].parentQuestionId && {
            parentQuestionId: longSurveyFormData[property].parentQuestionId,
          }),
        });
      }
      const formData = {
        ageId: userProfileData?.ageId,
        genderId: userProfileData?.genderId,
        name: userProfileData?.name,
        nationalityId: userProfileData?.nationalityId,
        answers,
      };
      const response = await LongSurveyAPI.postLongSurvey(
        getAuthenticatedUserId(),
        longSurveyFormDetails?.surveyId,
        formData
      );
      if (!!response) {
        setShowSuccessDialog(true);
      }
      setLoading(false);
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <MainContainer
        title="What does your dream date look like?"
        subtitle="We are creating a platform for the whole journey of relationships."
      >
        {loading ? (
          <LongSurveySkeleton />
        ) : (
          <LongSurveyForm
            longSurveyFormDetails={longSurveyFormDetails}
            errorObj={errorObj}
            loading={loading}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        )}
      </MainContainer>
      {showSuccessDialog && <SuccessMessageDialog />}
    </>
  );
};

export default LongSurvey;
