import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { format, addDays } from "date-fns";

import Button from "../../Button";
import Card from "../../Card";

const cardStyling = {
  padding: "1.5rem",
  borderRadius: "1rem",
  border: "1px solid #E6E6E6"
};

type IStoryListItemProps = {
  storyData: any;
  shortVariant?: boolean;
  setEditStoryData: (data: object) => void;
  setViewStoryData: (data: object) => void;
};

const StoryListItem: React.FC<IStoryListItemProps> = ({
  storyData = {},
  shortVariant = false,
  setEditStoryData = ({}) => {},
  setViewStoryData,
}) => {
  const navigate = useNavigate();
  const { count = 0, location, createdDate = "", locationName = "", address = "", editTill = "" } = storyData;

  const onEditHandler = () => {
    setEditStoryData(storyData);
  };

  const onViewHandler = () => {
    setViewStoryData(storyData);
  };

  const onViewStoriesHandler = () => {
    navigate(`/my-stories?location=${location}`);
  };

  const editTillDate = addDays(new Date(createdDate), 7);
  const canEdit = new Date() < editTillDate;

  const getEditStoryText = (shortVariant: boolean = false) => {
    return (
      <Typography
        variant="subHeading5"
        color="darkBurgundy"
        component="span"
        sx={{
          mt: shortVariant ? 0.5 : 0,
          mb: {
            xs: 0,
            md: shortVariant ? 0 : 2.5,
          },
        }}
      >
        (You can edit your date story till {format(editTillDate, "dd MMMM yyyy hh:mm a")})
      </Typography>
    );
  };

  return (
    <Card sx={cardStyling}>
      <Grid container>
        <Grid xs={12} md={7} textAlign="left">
          <Box sx={{ mb: shortVariant ? 0.5 : 2.5 }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
              <Typography
                variant="h2"
                component="p"
                sx={{ mb: shortVariant ? 0 : 1, mr: shortVariant ? 1 : 0 }}
              >
                {locationName}
              </Typography>
              {canEdit && editTillDate && shortVariant && getEditStoryText(true)}
            </Box>
            {!shortVariant && (
              <Typography
                variant="subText2"
                sx={{
                  display: "flex",
                  gap: 1,
                }}
                color="greyTint8"
              >
                <LocationOnIcon sx={{ fontSize: "1rem", color: "primary.main" }} />
                {address}
              </Typography>
            )}
          </Box>
          {count && count > 1 ? (
            <Typography variant="subHeading1" color="secondary.light">
              {count} Date stories posted
            </Typography>
          ) : null}
          {createdDate && (
            <Grid container spacing={0.5}>
              <Grid item>
                <Typography
                  variant="subHeading5"
                  component="span"
                  color="white"
                  sx={{ mr: 0.5, fontWeight: "bold" }}
                >
                  Completed On:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subHeading5" color="greyTint8" component="span">
                  {format(new Date(createdDate), "dd MMMM yyyy hh:mm a")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          md={5}
          display="flex"
          sx={{
            textAlign: {
              xs: "left",
              md: "right",
            },
            mt: {
              xs: "1rem",
              md: "0",
            },
            flexDirection: {
              xs: "row",
              md: "column",
            },
            alignItems: {
              xs: "center",
              md: "unset",
            },
            justifyContent: {
              xs: editTill ? "space-between" : "flex-end",
              md: editTill ? "space-between" : "center",
            },
          }}
        >
          {canEdit && editTillDate && !shortVariant && getEditStoryText()}
          <Box>
            <Button
              sx={{
                px: {
                  xs: "1rem",
                },
                py: {
                  xs: "0.375rem",
                },
              }}
              textVariant="subHeading5"
              label={count > 1 ? "View Stories" : canEdit ? "Edit story" : "View Story"}
              variant={count > 1 || canEdit ? "primaryDark" : "primaryLight"}
              onClick={count > 1 ? onViewStoriesHandler : canEdit ? onEditHandler : onViewHandler}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default StoryListItem;
