import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router-dom";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

interface IBackButtonProps {
    onClick?: () => void;
    sx?: any
}

const BackButton: React.FC<IBackButtonProps> = ({ onClick = null, sx = {} }) => {
  const isMobileDevice = useCheckMobileScreen()
    const navigate = useNavigate();
    const backHandler = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    }
  return <IconButton sx={{
    borderRadius: "50%",
    width: isMobileDevice ? "2rem" : "3rem",
    height: isMobileDevice ? "2rem" : "3rem",
    border: "1px solid #FF7BAC",
    background: "black",
    ...sx
  }} onClick={backHandler}>
    <KeyboardArrowLeftIcon sx={{color: "secondary.light"}}/>
  </IconButton>
};

export default BackButton;
