import store from "../store";

const getAuthToken = () => {
  const token = store.getState().keycloak.token;

  if (token) {
    return token;
  }
  return null;
};

const getAuthenticatedUserId = () => {
  const { keycloak } = store.getState().keycloak;

  if (keycloak?.tokenParsed) {
    return keycloak.tokenParsed.sub;
  }
  return "";
};

export { getAuthToken, getAuthenticatedUserId };
