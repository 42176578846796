import { Box, Typography } from "@mui/material";
import { useState } from "react";

import TextArea from "../../TextArea";

interface ILongAnswerProps {
  id: string;
  questionDetails?: any;
  errorMessages?: string[];
  onChange: (id: string, value: string, questionDetails?: any) => void;
}

const LongAnswer = ({
  id,
  questionDetails,
  errorMessages = [],
  onChange,
}: ILongAnswerProps) => {
  const [answer, setAnswer] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAnswer(value);
    onChange(id, value, questionDetails);
  };

  return (
    <Box>
      <TextArea
        name="shortNote"
        placeholder="Type your answer here..."
        value={answer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e)
        }
        rows={6}
        error={errorMessages?.length > 0}
      />
      {errorMessages?.length ? (
        <Box mt={0.5}>
          {errorMessages?.map((message: string, index: number) => (
            <Typography
              sx={{ display: "block" }}
              key={index}
              variant="smallText"
              color="redCarnation"
            >
              {message}
            </Typography>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default LongAnswer;
