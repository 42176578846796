import React, {useState, useEffect} from "react";
import { Box, Button } from "@mui/material";
import {getDateRatings, getIndexByRating} from "./../../utils/newDateRating";
import "./RatingButton.scss";

interface IRatingButtonsProps {
    onChange: (rating: number) => void,
    currentValue?: number
}
interface IIconRating {
    icon: any,
    text: string,
    rating: Number,
    isSelected?: Boolean,
}

const RatingButtons: React.FC<IRatingButtonsProps> = ({
    onChange = (rating: number) => {},
    currentValue = 0
  }) => {
    const [icons, setIcons] = useState<Array<IIconRating>>(getDateRatings());
    const [lastSelectedIcon, setLastSelectedIcon] = useState<IIconRating | null>(null);
    const [defaultRating] = useState<number>(currentValue);
    const [dateRating, setDateRating] = useState<number>(currentValue);

    const handleChange = (item: any) => {
        if(lastSelectedIcon) lastSelectedIcon.isSelected = false;
        item.isSelected = true;
        setIcons(icons);
        setLastSelectedIcon(item);
        setDateRating(item.rating);
        onChange(item.rating);
    }

    useEffect(() => {
        const index = getIndexByRating(defaultRating);
        if(index !== -1) {
            icons[index].isSelected = true;
            setIcons(icons);
            setLastSelectedIcon(icons[index]);
        }
        
    }, []);

    return <Box className="ratingBox">
        {icons.map(item => {
            return <Button key={item.text} onClick={()=>handleChange(item)} className={`ratingButton ${item.isSelected? "selected": ""}`} variant="outlined"  startIcon={<img alt={item.text} src={item.icon} width={25} />}>
            {item.text}
          </Button>
        })}
    </Box>;
};

export default RatingButtons;
