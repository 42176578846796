import { KEYCLOAK_INIT, SET_TOKEN } from "../constants";
import Keycloak from "keycloak-js";

export const keycloakInit = (payload: Keycloak) => ({
  type: KEYCLOAK_INIT,
  payload,
});

export const keycloakSetToken = (payload: string) => ({
  type: SET_TOKEN,
  payload,
});
