const getArrayLength = (validators: any) => {
  const arrayLength = validators.find(
    (validator: any) => validator.name === "ARRAY_LENGTH_VALIDATOR"
  ).value;

  return Number(arrayLength);
};

const getMaxLength = (validators: any) => {
  const maxLengthAllowed = validators.find(
    (validator: any) => validator.name === "MAX_LENGTH_VALIDATOR"
  )?.value;

  return maxLengthAllowed ? Number(maxLengthAllowed) : null;
};

const getMinLength = (validators: any) => {
  const minLengthAllowed = validators.find(
    (validator: any) => validator.name === "MIN_LENGTH_VALIDATOR"
  )?.value;

  return minLengthAllowed ? Number(minLengthAllowed) : null;
};

export { getArrayLength, getMinLength, getMaxLength };
