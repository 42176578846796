import { FunctionComponent, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import AdminDashboardCard from "./AdminDashboardCard";
import TabGroup from "../../../components/TabGroup";
import ColumnGraph from "../../../components/Graph";
import { ApexOptions } from "apexcharts";
import barChartOptions from "../../../components/Graph/barChartOptions";
import { UserExperienceAPI } from "../../../apis/UserExperienceAPI";
import { getDropDownRange } from "../../../utils/dateMethods";
import skeletonBarChartOptions from "../../../components/Graph/skeletonBarChartOptions";
import GraphNoDataFound from "./GraphNoDataFound";
interface LocationTrendGraphProps {}

const LocationTrendGraph: FunctionComponent<LocationTrendGraphProps> = () => {
  const [selectedTab, setSelectTab] = useState(0);
  const [graphSeriesData, setGraphSeriesData] = useState<ApexAxisChartSeries>([
    {
      name: `Percentage of users rate as best location`,
      data: [],
    },
  ]);
  const [skeletonChartSeries, setSkeletonChartSeries] =
    useState<ApexAxisChartSeries>([
      {
        name: "",
        data: [],
      },
    ]);
  const [loadingChartData, toggleChartLoadingState] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState("last_7_days");
  const [chartOptions, setChartOptions] =
    useState<ApexOptions>(barChartOptions);
  const tabsList = [
    {
      id: "active",
      label: "Best locations",
      color: "#218380",
    },
    {
      id: "new",
      label: "Worst locations",
      color: "#FA0202",
    },
  ];

  const getLocationData = async () => {
    try {
      graphSeriesData[0].data = [];
      setGraphSeriesData([...graphSeriesData]);
      setSkeletonChartSeries([{ ...skeletonChartSeries, data: [4, 2, 3, 4] }]);
      toggleChartLoadingState(true);
      const response = await UserExperienceAPI.getLocationTrends(
        getDropDownRange(selectedDateRange)
      );

      populateUserListResponse(selectedTab === 0 ? response[2] : response[1]);
    } finally {
      toggleChartLoadingState(false);
    }
  };

  const populateUserListResponse = (locationResponse?: any) => {
    if (!locationResponse) return;
    graphSeriesData[0].data = locationResponse.map(
      ({ rating }: { rating: number }) => rating
    );
    graphSeriesData[0].color = tabsList[selectedTab].color;

    setChartOptions({
      ...barChartOptions,
      xaxis: {
        ...barChartOptions.xaxis,
        type: "category",
        categories:
          [
            ...locationResponse.map(
              ({ location }: { location: number }) => location
            ),
          ] || [],
      },
      yaxis: {
        ...barChartOptions.yaxis,
        max:
          Math.max(
            ...locationResponse.map(({ rating }: { rating: number }) => rating)
          ) + 5,
        title: {
          text: "Percentage of users rate as best location",
          offsetX: 2,
        },
      },
      dataLabels: { ...barChartOptions.dataLabels, enabled: false },
      tooltip: {
        enabled: true,
        custom: (context) => {
          const type = tabsList[selectedTab].label.split(" ")[0].toLowerCase();

          return `<div class="location_trend_tooltip"><h3>${
            context?.w?.config?.xaxis?.categories[context.dataPointIndex]
          }</h4>
          <div><span class="address">${
            locationResponse[context.dataPointIndex].address
          }</span>
          </div>
          <div class="rating"><span class="rating_${type}">${
            context.series[0][context.dataPointIndex]
          }% users voted ${type} </span></div></div>`;
        },
      },
    });
    setGraphSeriesData([...graphSeriesData]);
    toggleChartLoadingState(false);
  };

  const noDataContainerStyle = {
    height: 494,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    getLocationData();
  }, [selectedTab, selectedDateRange]);

  return (
    <AdminDashboardCard
      title={"Location trend"}
      subtitle={"Top 10 best & worst locations for date"}
      showDropDown
      onSelectChange={(e) => setSelectedDateRange(e.target.value)}
    >
      <Box>
        <TabGroup
          tabs={tabsList}
          getCurrentTabValue={(currentValue: number) =>
            setSelectTab(currentValue)
          }
        />
        {Boolean(!loadingChartData && !graphSeriesData?.[0]?.data?.length) && (
          <Box sx={noDataContainerStyle}>
            <GraphNoDataFound />
          </Box>
        )}
        {Boolean(!loadingChartData && graphSeriesData?.[0]?.data?.length) && (
          <ColumnGraph
            type="bar"
            seriesData={graphSeriesData}
            chartOptions={chartOptions}
          />
        )}
        {loadingChartData && (
          <ColumnGraph
            type="bar"
            seriesData={skeletonChartSeries}
            chartOptions={{ ...skeletonBarChartOptions }}
          />
        )}
      </Box>
    </AdminDashboardCard>
  );
};

export default LocationTrendGraph;
