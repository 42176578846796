import { createTheme, StepIcon } from "@mui/material";
import { MuiButtonTheme } from "./themeOverrides/MuiButton";
import { MuiDialogTheme } from "./themeOverrides/MuiDialog";
import { MuiSliderTheme } from "./themeOverrides/MuiSlider";
import { MuiStepperTheme } from "./themeOverrides/MuiStepper";
import { MuiTextFieldTheme } from "./themeOverrides/MuiTextField";
import { MuiTooltipTheme } from "./themeOverrides/MuiTooltip";
import { MuiPaginationTheme } from "./themeOverrides/MuiPagination";
import { MuiChipTheme } from "./themeOverrides/MuiChip";

// custom typography variants - names are given from styleguide given in Figma designs
declare module "@mui/material/styles" {
  interface TypographyVariants {
    subHeading1: React.CSSProperties;
    subHeading2: React.CSSProperties;
    subHeading3: React.CSSProperties;
    subHeading4: React.CSSProperties;
    subHeading5: React.CSSProperties;
    subText1: React.CSSProperties;
    subText2: React.CSSProperties;
    smallText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subHeading1?: React.CSSProperties;
    subHeading2?: React.CSSProperties;
    subHeading3?: React.CSSProperties;
    subHeading4?: React.CSSProperties;
    subHeading5?: React.CSSProperties;
    subText1?: React.CSSProperties;
    subText2?: React.CSSProperties;
    smallText?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subHeading1: true;
    subHeading2: true;
    subHeading3: true;
    subHeading4: true;
    subHeading5: true;
    subText1: true;
    subText2: true;
    smallText: true;
  }
}

// custom colors  - names are given accoding to styleguide given in Figma designs
declare module "@mui/material/styles" {
  interface CustomPalette {
    burgundy: string;
    darkBurgundy: string;
    burgundyTint3: string;
    burgundyTint5: string;
    burgundyTint9: string;
    burgundyShade9: string;
    redCarnation: string;
    redCarnationShade3: string;
    redCarnationTint9: string;
    black: string;
    blackTint1: string;
    blackTint4: string;
    blackTint5: string;
    darkGrey: string;
    greyTint7: string;
    greyTint8: string;
    greyTint9: string;
    sapphireBlueTint9: string;
    white: string;
  }
  interface Palette extends CustomPalette {
    blackTint1: Palette["primary"];
    primaryLight: Palette["primary"];
  }
  interface PaletteOptions extends CustomPalette {
    blackTint1: PaletteOptions["primary"];
    primaryLight: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    burgundy: true;
    lightBurgundy: true;
    burgundyTint3: true;
    burgundyTint5: true;
    burgundyTint9: true;
    burgundyShade9: true;
    redCarnation: true;
    redCarnationTint9: true;
    redCarnationShade3: true;
    black: true;
    blackTint1: true;
    blackTint4: true;
    blackTint5: true;
    darkGrey: true;
    greyTint7: true;
    greyTint8: true;
    greyTint9: true;
    sapphireBlueTint9: true;
    white: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    burgundy: true;
    blackTint1: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: "#FF7BAC",
      light: "#FF7C89", // Burgundy
    },
    secondary: {
      main: "#FF7BAC", // Teal
      light: "#FF7BAC", // Sapphire Blue
    },
    primaryLight: {
      main: "#FF7C89",
      dark: "#FF7C89",
    },
    darkBurgundy: "#FF7BAC",
    burgundy: "#8A121E",
    burgundyTint3: "#AD5962",
    burgundyTint5: "#C5898F",
    burgundyTint9: "#F3E7E9",
    burgundyShade9: "#37070C",
    redCarnation: "#F9476B",
    redCarnationShade3: "#FF7C89",
    redCarnationTint9: "#FFE6E6",
    black: "#000000",
    blackTint1: {
      main: "#E6E6E6",
    },
    blackTint4: "#666666",
    blackTint5: "#AFAEAE",
    darkGrey: "#5E5C5C",
    greyTint7: "#858585",
    greyTint8: "#E6E6E6",
    greyTint9: "#b3b3b3",
    white: "#FFFFFF",
    sapphireBlueTint9: "#E7F5FF",
  },
  components: {
    MuiDialog: MuiDialogTheme,
    MuiStepper: MuiStepperTheme,
    MuiButton: MuiButtonTheme,
    MuiTextField: MuiTextFieldTheme,
    MuiSlider: MuiSliderTheme,
    MuiTooltip: MuiTooltipTheme,
    MuiPagination: MuiPaginationTheme,
    MuiChip: MuiChipTheme,
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#000'
        },
      },
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: `"AdamBold", "Mulish", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: 28,
      fontFamily: `"AdamBold", "Mulish", "Helvetica", "Arial", sans-serif`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      fontWeight: 800,
      lineHeight: 1.2,
      color: "#FF7BAC",
      textTransform: "uppercase"
    },
    h2: {
      fontSize: 20,
      fontFamily: `"AdamBold", "Mulish", "Helvetica", "Arial", sans-serif`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      fontWeight: 800,
      lineHeight: 1.3,
      color: "#FF7BAC",
    },
    h3: {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
      fontFamily: `"AdamBold", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontWeight: 700,
      lineHeight: 1.3,
      color: "#FF7BAC",
    },
    // TODO: find a way to update `fontFamily` centrally for custom variants too
    subHeading1: {
      fontFamily: `"Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.4,
      color: "#FF7BAC",
    },
    subHeading2: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.3,
      color: "#FF7BAC",
    },
    subHeading3: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.3,
      color: "#FF7BAC",
    },
    subHeading4: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1.4,
      color: "#E6E6E6",
    },
    subHeading5: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontWeight: 800,
      lineHeight: 1.3,
      color: "#E6E6E6",
    },
    subText1: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.4,
      color: "#FF7BAC",
    },
    subText2: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontWeight: 400,
      lineHeight: 1.3,
      color: "#FF7BAC",
    },
    body1: {
      fontSize: 18,
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontWeight: 500,
      lineHeight: 1.3,
      color: "#E6E6E6",
    },
    smallText: {
      fontFamily: `"MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif`,
      fontSize: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
      },
      fontWeight: 400,
      lineHeight: 1.4,
      color: "#b3b3b3",
    },
  },
});
export default theme;
