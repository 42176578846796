import { FunctionComponent, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/system/Box";

import AdminDashboardCard from "./AdminDashboardCard";

import DonutGraph from "../../../components/Graph";
import AverageExperienceTrendCard from "./AverageTrendCard";
import { UserExperienceAPI } from "../../../apis/UserExperienceAPI";
import { ApexOptions } from "apexcharts";
import donutChartOptions from "../../../components/Graph/donutChartOptions";
import { getDropDownRange } from "../../../utils/dateMethods";
import GraphNoDataFound from "./GraphNoDataFound";
import skeletonDonutChartOptions from "../../../components/Graph/skeletonDonutChartOptions";

interface ExperienceTrendGraphProps {}

const ExperienceTrendGraph: FunctionComponent<ExperienceTrendGraphProps> = () => {
  const [graphSeriesData, setGraphSeriesData] = useState<ApexAxisChartSeries>([]);
  const [skeletonChartOptions, setSkeletonChartOptions] = useState<ApexAxisChartSeries>([]);
  const [loadingChartData, toggleChartLoadingState] = useState(true);
  const [selectedTab, onTabChange] = useState<string | undefined>(undefined);
  const [selectedDateRange, setSelectedDateRange] = useState("last_7_days");
  const [averageData, setAverageData] = useState({ positive: 0, negative: 0 });
  const [chartOptions] = useState<ApexOptions>(donutChartOptions);

  const tabs = [
    { label: "Full world", value: undefined },
    { label: "North America", value: "North America" },
    { label: "South America", value: "South America" },
    { label: "Asia", value: "Asia" },
    { label: "Europe", value: "Europe" },
    { label: "Africa", value: "Africa" },
    { label: "Australia", value: "Australia" },
    { label: "Antartica", value: "Antartica" },
  ];
  const getExperienceTrends = async () => {
    try {
      setGraphSeriesData([]);
      // Adding type any for a reason
      // Adding array of nnumbers is causing issues
      const skeletonValues: any = [100];
      setSkeletonChartOptions([...skeletonValues]);
      toggleChartLoadingState(true);
      const response = await UserExperienceAPI.getExperienceTrends({
        ...getDropDownRange(selectedDateRange),
        continent: selectedTab,
      });
      populateExperienceTrendsResponse(response);
    } catch {
      setGraphSeriesData([]);
    } finally {
      toggleChartLoadingState(false);
    }
  };

  const populateExperienceTrendsResponse = (expResponse?: any) => {
    if (!expResponse || !expResponse?.length) return false;

    setGraphSeriesData([...expResponse.map(({ doc_count }: { doc_count: number }) => doc_count)]);

    const positiveValue = expResponse.find(({ key }: { key: number }) => key === 2)?.doc_count;
    const negativeValue = expResponse.find(({ key }: { key: number }) => key === 1)?.doc_count;
    const totalValue = expResponse.reduce((current: number, acc: any) => {
      return current + acc.doc_count;
    }, 0);

    setAverageData({
      positive: calculatePercentage(positiveValue, totalValue),
      negative: calculatePercentage(negativeValue, totalValue),
    });
    toggleChartLoadingState(false);
  };
  const calculatePercentage = (value: number, total: number) => {
    const percentage = (value / total) * 100;
    return isNaN(percentage) ? 0 : Number(percentage.toFixed(2));
  };
  useEffect(() => {
    getExperienceTrends();
  }, [selectedTab, selectedDateRange]);
  const noDataContainerStyle = {
    height: 494,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <AdminDashboardCard
      title={"Experience trend"}
      subtitle={"Average of positive or negative experiences"}
      showDropDown
      onSelectChange={(e) => setSelectedDateRange(e.target.value)}
    >
      <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
        {tabs.map(({ label, value }) =>
          value === selectedTab ? (
            <Chip key={value} label={label} color="secondary" onClick={() => onTabChange(value)} />
          ) : (
            <Chip key={value} variant="outlined" label={label} onClick={() => onTabChange(value)} />
          )
        )}
      </Stack>
      {!loadingChartData && !graphSeriesData?.length ? (
        <Box sx={noDataContainerStyle}>
          <GraphNoDataFound />
        </Box>
      ) : (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 5 }}>
          <Grid item md={6}>
            {!loadingChartData ? (
              <DonutGraph type="donut" seriesData={graphSeriesData} chartOptions={chartOptions} />
            ) : (
              <DonutGraph type="donut" seriesData={skeletonChartOptions} chartOptions={skeletonDonutChartOptions} />
            )}
          </Grid>
          <Grid item md={6} sx={{ px: 16 }}>
            {Boolean(!loadingChartData && graphSeriesData?.length) && (
              <div>
                <AverageExperienceTrendCard
                  value={`${averageData.positive}%`}
                  label={"Average of Positive Experience across the world"}
                  backgroundColor={"#218380"}
                />
                <AverageExperienceTrendCard
                  value={`${averageData.negative}%`}
                  label={"Average of Negative Experience across the world"}
                  backgroundColor={"#FA0202"}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </AdminDashboardCard>
  );
};

export default ExperienceTrendGraph;
