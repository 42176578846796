import { ChipProps, ChipClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiChipTheme: {
  defaultProps?: Partial<ChipProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof ChipClasses,
      "MuiChip",
      Omit<Theme, "components">
    >
  >;
} = {
    styleOverrides: {
        root: ({ theme }) => ({
        //   backgroundColor: theme.palette.primary.main,
          borderRadius: "10px",
        }),
    },
};