import { toast } from "../components/ToastNotification/ToastManager";
import { PAGE_SIZE } from "../constants/global";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const SUBMITTED_SURVEYS_ENDPOINT = "/submitSurveys";
const LONG_SURVEY_ENDPOINT = "/surveys";
const SUBMIT_LONG_SURVEY_ENDPOINT = "/submitSurveys/survey";
const UPDATE_LONG_SURVEY_ENDPOINT = "/surveys";

export const LongSurveyAPI = {
  getDefaultLongSurvey: async function (cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${LONG_SURVEY_ENDPOINT}/default`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getDefaultLongSurvey.name].handleRequestCancellation().signal : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  postLongSurvey: async function (
    userId: string,
    surveyId: string,
    data: any,
    successCallback = () => {},
    cancel = false
  ) {
    try {
      const response = await api.request({
        baseURL,
        url: `${SUBMIT_LONG_SURVEY_ENDPOINT}/${surveyId}`,
        method: "POST",
        data,
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postLongSurvey.name].handleRequestCancellation().signal : undefined,
      });
      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getLongSurveyByUserId: async function (userId: string, surveyId: string, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${SUBMIT_LONG_SURVEY_ENDPOINT}/${surveyId}`,
        method: "GET",
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getLongSurveyByUserId.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (errors: any) {
      if (errors?.statusCode === 404) {
        console.error(errors?.message);
      } else {
        toast.show({
          message: errors.message,
          error: true,
        });
      }
    }
  },

  createLongSurvey: async function (userId: string, data: any, successCallback = () => {}, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: LONG_SURVEY_ENDPOINT,
        method: "POST",
        data,
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postLongSurvey.name].handleRequestCancellation().signal : undefined,
      });
      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  updateLongSurvey: async function (
    userId: string,
    surveyId: string,
    data: any,
    successCallback = () => {},
    cancel = false
  ) {
    try {
      const response = await api.request({
        baseURL,
        url: `${UPDATE_LONG_SURVEY_ENDPOINT}/${surveyId}`,
        method: "PUT",
        data,
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postLongSurvey.name].handleRequestCancellation().signal : undefined,
      });
      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  // REPORTS ADMIN API
  filterLongSurveyReports: async function (data: any, pageNumber: number, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${SUBMITTED_SURVEYS_ENDPOINT}/search?offset=${pageNumber}&limit=${PAGE_SIZE}`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.filterLongSurveyReports.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  deleteLongSurvey: async function (surveyId: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${SUBMITTED_SURVEYS_ENDPOINT}/${surveyId}`,
        method: "DELETE",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.deleteLongSurvey.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getLongSurveyBySurveyId: async function (surveyId: string, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${LONG_SURVEY_ENDPOINT}/${surveyId}`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getLongSurveyBySurveyId.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (errors: any) {
      if (errors?.statusCode === 404) {
        console.error(errors?.message);
      } else {
        toast.show({
          message: errors.message,
          error: true,
        });
      }
    }
  },
};

// defining the cancel API object for LongSurveyAPI
const cancelApiObject: any = defineCancelApiObject(LongSurveyAPI);
