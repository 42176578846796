import { PaginationProps, PaginationClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiPaginationTheme: {
  defaultProps?: Partial<PaginationProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof PaginationClasses,
      "MuiPagination",
      Omit<Theme, "components">
    >
  >;
} = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        ".MuiPagination-ul": {
          justifyContent: "center"
        },
        button: {
          color:"#fff",
          fontWeight: 700,
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white"
          }
        }
      };
    }
  },
};
