export const decodeImage = (encodedImage: any, callback: any) => {
    const byteCharacters = atob(encodedImage);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let image = new Blob([byteArray], { type: "image/jpeg" });
    let imageUrl = URL.createObjectURL(image);
    callback(imageUrl);
};