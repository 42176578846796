import { Button as MuiButton, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface IButtonProps {
  disabled?: boolean;
  color?: "burgundy" | "lightBurgundy" | "redCarnation" | "blackTint1"; // custom color variants, these are added in theme.ts
  variant?:
    | "primaryDark"
    | "primaryLight"
    | "outlined"
    | "lightOutlined"
    | "blackOutlined"
    | "bright"
    | "iconWithTextButton"; // custom variants
  label: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  icon?: JSX.Element;
  textVariant?: any;
  sx?: any;
  startIcon?: any;
  loading?: boolean;
}

// Centralised Button config for different custom variants mapped with MUI button variants
const buttonVariantConfigMapping: {
  [key: string]: {
    variant: "contained" | "outlined" | "text";
    borderRadius: number;
    className?: string;
    px: number;
    py: number;
  };
} = {
  primaryDark: {
    variant: "contained",
    borderRadius: 2.5,
    px: 3,
    py: 1.5,
  },
  primaryLight: {
    variant: "contained",
    borderRadius: 2.5,
    className: "primaryLight",
    px: 2,
    py: 1.5,
  },
  bright: {
    variant: "contained",
    borderRadius: 2.5,
    className: "bright",
    px: 0.625,
    py: 0.5,
  },
  outlined: {
    variant: "outlined",
    borderRadius: 2.5,
    px: 1,
    py: 0.375,
  },
  lightOutlined: {
    variant: "outlined",
    borderRadius: 2.5,
    className: "lightOutlined",
    px: 1,
    py: 0.375,
  },
  blackOutlined: {
    variant: "outlined",
    borderRadius: 2.5,
    className: "blackOutlined",
    px: 1,
    py: 0.375,
  },
  iconWithTextButton: {
    variant: "outlined",
    borderRadius: 2.5,
    px: 1.5,
    py: 1,
  },
};

const Button: React.FC<IButtonProps> = ({
  color = "burgundy",
  label,
  type = "button",
  variant = "primaryDark",
  disabled = false,
  textVariant,
  icon,
  sx,
  loading,
  startIcon,
  onClick = () => null,
}) => {
  const { [variant]: variantConfig } = buttonVariantConfigMapping;
  const loaderColor = () => {
    switch (variant) {
      case "primaryDark":
      case "bright":
      case "primaryLight":
        return "#fff";
      case "outlined":
        return "primary.main";
      case "blackOutlined":
        return "#000";
      default:
        return "primary.main";
    }
  };
  return (
    <MuiButton
      color={color}
      sx={{
        borderRadius: variantConfig.borderRadius,
        textTransform: "capitalize",
        px: variantConfig.px,
        py: variantConfig.py,
        ...sx,
      }}
      type={type}
      disabled={disabled}
      variant={variantConfig.variant}
      onClick={onClick}
      className={variantConfig.className || ""}
      {...(startIcon && { startIcon })}
    >
      {loading ? (
        <CircularProgress
          size={14}
          sx={{
            color: loaderColor(),
          }}
        />
      ) : (
        <>
          {icon}
          <Typography component="span" variant={textVariant}>
            {label}
          </Typography>
        </>
      )}
    </MuiButton>
  );
};

export default Button;
