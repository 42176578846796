import React, { useState, useEffect, useRef } from "react";
import { SpotifyAPI } from "../../apis/SpotifyAPI";
import { format, addSeconds, addHours, addMinutes } from "date-fns";

import { Typography, Card, Box, CircularProgress } from "@mui/material";
import spotifyLogo from "../../assets/icons/spotify-logo-icon.svg";
import SearchResultDrop from "./SearchResultDrop";

import Button from "../Button";
import SingleSong from "./SearchResultDrop/SingleSong";

import "./style.scss";

interface SearchResult {
  id: string;
  name: string;
  album: {
    artists: {
      name: string;
    }[];
    images: {
      url: string;
    }[];
  };
  preview_url: string;
}

const SpotifyPicker: React.FC<{
  setSongLink?: (k: string) => void;
  activeSong?: string;
  viewmode?: boolean;
}> = ({ setSongLink = (k: string) => {}, activeSong, viewmode = false }) => {
  const [gettingActiveSong, setGettingActiveSong] = useState<boolean>(false);
  const [activeSongError, setActiveSongError] = useState<number>(0);
  const [spotifyError, setSpotifyError] = useState<boolean>(false);
  const [spotifyLoading, setSpotifyLoading] = useState<boolean>(true);
  const [spotifyToken, setSpotifyToken] = useState<string>(localStorage.getItem("spotify_token") || "");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cardRef = useRef(null);
  const open = Boolean(anchorEl);
  const [selectedSongDetails, setSelectedSongDetails] = useState<SearchResult | null>(null);

  const openSongList = (event: any) => {
    event.stopPropagation();
    if (!spotifyLoading && !gettingActiveSong) {
      setAnchorEl(cardRef.current);
    }
  };

  const closeSongList = () => {
    setAnchorEl(null);
  };

  const handleSelect = (song: SearchResult) => {
    const {
      id,
      name,
      album: {
        artists: [{ name: artistName }],
        images: [{ url }],
      },
      preview_url,
    } = song;

    // Divided songLink with |--|. First part is songId and second in song previewUrl

    setSongLink(`${id}|--|${preview_url}`);
    closeSongList();

    setSelectedSongDetails(song);
  };

  const getSpotifyToken = async () => {
    setSpotifyLoading(true);
    setSpotifyError(false);
    try {
      const response = await SpotifyAPI.getSpotifyToken();
      const { access_token, token_type, expires_in } = response;
      setSpotifyToken(`${token_type} ${access_token}`);
      if(token_type && access_token) {
        localStorage.setItem("spotify_token", `${token_type} ${access_token}`);
      }else {
        localStorage.setItem("spotify_token", '');
      }
      let expireDate = addSeconds(new Date(), expires_in);
      localStorage.setItem("spotify_expires_on", String(expireDate));
      setSpotifyLoading(false);
      getSingleSongDetails();
    } catch (error) {
      localStorage.removeItem("spotify_token");
      setSpotifyLoading(false);
      setSpotifyError(true);
      return error;
    }
  };
  const retryActiveSong = () => {
    if (activeSongError === 400) {
      getSpotifyToken();
    } else {
      getSingleSongDetails();
    }
  };

  const getSingleSongDetails = async () => {
    if (activeSong) {
      setActiveSongError(0);
      let songId = activeSong.split("|--|")[0];
      setGettingActiveSong(true);
      try {
        const response = await SpotifyAPI.getSingleTrack(songId, localStorage.getItem("spotify_token") || "");
        setGettingActiveSong(false);
        handleSelect(response.data);
      } catch (err: any) {
        setGettingActiveSong(false);
        setActiveSongError(err.error.status);
        return err;
      }
    }
  };

  const removeSong = () => {
    setSelectedSongDetails(null);
    setSongLink("");
  };

  const checkSpotifyTokenExpire = () => {
    const expireDate = localStorage.getItem("spotify_expires_on") || "";
    if (new Date(expireDate) < addMinutes(new Date(), 5)) {
      getSpotifyToken();
    } else {
      setSpotifyLoading(false);
      getSingleSongDetails();
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("spotify_token")) {
      getSpotifyToken();
    } else {
      checkSpotifyTokenExpire();
    }
  }, []);

  return (
    <Box>
      <Card
        ref={cardRef}
        variant="outlined"
        className="d-flex SpotifyPicker-card black-background grey-border1"
        sx={{
          p: 2,
          justifyContent: "space-between",
          borderRadius: 3,
          alignItems: "center",
          cursor: "pointer",
          ...(open && { borderColor: "burgundy" }),
        }}
        onClick={openSongList}
      >
        {selectedSongDetails && selectedSongDetails.id ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {activeSongError ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="subHeading2" color="primary.light">
                  Failed to load song data
                </Typography>
                <Button variant="primaryLight" onClick={retryActiveSong} label="Retry" />
              </Box>
            ) : (
              <>
                {gettingActiveSong ? (
                  <CircularProgress sx={{ fontSize: "1rem" }} />
                ) : (
                  <SingleSong
                    song={selectedSongDetails}
                    removeSong={removeSong}
                    isOpen={open}
                    editSong={openSongList}
                    showAction={!viewmode}
                  />
                )}
              </>
            )}
          </Box>
        ) : (
          <>
            {spotifyError ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="subHeading2" color="primary.light">
                  Failed to load Spotify data
                </Typography>
                <Button variant="primaryLight" onClick={getSpotifyToken} label="Retry" />
              </Box>
            ) : (
              <>
                <Box className="text-align-start">
                  <Typography variant="subHeading5" component="p">
                    Do you want to add a specific song?
                  </Typography>
                  {/* <Typography
                    sx={{ cursor: "pointer" }}
                    id="spotify-menu"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="smallText"
                    color="greyTint8"
                  >
                    Click & add the song from Spotify to your date
                  </Typography> */}
                </Box>
                <Box>
                  {spotifyLoading ? <CircularProgress size={15} /> : <img src={spotifyLogo} alt="spotify-logo" />}
                </Box>
              </>
            )}
          </>
        )}
      </Card>
      <SearchResultDrop
        anchorEl={anchorEl}
        open={open}
        handleClose={closeSongList}
        setSpotifyError={setSpotifyError}
        handleSelect={handleSelect}
        spotifyToken={spotifyToken}
        getSpotifyToken={getSpotifyToken}
      />
    </Box>
  );
};

export default SpotifyPicker;
