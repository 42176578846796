import { toast } from "../components/ToastNotification/ToastManager";
import { PAGE_SIZE } from "../constants/global";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const USER_EXPERIENCE_ENDPOINT = "/dating-experiences";

export const UserExperienceAPI = {
  postUserExperience: async function (userId: string, data: any, successCallback = () => {}, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: USER_EXPERIENCE_ENDPOINT,
        method: "POST",
        data,
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postUserExperience.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API

      // TODO: Add SuccessCallback

      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getUserStories: async function (userId: string, pageNumber: number, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/user?offset=${pageNumber}&limit=10`,
        method: "GET",
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getUserStories.name].handleRequestCancellation().signal : undefined,
      });

      // TODO: Add SuccessCallback
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getUserStoriesLocations: async function (userId: string, location: number[], pageNumber: number, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/userLocationSearch?offset=${pageNumber}&limit=10`,
        method: "POST",
        data: {
          location,
          isDetailed: true,
        },
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getUserStoriesLocations.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  updateUserExperience: async function (
    userId: string,
    storyId: string,
    data: any,
    successCallback = () => {},
    cancel = false
  ) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/${storyId}`,
        method: "PATCH",
        data,
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.updateUserExperience.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API

      // TODO: Add SuccessCallback

      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getHashtagsSuggestions: async function (userId: string, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/suggestHashTag`,
        method: "GET",
        // Need to replace with header userid
        headers: {
          UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getHashtagsSuggestions.name].handleRequestCancellation().signal
          : undefined,
      });

      // TODO: Add SuccessCallback
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  // ADMIN API
  /**
   *
   * @param userId
   * @param continent
   * @param cancel
   * @returns
   */
  getExperienceTrends: async function (
    params?: any,

    cancel = false
  ) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/trend`,
        method: "GET",
        params,
        // Need to replace with header userid
        headers: {
          // UserId: userId,
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getExperienceTrends.name].handleRequestCancellation().signal : undefined,
      });

      // TODO: Add SuccessCallback
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  // ADMIN API
  /**
   *
   * @param cancel
   * @returns
   */
  getLocationTrends: async function (params?: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/locationTrend`,
        params,
        method: "GET",
        // Need to replace with header userid
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getLocationTrends.name].handleRequestCancellation().signal : undefined,
      });

      // TODO: Add SuccessCallback
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  // REPORTS ADMIN API
  filterReports: async function (data: any, pageNumber: number, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/filterSearch?offset=${pageNumber}&limit=${PAGE_SIZE}`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.filterReports.name].handleRequestCancellation().signal : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  downloadShortStoriesCSV: async function (data: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/download`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.downloadShortStoriesCSV.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  deleteShortStory: async function (storyId: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_EXPERIENCE_ENDPOINT}/${storyId}`,
        method: "DELETE",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.deleteShortStory.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
};

// defining the cancel API object for UserExperienceAPI
const cancelApiObject: any = defineCancelApiObject(UserExperienceAPI);
