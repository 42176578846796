import { Box, Typography } from "@mui/material";

const PinInfoMessage = () => {
  return (
    <>
      <Box
        sx={{
          height: 72,
          px: 2,
          backgroundColor: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'MyriadProLightSemiExtended','Londrina Solid', cursive !important",
            textAlign: "center",
          }}
          variant="subHeading2"
          color="white"
        >
          Click on card to start your story
        </Typography>
      </Box>
    </>
  );
};

export default PinInfoMessage;
