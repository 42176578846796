import React from "react";
import { ReactComponent as AboveAverageEmoji } from "./../../assets/icons/above-average-icon.svg";

interface IRatingProps {
  rate: number;
  size?: "small" | "mid" | "large";
}

const ratingEmojiMapping: { [key: number]: JSX.Element } = {
  6: <AboveAverageEmoji />,
};

const Rating: React.FC<IRatingProps> = ({ rate, size = "small" }) => {
  return <div className="Rating">{ratingEmojiMapping[rate]}</div>;
};

export default Rating;
