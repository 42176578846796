import { actionType } from "../../models/action";
import { KEYCLOAK_INIT, SET_TOKEN } from "../constants";

const state = {
  keycloak: null,
  token: "",
};

const keycloakReducer = (initialState = state, action: actionType) => {
  switch (action.type) {
    case KEYCLOAK_INIT:
      return { ...initialState, keycloak: action.payload };
    case SET_TOKEN:
      return { ...initialState, token: action.payload };
    default:
      return initialState;
  }
};

export default keycloakReducer;
