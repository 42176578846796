import { Fragment, useEffect, useRef, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";

import { LongSurveyAPI } from "../../../../apis/LongSurveyAPI";
import Button from "../../../Button";

const LongSurvey = (props: any) => {
  const { name, datetime, surveyId, answers = [] } = props;
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [mappedAnswers, setMappedAnswers] = useState<any>();
  const printRef: any = useRef(null);

  const handleDownloadLongSurvey = useReactToPrint({
    content: () => printRef.current,
  });

  const generateQuestionAndAnswers = (survey: any) => {
    const clonedAnsers = [...answers];
    const mappedAnswers: any = [];
    clonedAnsers.forEach((answer: any) => {
      const currentQuestion: any = {};
      survey?.questions?.forEach((question: any) => {
        if (question.isMultiGroup && answer.parentQuestionId) {
          if (question.questionId === answer.parentQuestionId) {
            const currentMappedAnswer = mappedAnswers.find((item: any) => item.questionId === answer.parentQuestionId);
            const currentSubQuestion = question.questions.find((subQuestion: any) => {
              return subQuestion.questionId === answer.questionId;
            });
            if (currentMappedAnswer && currentMappedAnswer.answers.length) {
              currentMappedAnswer.answers.push(`${currentSubQuestion.text}: ${answer.answers.join(", ")}`);
            } else {
              currentQuestion.questionId = question.questionId;
              currentQuestion.text = question.text;
              currentQuestion.answers = currentQuestion.answers
                ? [...currentQuestion.answers, `${currentSubQuestion.text}: ${answer.answers.join(", ")}`]
                : [`${currentSubQuestion.text}: ${answer.answers.join(", ")}`];
            }
          }
        }

        if (question.questionId === answer.questionId && !answer.parentQuestionId) {
          currentQuestion.questionId = question.questionId;
          currentQuestion.text = question.text;
        }
      });
      if (Object.keys(currentQuestion).length) {
        mappedAnswers.push({
          questionId: currentQuestion.questionId,
          question: currentQuestion.text,
          answers: currentQuestion.answers ? currentQuestion.answers : answer.answers,
        });
      }
    });
    setMappedAnswers(mappedAnswers.filter((answer: any) => !!answer));
  };

  const getLongSurvey = async () => {
    setApiLoading(true);
    try {
      const response = await LongSurveyAPI.getLongSurveyBySurveyId(surveyId);
      generateQuestionAndAnswers(response);
      setApiLoading(false);
    } catch (error) {
      return error;
    } finally {
      setApiLoading(false);
    }
  };

  useEffect(() => {
    getLongSurvey();
  }, []);

  if (apiLoading) {
    return (
      <Typography variant="h2" color="blackTint1" textAlign="center">
        Loading...
      </Typography>
    );
  }

  return (
    <>
      <div style={{ padding: "16px" }} ref={printRef}>
        <Box>
          <Box className="text-align-center" mb={5}>
            <Typography variant="h1" mb={0.5} color="blackTint1">
              {name}
            </Typography>
            <Typography mr={0.5} variant="subHeading3" color="blackTint1">
              Posted On:
            </Typography>
            <Typography variant="subText2" color="blackTint4">
              {datetime && format(new Date(datetime), "dd MMMM yyyy")}
            </Typography>
          </Box>
          <Box my={3}>
            <Typography variant="h2" color="blackTint1" textAlign="center">
              Long survey
            </Typography>
          </Box>
          {mappedAnswers?.length > 0 &&
            mappedAnswers.map((item: any, index: number) => (
              <Fragment key={index}>
                <Box mb={1.5}>
                  <Box mb={0.5}>
                    <Typography variant="subText2" color="blackTint4">
                      {index + 1}. {item.question}
                    </Typography>
                  </Box>
                  <Box mb={1.5}>
                    {item.answers.map((answer: string) => (
                      <Typography variant="h2" color="blackTint1">
                        {answer}
                      </Typography>
                    ))}
                  </Box>
                  {index !== mappedAnswers.length - 1 && <Divider />}
                </Box>
              </Fragment>
            ))}
        </Box>
      </div>
      <Box textAlign="center">
        <Button label="Download as PDF" onClick={handleDownloadLongSurvey} variant="primaryDark" />
      </Box>
    </>
  );
};

export default LongSurvey;
