import { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { format } from "date-fns";

import TabPanel from "../../common/TabPanel";

import SocialShareStory from "../../SocialShareStory";
import ShortStory from "./ShortStory";
import LongStory from "./LongStory";

const DateStoryDetails = (props: any) => {
  const { showTabs = false, storyData = {}, showPostedDate = false } = props;
  const [activeTabValue, setActiveTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabValue(newValue);
  };

  return (
    <>
      <Box className="text-align-center" mb={5}>
        <Typography variant="h1" mb={0.5} color="blackTint1">
          {storyData.locationName}
        </Typography>
        {showPostedDate ? (
          <>
            <Typography mr={0.5} variant="subHeading3" color="blackTint1">
              Posted On:
            </Typography>
            <Typography variant="subText2" color="greyTint8">
              {storyData.createdDate && format(new Date(storyData.createdDate), "dd MMMM yyyy")}
            </Typography>
          </>
        ) : (
          <Typography variant="subText2" color="greyTint8">
            {storyData.address}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SocialShareStory />
      </Box>
      {showTabs && (
        <Box sx={{ mb: 4 }} className="d-flex justify-content-center">
          <Tabs value={activeTabValue} onChange={handleChange}>
            <Tab label="Short story" />
            <Tab label="Long story" />
          </Tabs>
        </Box>
      )}
      {showTabs ? (
        <>
          <TabPanel value={activeTabValue} index={0}>
            <ShortStory {...storyData} />
          </TabPanel>
          <TabPanel value={activeTabValue} index={1}>
            <LongStory />
          </TabPanel>
        </>
      ) : (
        <ShortStory showNickName={showPostedDate} {...storyData} />
      )}
    </>
  );
};

export default DateStoryDetails;
