import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import InputAnswer from "../InputAnswer";

interface IMultipleInputAnswersProps {
  id: string;
  parentQuestionId?: string;
  inputFieldsCount: number;
  questionDetails?: any;
  errorMessages?: string[];
  onChange(id: string, value: string, questionDetails?: any, index?: number, parentQuestionId?: string): void;
}

const MultipleInputAnswers = ({
  id,
  parentQuestionId,
  inputFieldsCount = 1,
  questionDetails,
  errorMessages = [],
  onChange,
}: IMultipleInputAnswersProps) => {
  const [errors, setErrors] = useState<any>(null);

  const handleChange = (value: string, index: number) => {
    onChange(id, value, questionDetails, index, parentQuestionId);
  };

  useEffect(() => {
    if (errorMessages) {
      const errors: any = {};
      errorMessages.forEach((message) => {
        const messageIndex = message.split("-")[0],
          messageText = message.split("-")[1];
        errors[messageIndex] = [...(errors?.[messageIndex] ? errors?.[messageIndex] : []), messageText];
      });
      setErrors(errors);
    }
  }, [errorMessages]);

  return (
    <Grid container spacing={3} mb={3}>
      {Array(inputFieldsCount)
        .fill(1)
        .map((inputField, index) => (
          <Grid item xs={12} lg={12 / inputFieldsCount} key={index}>
            <InputAnswer errorMessages={errors?.[index]} onChange={(value) => handleChange(value, index)} />
          </Grid>
        ))}
    </Grid>
  );
};

export default MultipleInputAnswers;
