import { toast } from "../components/ToastNotification/ToastManager";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";
import { Buffer } from "buffer";

const spotifyAuthBaseURL = process.env.REACT_APP_SPOTIFY_AUTH_URL;
const spotifyBaseURL = process.env.REACT_APP_SPOTIFY_BASE_API_URL;
const spotifyClientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const spotifyClientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

const SPOTIFY_AUTH_TOKEN = "/api/token";
const searchTracks = "/v1/search";
const getTracks = "/v1/tracks";

export const SpotifyAPI = {
  getSpotifyToken: async function (cancel = false) {
    try {
      const response = await api.request({
        baseURL: spotifyAuthBaseURL,
        url: `${SPOTIFY_AUTH_TOKEN}`,
        method: "POST",
        headers: {
          Authorization:
            `Basic ${Buffer.from(spotifyClientId + ":" + spotifyClientSecret).toString("base64")}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          grant_type: "client_credentials",
        },
        signal: cancel
          ? cancelApiObject[
              this.getSpotifyToken.name
            ].handleRequestCancellation().signal
          : undefined,
      });
      // returning the token details returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
      return errors;
    }
  },
  searchTracks: async function (
    trackName: string,
    token: string,
    cancel = false
  ) {
    return await api.request({
      baseURL: spotifyBaseURL,
      url: `${searchTracks}/?q=${trackName}&type=track`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      signal: cancel
        ? cancelApiObject[this.searchTracks.name].handleRequestCancellation()
            .signal
        : undefined,
    });
  },
  getSingleTrack: async function (
    trackId: string,
    token: string,
    cancel = false
  ) {
    return await api.request({
      baseURL: spotifyBaseURL,
      url: `${getTracks}/${trackId}?market=ES`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      signal: cancel
        ? cancelApiObject[this.searchTracks.name].handleRequestCancellation()
            .signal
        : undefined,
    });
  },
};

// defining the cancel API object for UserProfileAPI
const cancelApiObject: any = defineCancelApiObject(SpotifyAPI);
