import { Box, Typography } from "@mui/material";

import Dialog from "../../components/Dialog";
import Logo from "../../components/Logo";
import Button from "../../components/Button";

interface ITnCDialogProps {
  loading?: boolean;
  tncContent: any[];
  onClose: () => void;
  TnCAcceptButtonHandler: (isConsent: boolean) => void;
}

const TnCDialog = ({ loading = false, tncContent = [], onClose, TnCAcceptButtonHandler }: ITnCDialogProps) => {
  const acceptTnc = () => {
    TnCAcceptButtonHandler(true);
    onClose();
  };

  return (
    <Dialog onClose={onClose}>
      {loading ? (
        <Typography variant="h2" color="greyTint8" textAlign="center">
          Loading...
        </Typography>
      ) : (
        <>
          <Box className="text-align-center">
            <Logo sx={{ justifyContent: "center" }} />
            <Typography variant="h1" color="blackTint1" mt={1} mb={5}>
              Terms & Conditions
            </Typography>
          </Box>
          <Box
            p={3}
            sx={{
              border: "1px solid #EFEFEF",
              height: "50vh",
              overflowY: "auto",
            }}
            mb={5}
          >
            {tncContent.map((item) => (
              <Box key={item.id} mb={3} textAlign="justify">
                <Box mb={1} display="flex">
                  <Typography mr={2} variant="h3" color="greyTint8">
                    {item.id}
                  </Typography>
                  <Typography variant="h3" color="greyTint8">
                    {item.text}
                  </Typography>
                </Box>
                {item.points.map((subItem: any) => (
                  <Box key={subItem.id} mb={1}>
                    <Typography mr={2} variant="subText2" color="greyTint8">
                      {subItem.id}
                    </Typography>
                    <Typography variant="subText2" color="greyTint8">
                      {subItem.text}
                    </Typography>
                    {subItem.subPoints?.length && (
                      <Box mt={1} ml={4}>
                        {subItem.subPoints?.map((subPoint: any) => (
                          <Box key={subPoint.id} mb={1}>
                            <Typography mr={2} variant="subText2" color="greyTint8">
                              ({subPoint.id.split(".").slice(-1)})
                            </Typography>
                            <Typography variant="subText2" color="greyTint8">
                              {subPoint.text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box className="text-align-center">
            <Button type="button" label="I accept" onClick={acceptTnc} />
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default TnCDialog;
