import { SliderProps, SliderClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiSliderTheme: {
  defaultProps?: Partial<SliderProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof SliderClasses,
      "MuiSlider",
      Omit<Theme, "components">
    >
  >;
} = {
    styleOverrides: {
        root: ({theme}) => {
            return {
                ".MuiSlider-rail, .MuiSlider-track, .MuiSlider-mark": {
                    height: "1.25rem",
                    transition: "0.2s all ease-in-out"
                },
                ".MuiSlider-track": {
                    border: "none"
                },
                ".MuiSlider-rail": {
                    backgroundColor: '#8A8A8A',
                    opacity: 0.1
                },
                ".MuiSlider-mark": {
                    backgroundColor: "white",
                    width: "1px",
                    "&:nth-first-of-type(2)": {
                        display: "none"
                    },
                    "&:nth-last-of-type(2)": {
                        display: "none"
                    }
                },
                ".MuiSlider-thumb": {
                    transition: "0.2s all ease-in-out",
                    "&:after": {
                        boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)",
                        backgroundColor: "white",
                        width: "2.5rem",
                        height: "2.5rem"
                    },
                    "&:before": {
                        zIndex: 2,
                        backgroundColor: theme.palette.primary.main,
                        width: "1.25rem",
                        height: "1.25rem"
                    }
                }
            }
        },
    }
};