import { TooltipProps, TooltipClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTooltipTheme: {
  defaultProps?: Partial<TooltipProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TooltipClasses, "MuiTooltip", Omit<Theme, "components">>>;
} = {
  styleOverrides: {
    tooltip: ({ theme }) => {
      return {
        padding: "0.625rem",
        backgroundColor: theme.palette.black,
        border: "1px solid #fff",
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        maxWidth: "100%",
      };
    },
  },
};
