import { ApexOptions } from "apexcharts";
import { FunctionComponent, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface DonutGraphProps {
  seriesData: ApexAxisChartSeries;
  type?:any;
  // type?:
  // | "line"
  // | "area"
  // | "bar"
  // | "pie"
  // | "donut"
  // | "radialBar"
  // | "scatter"
  // | "bubble"
  // | "heatmap"
  // | "candlestick"
  // | "boxPlot"
  // | "radar"
  // | "polarArea"
  // | "rangeBar"
  // | "rangeArea"
  // | "treemap";
  chartOptions?: ApexOptions;
}

const DonutGraph: FunctionComponent<DonutGraphProps> = ({
  seriesData,
  type,
  chartOptions,
}) => {
  return (
    <ReactApexChart options={chartOptions} series={seriesData} type={type} />
  );
};

export default DonutGraph;
