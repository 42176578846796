import Box from "@mui/material/Box";
import { Typography, List, ListItem, Divider, ListItemButton } from "@mui/material";
import { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from "react-places-autocomplete";

import validateEnglishAddress from "../../utils/validateEnglishAddress";

import InputField from "../../components/InputField";
import Logo from "../../components/Logo";

import locatorIcon from "../../assets/icons/locator.svg";
import crossIcon from "../../assets/icons/cross-icon.svg";
import searchIcon from "../../assets/icons/search-icon.svg";

import "./style.scss";

interface IDateLocationSearchCardProps {
  isMobile?: boolean;
  isSearchCardExpanded?: boolean;
  setIsSearchCardExpanded?: any;
  onGeoLocationChange: any;
  onLocationChange: any;
}

const DateLocationSearchCard: React.FC<IDateLocationSearchCardProps> = ({
  isMobile,
  isSearchCardExpanded,
  setIsSearchCardExpanded,
  onGeoLocationChange,
  onLocationChange,
}) => {
  const [selectedPlace, setSelectedPlace] = useState<Suggestion | null>();
  const [dateLocationText, setDateLocationText] = useState<string>("");

  // Select location from suggestion
  const handleLocationClick = (location: Suggestion) => {
    geocodeByAddress(location.description)
      .then((results: any) => {
        onGeoLocationChange(results[0]);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        onLocationChange({ lat: latLng.lat, lng: latLng.lng }, location);
        compressLocationSearchCard();
      })
      .catch((error) => console.error("Error", error));
    setSelectedPlace(location);
  };

  const handleInputChange = (address: any) => {
    if (!!address) {
      const isValidAddressInEnglish = validateEnglishAddress(address);
      if (!isValidAddressInEnglish) return;
    }
    setDateLocationText(address);
    if (address && setIsSearchCardExpanded) {
      setIsSearchCardExpanded(true);
    }
  };

  const compressLocationSearchCard = () => {
    setIsSearchCardExpanded(false);
    setDateLocationText("");
  };

  return (
    <>
      <Box component="div" sx={{ textAlign: "center" }}>
        {isSearchCardExpanded ? <Logo sx={{ justifyContent: "center", mb: 5 }}/> : null} 
        <Typography sx={{ mb: 2 }} variant="h2">
          WHAT WAS YOUR DATE LOCATION
        </Typography>
        <Box
          sx={{
            mb: { xs: 3, md: 3 },
            px: { xs: 4, md: 8 },
          }}
        >
          <Typography variant="body1">
            Place the location pin on your date location or search & select your date location
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          flex: { xs: 1 },
          justifyContent: { xs: "space-between" },
        }}
      >
        <PlacesAutocomplete value={dateLocationText} onChange={handleInputChange}>
          {({ getInputProps, suggestions, loading }) => (
            <>
              <Box
                sx={{
                  px: { xs: 3.5, md: 6 },
                  position: "sticky",
                  top: 0,
                  pb: 1,
                  backgroundColor: "black",
                  zIndex: 2,
                }}
              >
                <InputField
                  {...getInputProps({
                    placeholder: "Search date location...",
                    className: "location-search-input",
                  })}
                  onBlur={undefined}
                  endAdornment={ <img src={searchIcon} alt="search-icon"/>}
                />
                <Box
                  sx={{
                    display: {
                      xs: isSearchCardExpanded ? "block" : "none",
                      md: "block",
                    },
                    mt: 3,
                    mb: 1,
                  }}
                >
                  {suggestions.length > 0 && <Typography variant="h3">Search suggestions</Typography>}
                  {loading && <Typography variant="subText1">Loading...</Typography>}
                </Box>
              </Box>
              {suggestions && suggestions.length ? (
                <Box
                  sx={{
                    display: {
                      xs: isSearchCardExpanded ? "block" : "none",
                      md: "block",
                    },
                    width: "100%",
                    flex: { xs: 1 },
                  }}
                >
                  <List>
                    {suggestions.map((location) => (
                      <Box key={location.placeId}>
                        <ListItem disablePadding>
                          <ListItemButton
                            sx={{
                              px: isMobile ? 2.5 : 6,
                              py: isMobile ? 2 : 2,
                              alignItems: "start",
                              gap: 1.5,
                            }}
                            selected={selectedPlace?.placeId === location.placeId}
                            onClick={() => handleLocationClick(location)}
                          >
                            <Box sx={{ mt: 0.5 }}>
                              <img src={locatorIcon} alt="locator-icon" />
                            </Box>
                            <Box>
                              <Box sx={{ mb: 0.5 }}>
                                <Typography variant="subHeading2">{location.formattedSuggestion.mainText}</Typography>
                              </Box>
                              <Box>
                                <Typography color="greyTint8" variant="subText2">{location.formattedSuggestion.secondaryText}</Typography>
                              </Box>
                            </Box>
                          </ListItemButton>
                        </ListItem>
                        {suggestions.length === 1 ? <></> : <Divider sx={{ mx: isMobile ? 2 : 6 }} />}
                      </Box>
                    ))}
                  </List>
                </Box>
              ) : null}

              {isSearchCardExpanded ? (
                <Box component="div" className="cross-icon" sx={{ textAlign: "center", position: "sticky", bottom: 0 }}>
                  <img src={crossIcon} alt="cross-icon" onClick={() => compressLocationSearchCard()} />
                </Box>
              ) : null}
            </>
          )}
        </PlacesAutocomplete>
      </Box>
    </>
  );
};

export default DateLocationSearchCard;
