import { toast } from "../components/ToastNotification/ToastManager";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const USER_PROFILE_ENDPOINT = "/content";

export const ContentAPI = {
  postUploadImage: async function (id: string, data: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/upload/user/${id}`,
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postUploadImage.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getUrlByFileName: async function (fileName: string, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/${fileName}`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.postUploadImage.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
};

// defining the cancel API object for ContentAPI
const cancelApiObject: any = defineCancelApiObject(ContentAPI);
