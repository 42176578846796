import { CircularProgress, Grid, Typography, Box, Divider } from "@mui/material";
import Dialog from "../Dialog";
import ProfileDataItem from "./ProfileDataItem";
import { useEffect, useState } from "react";
import Button from "../Button";
// import { useAuth0, User } from "@auth0/auth0-react";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { UserProfileMetadataAPI } from "../../apis/UserProfileMetadataAPI";
import { formatMetadata } from "../../utils/formatMetadata";
import { ContentAPI } from "../../apis/ContentAPI";
import { decodeImage } from "../../utils/decodeImage";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import DefaultAvatar from "../../assets/images/default_avatar.svg";

interface IViewProfileProps {
  userId?: string;
  onClose: () => void;
}

const ViewProfileDialog: React.FC<IViewProfileProps> = ({ userId = "", onClose = () => {} }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [userImage, setUserImage] = useState<string | null>(null);

  const getUserProfileMetadata = async () => {
    setLoading(true);
    const [ageGroupsData, gendersData, occupationsData, nationalitiesData, relationshipStatusesData] =
      await Promise.all([
        UserProfileMetadataAPI.getAllAgeGroups(),
        UserProfileMetadataAPI.getAllGender(),
        UserProfileMetadataAPI.getAllOccupation(),
        UserProfileMetadataAPI.getAllNationality(),
        UserProfileMetadataAPI.getAllRelationshipStatus(),
      ]);

    if (!!ageGroupsData && !!gendersData && !!occupationsData && !!nationalitiesData && !!relationshipStatusesData) {
      getUserProfileData({
        ageGroups: formatMetadata(ageGroupsData, "ageId", "age"),
        genders: formatMetadata(gendersData, "genderId", "gender"),
        occupations: formatMetadata(occupationsData, "occupationId", "occupation"),
        nationalities: formatMetadata(nationalitiesData, "nationalityId", "nationality"),
        relationshipStatuses: formatMetadata(relationshipStatusesData, "statusId", "status"),
      });
    }
  };

  const profileDataKeyMap: { [key: string]: string } = {
    name: "Name",
    nickname: "Nick name",
    age: "Age",
    gender: "Gender",
    occupation: "Occupation",
    nationality: "Nationality",
    relationshipStatus: "Relationship Status",
  };
  const findLabelInList = (
    list: {
      value: number;
      label: string;
    }[],
    keyToMatch: string,
    response: any
  ) => {
    return (
      list.find((a) => {
        return a.value === response[keyToMatch];
      })?.label || null
    );
  };
  const [profileData, setProfileData] = useState<{ [key: string]: string }>({});

  const getUserProfileData = async (referenceData: {
    [key: string]: {
      value: number;
      label: string;
    }[];
  }) => {
    const response = await UserProfileAPI.getUserProfile(
      // user.sub.replace("|", "_")
      // "auth0_63e37f81b4b776e79a48af87"
      !!userId ? userId : getAuthenticatedUserId()
    );
    if (!!response) {
      const age = findLabelInList(referenceData.ageGroups, "ageId", response);
      const nationality = findLabelInList(referenceData.nationalities, "nationalityId", response);
      const gender = findLabelInList(referenceData.genders, "genderId", response);
      const relationship = findLabelInList(referenceData.relationshipStatuses, "relationshipStatusId", response);
      const occupation = findLabelInList(referenceData.occupations, "occupationId", response);

      if (age && nationality && gender && occupation) {
        setProfileData({
          name: response.name,
          nickname: response.nickname,
          age: age,
          nationality: nationality,
          gender: gender,
          relationshipStatus: relationship || "",
          occupation: occupation,
        });
      }
      if (response.imageUrl) {
        getImgData(response.imageUrl);
      } else {
        setImageLoading(false);
      }
    }
    setLoading(false);
  };

  const getImgData = async (fileName: string) => {
    const imgResponse = await ContentAPI.getUrlByFileName(fileName);
    if (!!imgResponse) {
      decodeImage(imgResponse.body, setUserImage);
    }
    setImageLoading(false);
  };

  useEffect(() => {
    getUserProfileMetadata();
  }, []);

  return (
    <Dialog onClose={onClose}>
      <Grid container sx={{ textAlign: "center" }} px={2.5}>
        <Grid item xs={12} mb={6} textAlign="center">
          <Box
            sx={{
              width: "3.75rem",
              height: "3.75rem",
              boxShadow: "none",
              margin: "auto",
            }}
          >
            {userImage ? (
              <img
                alt="profile pic"
                src={userImage}
                style={{
                  width: "inherit",
                  height: "inherit",
                  borderRadius: 12,
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#EFEFEF",
                  borderRadius: 3,
                  boxShadow: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {imageLoading ? <CircularProgress size={20} /> : <img alt="avatar" src={DefaultAvatar} />}
              </Box>
            )}
          </Box>
          <Typography variant="h1">My profile</Typography>
        </Grid>
        {loading ? (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {Object.keys(profileDataKeyMap).map((key, i) => (
              <Grid item xs={12} textAlign="left" key={i}>
                <ProfileDataItem key={key} keyText={profileDataKeyMap[key]} value={profileData[key]} />
                {Object.keys(profileDataKeyMap).length !== i + 1 && <Divider sx={{ my: 2 }} />}
              </Grid>
            ))}
          </>
        )}
        <Grid item xs={12} mt={7}>
          <Button label="Close" onClick={onClose} variant="primaryDark" />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ViewProfileDialog;
