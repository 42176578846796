import {
  List,
  ListProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { ReactComponent as HeartIcon } from "./../../assets/icons/heart-icon.svg";

interface IListItemsProps extends ListProps {
  items: String[];
}

const ListItems: React.FC<IListItemsProps> = ({ items, ...props }) => {
  return (
    <List {...props}>
      {items.map((value, index) => (
        <ListItem key={index} disablePadding sx={{ mb: 3 }}>
          <ListItemIcon
            sx={{
              alignSelf: "flex-start",
              minWidth: "auto",
              mt: 1.25,
              mr: 1.5,
            }}
          >
            <HeartIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="subText1">{value}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ListItems;
