import { ButtonProps, ButtonClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiButtonTheme: {
  defaultProps?: Partial<ButtonProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof ButtonClasses,
      "MuiButton",
      Omit<Theme, "components">
    >
  >;
} = {
    styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          minWidth: "5.87rem",
          color: "#E6E6E6",
          minHeight: "2.62rem",
          "&:hover": {
            backgroundColor: theme.palette.darkBurgundy
          },
          "&:disabled": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
            opacity: 0.75
          },
          "&.primaryLight": {
            backgroundColor: theme.palette.primaryLight.main,
            "&:hover": {
              backgroundColor: theme.palette.primaryLight.dark,
            },
            "&:disabled": {
              backgroundColor: theme.palette.primaryLight.main,
              color: "rgba(255, 255, 255, 0.3)",
              opacity: 0.75
            },
          },
          "&.bright": {
            backgroundColor: theme.palette.redCarnation,
            minWidth: "3.75rem",
            padding: "0.5rem 0.625rem",
            fontSize: "0.75rem",
            minHeight: "2rem",
            "&:hover": {
              backgroundColor: theme.palette.redCarnationShade3,
            },
            "&:disabled": {
              backgroundColor: theme.palette.redCarnation,
              color: "rgba(255, 255, 255, 0.3)",
              opacity: 0.75
            }
          }
        }),
        "outlined": ({ theme }) =>  {
          return {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "8px",
            background: "none",
            minWidth: "4.375rem",
            minHeight: "1.75rem",
            color: theme.palette.primary.main,
            fontSize: "0.75rem",
            padding: "0.375rem 0.75rem",
            "&:hover": {
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.burgundyTint9,
              opacity: 0.75
            },
            "&:disabled": {
              border: `1px solid ${theme.palette.primary.main}`,
              background: "#fff",
              color: theme.palette.primary.main,
              opacity: 0.75
            },
            "&.lightOutlined": {
              border: "1px solid transparent",
              color: theme.palette.burgundyTint3,
              background: theme.palette.burgundyTint9,
              minHeight: "1.75rem",
              padding: "0.375rem 0.75rem",
              "&:hover": {
                border: `1px solid ${theme.palette.burgundyTint5}`,
                background: theme.palette.burgundyTint9,
                color: theme.palette.burgundyTint3,
              },
              "&:disabled": {
                border: "1px solid transparent",
                color: "rgba(173, 89, 98, 0.3)",
                background: theme.palette.burgundyTint9,
                opacity: 0.75
              },
            },
            "&.blackOutlined": {
              border: `1px solid ${theme.palette.greyTint8}`,
              color: theme.palette.blackTint1.main,
              padding: "0.375rem 0.75rem",
              minHeight: "1.75rem",
              "&:hover": {
                border: `1px solid ${theme.palette.blackTint1.main}`,
                background: "#fff",
              },
              "&:disabled": {
                border: `1px solid ${theme.palette.blackTint1.main}`,
                color: theme.palette.blackTint1.main,
                opacity: 0.75
              },
            }
          }
        }
    },
};