import { useState } from "react";
import { Box, Grid, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import DescriptionIcon from "@mui/icons-material/Description";
import QuizIcon from "@mui/icons-material/Quiz";

import { rootState } from "../../store";

import Card from "../../components/Card";
import Logo from "../../components/Logo";
import LogoutDialog from "../../components/LogoutDialog";

interface IAdminLayoutProps {
  logo?: JSX.Element;
  title?: string;
  description?: string;
  children: JSX.Element;
}

const ADMIN_NAVIGATIONS = [
  {
    id: "dashboard",
    icon: <AutoAwesomeMosaicIcon />,
    label: "Dashboard",
    route: "/admin/dashboard",
  },
  {
    id: "users",
    icon: <GroupIcon />,
    label: "Users",
    route: "/admin/users",
  },
  {
    id: "reports",
    icon: <DescriptionIcon />,
    label: "Reports",
    route: "/admin/reports",
  },
  {
    id: "survey-management",
    icon: <QuizIcon />,
    label: "Survey Management",
    route: "/admin/survey-management",
  },
];

const AdminLayout: React.FC<IAdminLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
  const keycloakState: any = useSelector((state: rootState) => state.keycloak.keycloak);

  return (
    <Box
      sx={{
        m: {
          xs: 2,
          md: 5,
        },
        display: "flex",
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={1} md={2.5}>
          <Card
            sx={{
              p: 4,
              borderRadius: 6,
              boxSizing: "border-box",
              height: "calc(100vh - 80px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <>
              <Box>
                <Logo sx={{ mb: 6, justifyContent: "center" }} />
                <Box
                  sx={{
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subHeading1">
                    {keycloakState?.tokenParsed?.given_name} {keycloakState?.tokenParsed?.family_name}
                  </Typography>
                  <Typography variant="subText2" color="blackTint4">
                    Admin
                  </Typography>
                </Box>
                <Box>
                  {ADMIN_NAVIGATIONS.map((option, index) => (
                    <MenuItem key={index} sx={{ px: 0, py: 1, mb: 1 }} onClick={() => navigate(option.route)}>
                      <ListItemIcon
                        sx={{
                          color: pathname === option.route ? "burgundy" : "blackTint1.main",
                        }}
                      >
                        {option.icon}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            color: pathname === option.route ? "burgundy" : "blackTint1.main",
                          }}
                          component="span"
                          variant="subHeading1"
                        >
                          {option.label}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Box>
              </Box>
              <Box>
                <MenuItem sx={{ px: 0, py: 1, mb: 1 }} onClick={() => setOpenLogoutDialog(true)}>
                  <ListItemIcon sx={{ color: "burgundy" }}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ color: "burgundy" }} component="span" variant="subHeading1">
                      Logout
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Box>
            </>
          </Card>
        </Grid>
        <Grid item xs={11} md={9}>
          {children}
        </Grid>
      </Grid>
      {openLogoutDialog && <LogoutDialog onClose={() => setOpenLogoutDialog(false)} />}
    </Box>
  );
};

export default AdminLayout;
