import { Box, Typography, Divider } from "@mui/material";

import { ratingStyleMap, ratingTextMap } from "../../../../utils/dateRating";
import {getDateRatingByRating} from "../../../../utils/newDateRating";
import SpotifyPicker from "../../../SpotifyPicker";

const ShortStory = (props: any) => {
  const {
    nickName,
    feeling = 1,
    shortNote = "",
    rating = 1,
    hashTags = [],
    songLink = "",
    showNickName = true,
  } = props;
  const newDateRating = getDateRatingByRating(rating);
  return (
    <Box>
      {nickName && showNickName && (
        <Box my={3}>
          <Typography variant="h2" color="blackTint1" textAlign="center">
            User Name ({nickName.trim()})
          </Typography>
        </Box>
      )}
      <Box mb={0.5} mt={showNickName ? 0 : 3}>
        <Typography variant="subText2" color="greyTint8">
          How do you want to describe your date?
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Typography variant="h2" color="blackTint1">
          {feeling === 1 ? "Worst" : "Amazing"}
        </Typography>
      </Box>
      <Box mb={0.5}>
        <Typography variant="subHeading3" color="blackTint1">
          Brief description
        </Typography>
      </Box>
      <Box mb={3.5}>
        <Typography variant="subText2" color="greyTint8">
          {shortNote}
        </Typography>
      </Box>
      <Box mb={0.5}>
        <Typography variant="subHeading3" color="blackTint1">
          Song of date
        </Typography>
      </Box>
      <Box mb={3}>
        <Box>
          {songLink ? (
            <SpotifyPicker activeSong={songLink} viewmode />
          ) : (
            <Typography variant="subText2" color="greyTint8">
              No song selected
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      <Box mb={0.5} mt={3.5}>
        <Typography variant="subText2" color="greyTint8">
          How do you rate your date?
        </Typography>
      </Box>
      <Box className="d-flex justify-content-between align-items-center" mb={3}>
        <Typography variant="h2" color="blackTint1">
          {newDateRating?.text || "Not Set"}
        </Typography>
        <img alt={newDateRating?.text  || "Not Set" } src={newDateRating?.icon || '#'} width={25} />
      </Box>
      <Divider />
      <Box mb={0.5} mt={3.5}>
        <Typography variant="subText2" color="greyTint8">
          Add hashtags for your date
        </Typography>
      </Box>
      <Box className="d-flex" sx={{ gap: 1.5, flexWrap: "wrap" }}>
        {hashTags &&
          hashTags.map((hashtag: string) => (
            <Typography key={hashtag} variant="h2" color="blackTint1">
              #{hashtag}
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

export default ShortStory;
