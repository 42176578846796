import { FunctionComponent, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

import { UserExperienceAPI } from "../../../apis/UserExperienceAPI";
import { LongSurveyAPI } from "../../../apis/LongSurveyAPI";

import ConfirmirmationDialog from "../../../components/ConfirmirmationDialog";
import Dialog from "../../../components/Dialog";
import DateStoryDetails from "../../../components/DateStoryList/DateStoryDetails";
import LongSurvey from "../../../components/DateStoryList/DateStoryDetails/LongStory";

import deleteUserImage from "./../../../assets/images/delete-user.svg";

interface AdminUserActionsProps {
  data?: any;
  showDelete?: boolean;
  appliedFilters?: any;
  currentTab?: any;
  updateTable: (value: boolean) => void;
}

const AdminUserActions: FunctionComponent<AdminUserActionsProps> = ({
  data,
  appliedFilters,
  currentTab,
  updateTable,
}) => {
  const [viewStoryDialog, setViewStoryDialog] = useState<boolean>(false);
  const [viewLongSurveyDialog, setViewLongSurveyDialog] = useState<boolean>(false);
  const [openDeleteShortStoryDialog, setOpenDeleteShortStoryDialog] = useState<boolean>(false);
  const [openDeleteLongSurveyDialog, setOpenDeleteLongSurveyDialog] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const handleDeleteShortStory = async () => {
    setApiLoading(true);
    try {
      const response = await UserExperienceAPI.deleteShortStory(data?.id);
      setTimeout(() => {
        setApiLoading(false);
        if (!!response) setOpenDeleteShortStoryDialog(false);
        updateTable(true);
      }, 2000);
    } catch (error) {
      setApiLoading(false);
      return error;
    }
  };

  const handleDeleteLongSurvey = async () => {
    setApiLoading(true);
    try {
      const response = await LongSurveyAPI.deleteLongSurvey(data?.id);
      setTimeout(() => {
        setApiLoading(false);
        if (!!response) setOpenDeleteLongSurveyDialog(false);
        updateTable(true);
      }, 2000);
    } catch (error) {
      setApiLoading(false);
      return error;
    }
  };

  return (
    <>
      <Tooltip title={currentTab === 0 ? "View" : "View & Download"}>
        <VisibilityIcon
          sx={{ color: "secondary.light", mr: 1.75, cursor: "pointer" }}
          onClick={() => {
            currentTab === 0 ? setViewStoryDialog(true) : setViewLongSurveyDialog(true);
          }}
        />
      </Tooltip>
      <Tooltip title="Delete">
        <DeleteIcon
          sx={{ color: "redCarnation", cursor: "pointer" }}
          onClick={() => (currentTab === 0 ? setOpenDeleteShortStoryDialog(true) : setOpenDeleteLongSurveyDialog(true))}
        />
      </Tooltip>
      {viewStoryDialog && (
        <Dialog onClose={() => setViewStoryDialog(false)}>
          <DateStoryDetails storyData={data} />
        </Dialog>
      )}
      {viewLongSurveyDialog && (
        <Dialog onClose={() => setViewLongSurveyDialog(false)}>
          <LongSurvey {...data} />
        </Dialog>
      )}
      {openDeleteShortStoryDialog && (
        <ConfirmirmationDialog
          image={deleteUserImage}
          title="Delete short story?"
          description="Are you sure you want to delete this short story permanently from Datehouse"
          primaryButtonLabel="Delete"
          apiLoading={apiLoading}
          onClose={() => setOpenDeleteShortStoryDialog(false)}
          primaryButtonHandler={handleDeleteShortStory}
        />
      )}
      {openDeleteLongSurveyDialog && (
        <ConfirmirmationDialog
          image={deleteUserImage}
          title="Delete long survey?"
          description="Are you sure you want to delete this long survey permanently from Datehouse"
          primaryButtonLabel="Delete"
          apiLoading={apiLoading}
          onClose={() => setOpenDeleteLongSurveyDialog(false)}
          primaryButtonHandler={handleDeleteLongSurvey}
        />
      )}
    </>
  );
};

export default AdminUserActions;
