import { ApexOptions } from "apexcharts";

const skeletonBarChartOptions: ApexOptions = {
  chart: {
    height: 350,
    toolbar: {
      show: false,
    },

    zoom: {
      enabled: false,
    },
  },
  dataLabels: { enabled: false },
  tooltip: { enabled: false },

  plotOptions: {
    bar: {
      columnWidth: "20%",
      borderRadius: 4,
      dataLabels: {
        position: "top",
      },
    },
  },

  xaxis: {
    categories: ["", "", "", ""],
    type: "category",

    axisBorder: {
      show: false,
    },
    crosshairs: { show: false },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
      rotate: 0,
      trim: true,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    show: false,

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: { show: false },
    labels: {
      show: false,
    },
  },
  colors: ["#EAECEF", "#F3F4F6"],
};
export default skeletonBarChartOptions;
