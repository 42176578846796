import { FC, memo, useEffect, useState } from 'react';

import {
  DataTypes,
  IQuestion,
  OptionTypes,
  Validators,
} from '../../../models/survey-management';
import { options } from '../../../constants/surveyMapping';

import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MultiOption from './MultiOption';
import MultiQuestion from './MultiQuestion';
import Card from '../../../components/Card';
import TextArea from '../../../components/TextArea';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Question: FC<{
  question: IQuestion;
  totalQuestions: number;
  handleActions: Function;
  handleChange: Function;
}> = memo(({ question, totalQuestions, handleChange, handleActions }) => {
  const [text, setText] = useState<string>('');
  const [type, setType] = useState<string>(OptionTypes.singleOptionSelect);

  /**
   * @description Function called on each value change
   * and update local state
   * */
  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setText(event.target.value);
  };

  /**
   * @description Function called when input losses focus and
   * update value in global list of questions
   * Also if option is long answer and then on text input need to updated
   * */
  const saveChange = () => {
    if (type === OptionTypes.text) {
      handleChange({
        ...question,
        text,
        allowOpenAnswer: true,
        dataType: DataTypes.text,
        metaTags: [DataTypes.text],
        validators: [
          {
            name: Validators.arrayLengthValidator,
            value: '1',
            errorMessage: 'Required',
          },
        ],
      });
    } else {
      handleChange({ ...question, text });
    }
  };

  /**
   * @description Function called to update question
   * type and also update in global list of questions
   */
  const handleQuestionTypeChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();
    setType(event.target.value);
    handleChange({ ...question, metaTags: [event.target.value] });
  };

  /**
   *
   * @param action
   * @description Handle card action like add, duplicate, and delete
   */
  const handleCardAction = (action: string) => {
    handleActions(action, question);
  };

  useEffect(() => {
    if (question) {
      setText(question.text);
      setType(question.metaTags[0]);
    }
  }, [question]);

  return (
    <Box className="d-flex flex-column">
      <Card
        sx={{
          borderRadius: 6,
          p: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <Box className="d-flex">
          <Grid item md={8}>
            <TextArea
              name=""
              onChange={handleQuestionChange}
              onBlur={saveChange}
              value={text}
              placeholder="Type your question here"
            />
            <Box mt={2} mb={2}>
              {type == OptionTypes.singleOptionSelect ? (
                <MultiOption
                  type="single"
                  question={question}
                  handleFieldsChange={handleChange}
                />
              ) : type == OptionTypes.multiQuestion ? (
                <MultiQuestion
                  question={question}
                  handleFieldsChange={handleChange}
                />
              ) : type == OptionTypes.multiOptionSelect ? (
                <MultiOption
                  type="multiple"
                  question={question}
                  handleFieldsChange={handleChange}
                />
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Grid item md={4} pl={4}>
            <Select
              sx={{
                width: '100%',
                borderRadius: 4,
              }}
              displayEmpty
              value={type}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={handleQuestionTypeChange}
            >
              {/* Iterate over all Dropdown choices */}
              {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <IconButton onClick={() => handleCardAction('duplicate')}>
            <ContentCopyIcon sx={{ color: 'secondary.main' }} />
          </IconButton>
          {totalQuestions > 1 && (
            <IconButton onClick={() => handleCardAction('delete')}>
              <DeleteIcon sx={{ color: 'redCarnation' }} />
            </IconButton>
          )}
        </Box>
      </Card>
      <IconButton
        sx={{ margin: 'auto' }}
        onClick={() => handleCardAction('add')}
      >
        <AddCircleOutlineIcon
          sx={{ color: 'secondary.main', fontSize: '3rem' }}
        />
      </IconButton>
    </Box>
  );
});

Question.displayName = 'Question';
export default Question;
