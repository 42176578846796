import { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import MapIllustrator from "../../assets/images/map-illustrator.svg";

import "./style.scss";

const MapLoader = () => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box className="map-loader-wrapper">
      <img src={MapIllustrator} alt="map-illustrator" />
      <Box
        sx={{
          mt: 4.5,
        }}
      >
        <Typography sx={{ mb: 1 }} variant="h1">
          Loading you map
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1.5,
          mb: 3,
        }}
      >
        <Typography variant="body1">
          Place the location pin on your date location or search & select your
          date location
        </Typography>
      </Box>
      <Card
        sx={{
          p: 1.5,
          borderRadius: 8,
          boxShadow: "0px 0px 10px rgba(207, 206, 206, 0.8)",
        }}
      >
        <LinearProgress
          className="linear-progress"
          variant="determinate"
          color="primary"
          value={progress}
          sx={{
            "--LinearProgress-radius": "64px",
            "--LinearProgress-progressThickness": "18px",
            "--LinearProgress-thickness": "18px",
            height: "18px",
            borderRadius: "64px",
            backgroundColor: "#EFEFEF",
          }}
        />
      </Card>
    </Box>
  );
};

export default MapLoader;
