import { Box, Typography } from "@mui/material";

import { getArrayLength } from "../../../utils/formValidators";

import MultipleInputAnswers from "../MultipleInputAnswers";

interface ISubQuestionsProps {
  id: string;
  questions: any;
  errorMessages?: any;
  onChange: (id: string, value: string, questionDetails?: any, index?: number, parentQuestionId?: string) => void;
}

const SubQuestions = ({ id, questions, errorMessages, onChange }: ISubQuestionsProps) => {
  return (
    <div>
      {(questions || []).map((questionDetails: any, index: number) => (
        <Box key={questionDetails.questionId}>
          <Typography variant="h3" color="blackTint1" mb={1.5}>
            {questionDetails?.text || ""}
          </Typography>
          <MultipleInputAnswers
            id={questionDetails.questionId}
            parentQuestionId={id}
            inputFieldsCount={getArrayLength(questionDetails.validators)}
            questionDetails={questionDetails}
            errorMessages={errorMessages?.[questionDetails.questionId]}
            onChange={onChange}
          />
        </Box>
      ))}
    </div>
  );
};

export default SubQuestions;
