import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { Typography, Grid, Box, Tabs, Tab } from "@mui/material";

import { WorldMapStoriesAPI } from "../../apis/WorldMapStoriesAPI";

import BackButton from "../../components/BackButton";
import DateStoryList from "../../components/DateStoryList";
import Pagination from "../../components/common/Pagination";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import LoginSignupInfoBanner from "../../components/LoginSignupInfoBanner";

import StoryImageLimit from "../../assets/images/story_limit.svg";

import "./style.scss";

const PAGE_SIZE = 10;

const DateStories = () => {
  const [reachedStoryLimit, setReachedStoryLimit] = useState(false);
  const [activeTabValue, setActiveTabValue] = useState(2);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dateStories, setDateStories] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [feelingCount, setFeelingCount] = useState<{ [key: number]: number }>({
    1: 0,
    2: 0,
  });
  const { state: locationData } = useLocation();
  const { keycloak } = useKeycloak();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabValue(newValue);
  };

  const getDateStories = async (page: number) => {
    setCurrentPage(page);
    setApiLoading(true);
    try {
      const response = await WorldMapStoriesAPI.getAllDateStoriesByLocation(
        {
          location: [locationData.position.lng, locationData.position.lat],
          isDetailed: true,
          feelingType: activeTabValue,
        },
        page - 1
      );
      const feelingCountData: { [key: number]: number } = {};
      response.forEach((feelingData: any) => {
        feelingCountData[feelingData.feeling] = feelingData.feelingCount;
      });
      if (!feelingCountData[2] && feelingCountData[1]) setActiveTabValue(1);
      setFeelingCount(feelingCountData);
      const selectedFeelingStories = response.find((item: any) => item.feeling === activeTabValue);
      setTotalPages(Math.ceil(selectedFeelingStories.feelingCount / PAGE_SIZE));
      setDateStories(selectedFeelingStories.datingExperience || []);
      setApiLoading(false);
    } catch (error: any) {
      return error;
    }
  };

  const getToPage = (event: React.ChangeEvent<unknown>, value: number) => {
    getDateStories(value);
  };

  const handleCreateAccount = () =>
    keycloak.register({
      redirectUri: `${window.location.origin}/user-details`,
    });

  const handleLogin = () => keycloak?.login({ redirectUri: window.location.origin });

  useEffect(() => {
    getDateStories(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabValue]);

  return (
    <Grid
      container
      sx={{
        height: "100%",
        position: "relative",
        py: "5rem",
        px: "3rem",
        overflow: "auto",
      }}
    >
      {reachedStoryLimit ? (
        <>
          <Logo sx={{ justifyContent: "center" }} />
          <Box className="DateStories-limitReached">
            <img src={StoryImageLimit} alt="story-limit-reached" />
            <Typography variant="h1" mt={5}>
              You reached the maximum limit to view story as a guest.
            </Typography>
            <Typography variant="h1" mt={0.5}>
              Please{" "}
              <Typography variant="h1" color="burgundy" display="inline">
                CREATE ACCOUNT
              </Typography>{" "}
              to see more. If you already have account, please Login
            </Typography>
            <Box mt={5} width="100%" className="d-flex justify-content-center">
              <Box mr={1}>
                <Button variant="primaryLight" label="Create account" onClick={handleCreateAccount} />
              </Box>
              <Box ml={1}>
                <Button label="Login" onClick={handleLogin} />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Grid item xs={0} lg={2}></Grid>
          <Grid item xs={12} lg={8} textAlign="center">
            {!keycloak.authenticated && (
              <>
                <Logo
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                    },
                  }}
                />
                <LoginSignupInfoBanner onLoginClick={handleLogin} onSignUpClick={handleCreateAccount} />
              </>
            )}
            <BackButton
              sx={{
                position: "absolute",
                left: "3rem",
                top: "5rem",
              }}
            />
            <Box sx={{ mb: 5 }}>
              <Typography variant="h1" mt={1} color="blackTint1">
                {locationData?.name || ""}
              </Typography>
            </Box>
            <Box sx={{ mb: 5 }} className="d-flex justify-content-center">
              <Tabs value={activeTabValue} onChange={handleChange} className="DateStories-tabs">
                {feelingCount[2] && <Tab className="DateStories-tab" value={2} label={`Best dates (${feelingCount[2]})`} />}
                {feelingCount[1] && <Tab className="DateStories-tab" value={1} label={`Worst dates (${feelingCount[1]})`} />}
              </Tabs>
            </Box>
            <DateStoryList
              apiLoading={apiLoading}
              dateStories={dateStories}
              pageSize={PAGE_SIZE}
              setReachedStoryLimit={setReachedStoryLimit}
            />
            {totalPages && totalPages > 1 ? (
              <Grid item xs={12} mt={4}>
                <Pagination activePage={currentPage} totalPages={totalPages} handleChange={getToPage} />
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={0} lg={2}></Grid>
        </>
      )}
    </Grid>
  );
};

export default DateStories;
