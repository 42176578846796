import { DataTypes, OptionTypes } from '../models/survey-management';

// Inital state of question used when list is empty or new quesion is added
// Single choice is default added
export const defaultQuestion = {
  isMultiGroup: false,
  allowOpenAnswer: false,
  text: '',
  dataType: DataTypes.string,
  order: 1,
  possibleAnswers: [],
  metaTags: [OptionTypes.singleOptionSelect],
  validators: [],
};

export const options = [
  {
    label: 'Single Choice',
    value: OptionTypes.singleOptionSelect,
  },
  {
    label: 'Multiple short answer',
    value: OptionTypes.multiQuestion,
  },
  {
    label: 'Long answer',
    value: OptionTypes.text,
  },
  // {
  //   label: 'Multiple Choice',
  //   value: OptionTypes.multiOptionSelect,
  // },
];
