import MuiCard from "@mui/material/Card";

import "./Card.scss";

type ICardProps = {
  sx?: any;
  children: React.ReactNode;
  extraClass?: string;
};

const Card: React.FC<ICardProps> = ({ sx, children, extraClass }) => {
  return (
    <MuiCard className={`Card ${extraClass}`} sx={sx}>
      {children}
    </MuiCard>
  );
};

export default Card;
