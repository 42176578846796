import { Grid, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

import FormActions from "../FormActions";
import Dialog from "../Dialog";

import logoutImage from "./../../assets/images/logout.svg";

import "./LogoutDialog.scss";

const LogoutDialog = ({ onClose }: any) => {
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak?.logout({ redirectUri: `${window.location.origin}/logout` });
    localStorage.removeItem("spotify_token");
    localStorage.removeItem("surveyId");
  };

  return (
    <Dialog onClose={onClose}>
      <Grid
        container
        sx={{
          maxWidth: "100%",
          width: "16.75rem",
          mx: "auto",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <img src={logoutImage} className="logout_image" alt="logout" />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h2">Logout</Typography>
          <Typography variant="body1" mt={2}>
            Are you sure you want to logout from Datehouse
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} mt={6}>
          <FormActions
            primaryButtonLabel="Logout"
            secondaryButtonLabel="Cancel"
            primaryButtonClickHandler={handleLogout}
            secondaryButtonClickHandler={onClose}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LogoutDialog;
