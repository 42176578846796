import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Card from "../../components/Card";
import WelcomeCard from "../../components/WelcomeCard";

import "./Welcome.scss";

const Welcome = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className="Welcome">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={9} lg={6} xl={5}>
            {matchesMd ? (
              <Card
                sx={{
                  py: 5,
                  borderRadius: 6.5,
                }}
              >
                <WelcomeCard />
              </Card>
            ) : (
              <WelcomeCard />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className="artist-container" sx={{ color: "greyTint8", position: "absolute", right: 20, bottom: 32 }}>
        art by <Typography variant="subHeading1" color="greyTint8">Alessia Moccia</Typography>
      </Box>
    </div>
  );
};

export default Welcome;
