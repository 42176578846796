import Grid from "@mui/material/Grid";

import logo from "./../../assets/logos/datehouse.svg";
import logoText from "./../../assets/logos/meta-love-feast-text.svg";

type ILogoProps = {
  thumbnail?: boolean;
  sx?: any;
  width?: string;
};

const Logo: React.FC<ILogoProps> = ({ thumbnail, sx, width }) => {
  return (
    <Grid container direction="row" alignItems="center" sx={sx}>
      <Grid item>
        <img alt="logo" src={logo}style={{ width: width}}/>

      </Grid>
    </Grid>
  );
};

export default Logo;
