import { Checkbox, Grid, Box, Typography, useTheme } from "@mui/material";

import { useEffect, useState } from "react";

import { ContentAPI } from "../../apis/ContentAPI";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { UserProfileMetadataAPI } from "../../apis/UserProfileMetadataAPI";
import { formatMetadata } from "../../utils/formatMetadata";
import { decodeImage } from "../../utils/decodeImage";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import ImageUpload from "../../components/ImageUpload";
import TnCDialog from "./TnCDialog";

const UserDetailsForm = (props: any) => {
  const theme = useTheme();
  const [ageGroups, setAgeGroups] = useState([]);
  const [genders, setGenders] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [relationshipStatuses, setRelationshipStatuses] = useState([]);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [tnCDialogState, setTnCDialogState] = useState<boolean>(false);
  const [tncContent, setTncContent] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [userImage, setUserImage] = useState<string | null>(null);
  const {
    values: {
      imageUrl = "",
      name,
      nickname,
      ageId,
      genderId,
      occupationId,
      nationalityId,
      relationshipStatusId,
      isConsent,
    },
    errors,
    touched,
    isValid,
    isEditProfile,
    handleSubmit,
    handleChange,
    setFieldTouched,
    onImageChange,
  } = props;

  const getUserProfileMetadata = async () => {
    const [ageGroups, genders, occupations, nationalities, relationshipStatuses] = await Promise.all([
      UserProfileMetadataAPI.getAllAgeGroups(),
      UserProfileMetadataAPI.getAllGender(),
      UserProfileMetadataAPI.getAllOccupation(),
      UserProfileMetadataAPI.getAllNationality(),
      UserProfileMetadataAPI.getAllRelationshipStatus(),
    ]);
    setAgeGroups(formatMetadata(ageGroups, "ageId", "age"));
    setGenders(formatMetadata(genders, "genderId", "gender"));
    setOccupations(formatMetadata(occupations, "occupationId", "occupation"));
    setNationalities(formatMetadata(nationalities, "nationalityId", "nationality"));
    setRelationshipStatuses(formatMetadata(relationshipStatuses, "statusId", "status"));
  };

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    try {
      const response = await UserProfileAPI.getTermsAndConditions();
      setTncContent(response);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  const TnCAcceptButtonHandler = (isConsent: boolean) => {
    handleChange({ target: { name: "isConsent", value: isConsent } });
    setFieldTouched("isConsent", true, false);
  };

  const handleInputChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setFieldTouched(name, true, false);
  };

  const handleImageUploadLoading = (loading: boolean) => {
    setIsImageUploadLoading(loading);
  };

  const getImgData = async (fileName: string) => {
    const imgResponse = await ContentAPI.getUrlByFileName(fileName);
    if (!!imgResponse) {
      decodeImage(imgResponse.body, setUserImage);
    }
    setImageLoading(false);
  };

  useEffect(() => {
    fetchTermsAndConditions();
    getUserProfileMetadata();
    if (isEditProfile) {
      if (imageUrl) {
        setImageLoading(true);
        getImgData(imageUrl);
      } else if (imageUrl === "") {
        setImageLoading(false);
      }
    } else {
      setImageLoading(false);
    }
  }, [isEditProfile, imageUrl]);

  return (
    <form onSubmit={handleSubmit} className="w-sm-100 w-md-60">
      <Grid container rowSpacing={{ xs: 2, md: 5 }} spacing={{ md: 5 }} justifyContent="center">
        <Grid item xs={12}>
          <ImageUpload
            image={userImage}
            loading={imageLoading}
            handleLoading={handleImageUploadLoading}
            onChange={onImageChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="name"
            helperText={touched.name && errors.name ? errors.name : "Your name will be kept hidden"}
            error={Boolean(errors.name)}
            label="Name"
            value={name}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("name", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="nickname"
            helperText={touched.nickname ? errors.nickname : ""}
            error={Boolean(errors.nickname)}
            label="Nick name"
            value={nickname}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("nickname", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="ageId"
            helperText={touched.ageId ? errors.ageId : ""}
            error={Boolean(errors.ageId)}
            label="Age group"
            value={ageId}
            required
            select
            options={ageGroups}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("ageId", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="genderId"
            helperText={touched.genderId ? errors.genderId : ""}
            error={Boolean(errors.genderId)}
            label="Gender"
            value={genderId}
            required
            select
            options={genders}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("genderId", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="occupationId"
            helperText={touched.occupationId ? errors.occupationId : ""}
            error={Boolean(errors.occupationId)}
            label="Occupation"
            value={occupationId}
            required
            select
            options={occupations}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("occupationId", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="nationalityId"
            helperText={touched.nationalityId ? errors.nationalityId : ""}
            error={Boolean(errors.nationalityId)}
            label="Nationality"
            value={nationalityId}
            required
            select
            options={nationalities}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("nationalityId", e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="relationshipStatusId"
            helperText={touched.relationshipStatusId ? errors.relationshipStatusId : ""}
            error={Boolean(errors.relationshipStatusId)}
            label="Relationship status"
            value={relationshipStatusId}
            select
            options={relationshipStatuses}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("relationshipStatusId", e)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box className="d-flex align-items-center justify-content-center">
            <Checkbox
              color="primary"
              sx={{
                color: theme.palette.primary.light,
                
               }}
              name="isConsent"
              checked={isConsent}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("isConsent", e)}
            />
            <Typography variant="subHeading2" color="greyTint9" mr={0.25}>
              I accept all the
            </Typography>
            <Typography
              variant="subHeading2"
              color={theme.palette.secondary.light}
              onClick={() => setTnCDialogState(true)}
              sx={{ cursor: "pointer" }}
            >
              Terms & Conditions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            type="submit"
            label={isEditProfile ? "Update my profile" : "Complete registration"}
            disabled={!isValid || isImageUploadLoading}
          />
        </Grid>
        {tnCDialogState && (
          <TnCDialog
            loading={loading}
            tncContent={tncContent}
            onClose={() => setTnCDialogState(false)}
            TnCAcceptButtonHandler={TnCAcceptButtonHandler}
          />
        )}
      </Grid>
    </form>
  );
};

export default UserDetailsForm;
