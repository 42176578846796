import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { rootState } from "../../../store";

import AdminLayout from "../../../layouts/AdminLayout";
import ExperienceTrendGraph from "./ExperienceTrendGraph";
import LocationTrendGraph from "./LocationTrendGraph";
import UserTrendsGraph from "./UserTrendsGraph";
import GenderStatisticsGraph from "./GenderStatisticsGraph";

import "./AdminDashboard.scss";

const AdminDashboard = () => {
  const keycloakState: any = useSelector((state: rootState) => state.keycloak.keycloak);

  return (
    <AdminLayout>
      <Box className="AdminDashboard">
        <div className="AdminDashboard_name">
          <Typography variant="h1">Good morning, {keycloakState?.tokenParsed?.given_name}</Typography>
        </div>
        <UserTrendsGraph />
        <ExperienceTrendGraph />
        <LocationTrendGraph />
        <GenderStatisticsGraph />
      </Box>
    </AdminLayout>
  );
};

export default AdminDashboard;
