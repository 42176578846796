import { UserProfileMetadataAPI } from "../apis/UserProfileMetadataAPI";
import { REGIONS_OPTIONS } from "../constants/regions";
import { EXPERIENCE_RATINGS } from "./dateRating";
import { formatMetadata } from "./formatMetadata";

const getAdminReportFilters = async (isReport: boolean = false) => {
  const [ageGroups, genders, occupations, nationalities] = await Promise.all([
    UserProfileMetadataAPI.getAllAgeGroups(),
    UserProfileMetadataAPI.getAllGender(),
    UserProfileMetadataAPI.getAllOccupation(),
    UserProfileMetadataAPI.getAllNationality(),
  ]);

  return {
    ageGroups: formatMetadata(ageGroups, "ageId", "age"),
    genders: formatMetadata(genders, "genderId", "gender"),
    nationalities: formatMetadata(nationalities, "nationalityId", "nationality"),
    ...(!isReport && { occupations: formatMetadata(occupations, "occupationId", "occupation") }),
    ...(isReport && { experienceRates: EXPERIENCE_RATINGS, regions: REGIONS_OPTIONS }),
  };
};

export default getAdminReportFilters;
