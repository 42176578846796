import { Box, Typography } from "@mui/material";
import { useState } from "react";

import InputField from "../../InputField";

type IInputAnswerProps = {
  errorMessages?: string[];
  onChange: (value: string) => void;
};

const InputAnswer = ({ errorMessages = [], onChange }: IInputAnswerProps) => {
  const [answer, setAnswer] = useState<string>("");

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAnswer(value);
    onChange(value);
  };

  return (
    <Box>
      <InputField
        placeholder="Enter here"
        value={answer}
        error={errorMessages?.length > 0}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
      />
      {errorMessages?.length ? (
        <Box mt={0.5}>
          {errorMessages?.map((message: string, index: number) => (
            <Typography
              sx={{ display: "block" }}
              key={index}
              variant="smallText"
              color="redCarnation"
            >
              {message}
            </Typography>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default InputAnswer;
