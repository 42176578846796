import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { Box, Grid, Typography } from "@mui/material";
import debounce from "lodash.debounce";

import mapStyles from "../../constants/mapStyling";
import { WorldMapStoriesAPI } from "../../apis/WorldMapStoriesAPI";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import BackButton from "../BackButton";
import CentroidDatesCountCard from "../CentroidDatesCountCard";
import DateLocationCountCard from "../DateLocationCountCard";
import Logo from "../Logo";
import MapLoader from "../MapLoader";
import LocationSearchInput from "../LocationSearchInput";

import mapPinRedLarge from "./../../assets/icons/map-pin-red-large.svg";
import mapPinRedSmall from "./../../assets/icons/map-pin-red-small.svg";
import mapPinBlackSmall from "./../../assets/icons/map-pin-black-small.svg";

import "./WorldDateStories.scss";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const DEFAULT_ZOOM_LEVEL = 2.9;
const MIN_ZOOM_LEVEL = 2.9;
const MIN_ZOOM_LEVEL_HIGH_RESOLUTION = 4.2;
const MAX_ZOOM_LEVEL = 14;

const WorldDateStories = () => {
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY || "",
    libraries: ["places"],
  });
  const { width } = useWindowDimensions();
  const [center, setCenter] = useState<any>({ lat: 45, lng: 0 });
  const [minZoom, setMinZoom] = useState<number>(MIN_ZOOM_LEVEL);
  const [currentZoom, setCurrentZoom] = useState<number>(DEFAULT_ZOOM_LEVEL);
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = React.useState<any>(null);

  React.useEffect(() => {
    if (width > 2000) {
      setMinZoom(MIN_ZOOM_LEVEL_HIGH_RESOLUTION);
    } else {
      setMinZoom(MIN_ZOOM_LEVEL);
    }
  }, [width]);

  const handleCentroidCardClick = (
    centroidLatLng: google.maps.LatLng,
    zoomLevel: number
  ) => {
    setCenter(centroidLatLng);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(centroidLatLng);
    map.fitBounds(bounds);
    if (zoomLevel <= 4) {
      setCurrentZoom(5);
    } else if (zoomLevel >= 4 && zoomLevel <= 7) {
      setCurrentZoom(8);
    } else if (zoomLevel >= 7 && zoomLevel <= 10) {
      setCurrentZoom(11);
    }
  };

  const handleLocationPinClick = (
    position: { lat: number; lng: number },
    name: string
  ) => {
    position &&
      navigate("/date-stories", {
        state: {
          position,
          name,
        },
      });
  };

  const getCenterDatePostion = (data: any) => {
    const position = { lat: data.center.latitude, lng: data.center.longitude };
    return (
      <MarkerF icon={{ url: mapPinRedSmall }} position={position}>
        <InfoWindowF position={position} options={{ disableAutoPan: true }}>
          <CentroidDatesCountCard
            currentZoom={currentZoom}
            latLng={position}
            locationName={data.name}
            experienceCount={data.experienceCount}
            onCentroidCardClick={handleCentroidCardClick}
          />
        </InfoWindowF>
      </MarkerF>
    );
  };

  const getCityCenterDatePosition = (data: any) => {
    const position = { lat: data.center.latitude, lng: data.center.longitude };
    return (
      <MarkerF icon={{ url: mapPinRedSmall }} position={position}>
        <InfoWindowF position={position} options={{ disableAutoPan: true }}>
          <DateLocationCountCard
            locationName={data.name}
            experienceCount={data.experienceCount}
          />
        </InfoWindowF>
      </MarkerF>
    );
  };

  const getAllDatePositions = (cityData: any, id: string) => {
    const cityMarkers: any = [];
    cityData[id].forEach((item: any) => {
      const position = { lat: item[1], lng: item[0] };
      cityMarkers.push(
        <MarkerF
          icon={{ url: id === "1" ? mapPinBlackSmall : mapPinRedSmall }}
          position={position}
          onClick={() => handleLocationPinClick(position, cityData.name)}
        />
      );
    });
    return cityMarkers;
  };

  const saveMarkers = (data: any, zoom: number) => {
    const markerArr: any = [];
    if (data.length) {
      data.forEach((item: any) => {
        if (zoom <= 10) {
          markerArr.push(getCenterDatePostion(item));
        } else {
          markerArr.push(getCityCenterDatePosition(item));
          if (item.hasOwnProperty("1")) {
            markerArr.push(getAllDatePositions(item, "1"));
          }
          if (item.hasOwnProperty("2")) {
            markerArr.push(getAllDatePositions(item, "2"));
          }
        }
      });
    }

    setMarkers(markerArr);
  };

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const getDatingExperiences = async (bounds: any, zoom: number) => {
    const response = await WorldMapStoriesAPI.getAllDatingExperiences({
      lowerBoundlocation: [bounds.west, bounds.north],
      upperBoundlocation: [bounds.east, bounds.south],
      zoomLevel: Math.round(zoom),
    });
    if (!!response) {
      saveMarkers(response, zoom);
    }
  };

  const getDatingExperiencesDebounce = debounce(getDatingExperiences, 300);

  const handleBoundsChanged = () => {
    if (map) {
      const bounds = map.getBounds();
      getDatingExperiencesDebounce(
        {
          north: bounds.getNorthEast().lat(), // upperBoundlocation lng
          south: bounds.getSouthWest().lat(), // lowerBoundlocation lng
          east: bounds.getNorthEast().lng(), // upperBoundlocation lat
          west: bounds.getSouthWest().lng(), // lowerBoundlocation lat
        },
        map.getZoom()
      );
    }
  };

  const handleZoomChanged = () => {
    if (map) {
      setCurrentZoom(map.getZoom());
    }
  };

  const handleOnLoad = (map: any) => {
    setMap(map);
  };

  const handleLocationChange = (latLng: any, location: any, bounds: any) => {
    map.fitBounds(bounds);
    handleBoundsChanged();
    const type = location.types[0];
    switch (type) {
      case "country":
        setCurrentZoom(5);
        break;
      case "administrative_area_level_1":
        setCurrentZoom(8);
        break;
      case "administrative_area_level_2":
      case "sublocality_level_1":
      case "postal_town":
      case "locality":
      case "administrative_area_level_3":
        setCurrentZoom(11);
        break;
      default:
        break;
    }
    setCenter(latLng);
  };

  return (
    <Grid
      container
      sx={{
        px: {
          xs: 0,
          md: 8,
          lg: 10,
        },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          textAlign: {
            xs: "center",
            md: "left",
          },
          px: {
            xs: 2.75,
            md: 0,
          },
          py: {
            xs: 5,
            md: 10,
          },
          minHeight: {
            xs: 280,
            md: 250,
          },
          height: {
            xs: "35vh",
            md: "30vh",
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <BackButton
          sx={{
            position: "absolute",
            left: {
              xs: "1rem",
              md: "4rem",
            },
            top: {
              xs: "4rem",
              md: "7rem",
            },
          }}
        />
        <Box
          sx={{
            ml: {
              md: 10,
            },
          }}
        >
          <Logo
            sx={{
              mb: 4,
              display: "flex",
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
            }}
            width="200px"
          />
          <Typography sx={{ mb: 1.5 }} variant="h1">
            World map of date stories
          </Typography>
          <Typography sx={{ mb: { xs: 3, md: 0 } }} variant="body1">
            Click on the stories below to read about the dating experiences
            everyone is having
          </Typography>
        </Box>
        {isLoaded && (
          <LocationSearchInput onLocationChange={handleLocationChange} />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: {
            xs: "65vh",
            md: "70vh",
          },
        }}
      >
        {isLoaded ? (
          <GoogleMap
            id="world-date-stories"
            mapContainerClassName="WorldDateStories"
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
              mapTypeControl: false,
              fullscreenControl: false,
              minZoom,
              maxZoom: MAX_ZOOM_LEVEL,
              streetViewControl: false,
              restriction: {
                latLngBounds: {
                  north: 70.67351256610522,
                  south: -50.995311187950925,
                  west: 192,
                  east: 190,
                },
              },
            }}
            center={center}
            zoom={currentZoom}
            onUnmount={onUnmount}
            onLoad={handleOnLoad}
            onBoundsChanged={handleBoundsChanged}
            onZoomChanged={handleZoomChanged}
          >
            {markers}
          </GoogleMap>
        ) : (
          <MapLoader />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(WorldDateStories);
