export const getCoordinate = (id: number) => {
  let lat = 0,
    lng = 0;
  switch (id) {
    //NORTH AMERICA
    case 1:
      lat = 43.6426;
      lng = 79.3871;
      break;
    //CENTRAL AMERICA
    case 2:
      lat = 19.4326077;
      lng = -99.133208;
      break;
    //EUROPE
    case 3:
      lat = 51.51599299999999;
      lng = -0.1392256;
      break;
    //SOUTH AMERICA
    case 4:
      lat = 39.523652;
      lng = -87.1250154;
      break;
    //NORTH AFRICA
    case 5:
      lat = 33.5758954;
      lng = -7.706821000000001;
      break;
    //CENTRAL AFRICA
    case 6:
      lat = 12.862807;
      lng = 30.217636;
      break;
    //ASEAN
    case 7:
      lat = 1.3604206;
      lng = 103.8720225;
      break;
    //SOUTH AFRICA
    case 8:
      lat = -30.559482;
      lng = 22.937506;
      break;
    //SUB CONTINENT INDIA COORDINATES
    case 9:
      lat = 28.630570972008694;
      lng = 77.21669163173826;
      break;
    //GREATER CHINA REGION
    case 10:
      lat = 35.86166;
      lng = 104.195397;
      break;
    //JAPAN
    case 11:
      lat = 36.204824;
      lng = 138.252924;
      break;
    //AUSTRALIA
    case 12:
      lat = -25.274398;
      lng = 133.775136;
      break;
    //UNITED KINGDOM
    case 13:
      lat = 55.378051;
      lng = -3.435973;
      break;
    //NEW YORK DEFAULT CORDINATE
    default:
      lat = 40.7128;
      lng = -73.935;
      break;
  }
  return {
    lat,
    lng,
  };
};
