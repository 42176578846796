import { Typography, Box, Tooltip } from "@mui/material";

import "./DateOption.scss";
import InfoIcon from "@mui/icons-material/Info";
import heartSelected from "../../assets/icons/date-rating-filled.svg";
import heart from "../../assets/icons/date-rating-outline.svg";

interface IDateOptionProps {
  option: {
    key: string;
    image: any;
    text: string;
    rating: number;
    isSelected?: boolean;
  };
  setOption: (rating: number) => void;
  selected: string;
}

const datingOptionInfo: { [key: string]: string } = {
  amazing:
    "Please describe a fantastic dating experience which you will remember forever, and you are talking about often",
  worst: "Please describe a nightmare of a dating experience you want to forget forever and never speak again.",
};

const DateOption: React.FC<IDateOptionProps> = ({
  option = { key: "", image: null, text: "", rating: 0 },
  setOption = (rating: number) => {},
  selected = "",
}) => {
  return (
    <Box sx={{cursor:"pointer", px:"5px"}}  onClick={() => setOption(option.rating)}> 
      {option.isSelected ? <img src={heartSelected} alt="heart-selected-icon" /> : <img src={heart} alt="heart-icon" />}
    </Box>
    

    // <Box
    //   className={"date-review-option" + (selected === option.key ? " selected" : "")}
    //   key={option.key}
    //   onClick={() => setOption(option.key)}
    // >
    //   <img src={heartSelected} alt="heart-selected-icon" />
    //   <img src={heart} alt="heart-icon" />
    //   {/* <img src={option.image} alt={option.key} />
    //   <Box className="d-flex align-items-center justify-content-center">
    //     <Typography variant="subHeading1" component="p" mr={0.5}>
    //       {option.text}
    //     </Typography>
    //     <Tooltip componentsProps={{ tooltip: { sx: { width: "15rem" } } }} title={datingOptionInfo[option.key]}>
    //       <InfoIcon sx={{ width: "0.87rem", height: "0.87rem", color: "secondary.light" }} />
    //     </Tooltip>
    //   </Box> */}
    // </Box>
  );
};

export default DateOption;
