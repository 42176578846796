import { Box, Card, Skeleton } from '@mui/material';

const cardStyling = {
  padding: '1.5rem',
  borderRadius: '1rem',
};

const skeletonArr = [8, 5, 4, 9, 11];

const Loading = () => {
  return (
    <Card
      sx={{
        borderRadius: 6,
        p: 3,
      }}
    >
      {skeletonArr.map((item, index) => (
        <Box key={index} sx={cardStyling}>
          <Skeleton
            sx={{ mb: 2 }}
            variant="rounded"
            height={40}
            width={'80%'}
          />
          <Skeleton sx={{ mb: 2 }} variant="rounded" width={'80%'} />
          <Skeleton sx={{ mb: 2 }} variant="rounded" width={'40%'} />
          <Skeleton sx={{ mb: 2 }} variant="rounded" width={'40%'} />
        </Box>
      ))}
    </Card>
  );
};

export default Loading;
