import { Grid, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

import Button from "../Button";
import ListItems from "../ListItems";
import Rating from "../Rating";

import logo from "./../../assets/logos/datehouse.svg";

import "./WelcomeCard.scss";

const WelcomeCard = () => {
  const { keycloak } = useKeycloak();

  // redirecting user to keycloak signup page
  const handleStartNow = () => {
    localStorage.setItem("isNewUser", "false");
    keycloak.register({
      redirectUri: `${window.location.origin}/`,
    });
  };

  return (
    <Grid
      container
      className="WelcomeCard"
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        px: {
          xs: 4,
          md: 10,
        },
      }}
    >
      <img className="WelcomeCard-logo" alt="logo" src={logo} />
      <Typography
        variant="h1"
        sx={{
          mb: {
            xs: 8,
            md: 10,
          },
        }}
      >
        Welcome to Datehouse
      </Typography>
      <Typography variant="subText1" alignSelf="flex-start" mb={5}>
        We have created a world map for people to share its most exciting and worst dates.
      </Typography>
      <ListItems
        sx={{ mb: 1, p: 0 }}
        items={[
          "Anyone can share a dating story.",
          "While sharing your story here, you are actively helping us to build a fantastic relationship and curated date site.",
          "Please remember not to be crude, and do not give away obvious identity details, including using your real name or your date's actual name for safety reasons.",
          "You can easily share your dates (as many as you like), and if you would like to help us further, you can fill in a more extended format anytime you want or add more details.",
        ]}
      />
      <Rating rate={6} />
      <Typography sx={{ mb: 5 }} variant="subHeading1">
        THANK YOU
      </Typography>
      <Button
        variant="primaryDark"
        label="Start now"
        onClick={handleStartNow}
        textVariant="body1"
      />
    </Grid>
  );
};

export default WelcomeCard;
