// import { useAuth0, User } from "@auth0/auth0-react";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { PAGE_SIZE } from "../../constants/global";
import { LongSurveyAPI } from "../../apis/LongSurveyAPI";
import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { UserExperienceAPI } from "../../apis/UserExperienceAPI";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import HomeCard from "../../components/HomeCard";
import HomeCardMobile from "../../components/HomeCard/HomeCardMobile";
import Map from "../../components/Map";
import Loader from "../../components/Loader";

import "./Home.scss";

const Home = () => {
  const isMobileDevice = useCheckMobileScreen();
  const navigate = useNavigate();
  const userId = getAuthenticatedUserId();
  const { state: userDetailsState } = useLocation();
  const { keycloak } = useKeycloak();
  const [latLng, setLatLng] = useState<any>();
  const [locationData, setLocationData] = useState();
  const [geoLocationDescription, setGeoLocationDescription] = useState();
  const [checkingIfExists, setCheckingIfExists] = useState<boolean>(true);
  const [myLocations, setMyLocations] = useState<any>([]);
  const currentPageRef = useRef(1);
  const myLocationsRef = useRef<any>([]);

  const checkIfUserExists = async (id: string) => {
    try {
      const response = await UserProfileAPI.checkUserExist(id);
      if (!response) {
        navigate("/user-details");
      } else {
        getMyLocations();
        if (!userDetailsState && userId) {
          getLoggedInUserLocation(userId);
        } else if (!!userDetailsState && userDetailsState.userDetailsFlow && userDetailsState.currentLatLng) {
          setLatLng({
            lat: userDetailsState.currentLatLng[1],
            lng: userDetailsState.currentLatLng[0],
          });
        }
        // get default long survey
        const surveyId = localStorage.getItem("surveyId");
        if (!surveyId) {
          const longSurveyResponse = await LongSurveyAPI.getDefaultLongSurvey();
          if (longSurveyResponse && longSurveyResponse.surveyId) {
            localStorage.setItem("surveyId", longSurveyResponse.surveyId);
          }
        }
      }
      setCheckingIfExists(false);
    } catch (err) {
      navigate("/user-details");
      setCheckingIfExists(false);
      return err;
    }
  };

  const getLoggedInUserLocation = async (userId: string) => {
    const response = await UserProfileAPI.getUserProfile(userId);
    const [lng, lat] = response.location;
    setLatLng({ lat, lng });
  };

  const handleLocationChange = (latLng: any, locationData: any) => {
    setLatLng(latLng);
    setLocationData(locationData);
  };

  const handleGeoLocationChange = (geoLocationData: any) => {
    setGeoLocationDescription(geoLocationData);
  };

  const getMyLocations = async () => {
    try {
      const response = await UserExperienceAPI.getUserStories(getAuthenticatedUserId(), currentPageRef.current - 1);
      const totalPages = Math.ceil(response.totalCount / PAGE_SIZE);
      if (currentPageRef.current < totalPages) {
        currentPageRef.current += 1;
        getMyLocations();
      }
      myLocationsRef.current = [...myLocationsRef.current, ...response.userDatingExperiences];
      setMyLocations([...myLocationsRef.current, ...response.userDatingExperiences]);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (!!keycloak.authenticated) {
      if (userId) {
        checkIfUserExists(userId);
      }
    } else {
      navigate("/logout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (keycloak.authenticated && !checkingIfExists) {
    return (
      <>
        <div className="Home">
          <Map
            latLng={latLng}
            dateLocations={myLocationsRef.current}
            locationData={locationData}
            geoLocationDescription={geoLocationDescription}
          >
            {isMobileDevice ? (
              <HomeCardMobile onLocationChange={handleLocationChange} onGeoLocationChange={handleGeoLocationChange} />
            ) : (
              <HomeCard onLocationChange={handleLocationChange} onGeoLocationChange={handleGeoLocationChange} />
            )}
          </Map>
        </div>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default Home;
