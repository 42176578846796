import Box from "@mui/material/Box";

import Card from "../../components/Card";
import NavBar from "../../components/NavBar";
import DateLocationSearchCard from "../../components/DateLocationSearchCard";

const HomeCard = ({ onLocationChange, onGeoLocationChange }: any) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "absolute",
        zIndex: 2,
        right: 60,
        top: "50%",
        transform: "translateY(-50%)",
        width: "480px",
      }}
    >
      <Card
        sx={{
          py: 5,
          borderRadius: 6.5,
          height: "80vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <Box sx={{ px: 6, mb: 5 }}>
            <NavBar />
          </Box>
          <DateLocationSearchCard
            onLocationChange={onLocationChange}
            onGeoLocationChange={onGeoLocationChange}
          />
        </>
      </Card>
    </Box>
  );
};

export default HomeCard;
