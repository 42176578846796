import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

import Logo from "../../components/Logo";
import Button from "../../components/Button";
import LogoutDialog from "../../components/LogoutDialog";
import BackButton from "../../components/BackButton";

import "./style.scss";

interface IAppLayoutProps {
  showBackButton?: boolean;
  logo?: JSX.Element;
  title?: string;
  description?: string;
  children: JSX.Element;
}

const AppLayout: React.FC<IAppLayoutProps> = ({
  showBackButton = false,
  logo = <Logo />,
  title,
  description,
  children,
}) => {
  const isMobileDevice = useCheckMobileScreen();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);

  return (
    <Box
      sx={{
        mx: {
          xs: 3,
          md: 10,
        },
      }}
    >
      <Grid
        sx={{
          py: {
            xs: 5,
            md: 12,
          },
        }}
        container
        className="AppLayout"
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        
        <Grid container mb={3} alignItems="center">
          <Grid item xs={2}>
          {showBackButton && (
          <BackButton
            // sx={{
            //   position: "absolute",
            //   left: {
            //     xs: "1rem",
            //     md: "4rem",
            //   },
            //   top: {
            //     xs: "3rem",
            //     md: "7rem",
            //   },
            // }}
          />
        )}
          </Grid>
          <Grid item xs={8}>
            <div className="logo">
              <Logo sx={{ justifyContent: "center" }} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={isMobileDevice ? { minWidth: "32px", padding: 1 } : {}}
              icon={isMobileDevice ? <LogoutIcon fontSize="small" /> : <></>}
              label={isMobileDevice ? "" : "Logout"}
              variant="bright"
              onClick={() => setOpenLogoutDialog(true)}
            />
          </Grid>
        </Grid>
        {title && (
          <Typography color="blackTint1" mb={!!description ? 1 : 8} variant="h1">
            {title}
          </Typography>
        )}
        {description && (
          <Typography color="blackTint4" maxWidth={376} mb={4} mx="auto" variant="body1">
            {description}
          </Typography>
        )}
        {children}
        {openLogoutDialog && <LogoutDialog onClose={() => setOpenLogoutDialog(false)} />}
      </Grid>
    </Box>
  );
};

export default AppLayout;
