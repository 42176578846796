import { FC, useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';

import {
  DataTypes,
  IQuestion,
  Validators,
} from '../../../../models/survey-management';

import Option from './Option';

const MultiOption: FC<{
  question: IQuestion;
  handleFieldsChange: Function;
  type: 'single' | 'multiple';
}> = ({ question, handleFieldsChange, type }) => {
  const [optionsList, setOptionsList] = useState<{
    [position: number]: string;
  }>({
    [1]: '',
  });

  const totalOptions = useMemo(
    () => Object.keys(optionsList).length,
    [optionsList]
  );

  /**
   * @param position
   * @param value
   * @param action
   * @description Handle update and delete of possibl answers
   */
  const handleChange = (
    position: number,
    value: string,
    action: 'update' | 'delete' = 'update'
  ) => {
    let updatedOptions: typeof optionsList = {};
    if (action == 'delete') {
      // step:1 Delete option
      // step:2 Create final object
      // step:3 Update positions
      // step:4 Set final object
      const options = { ...optionsList };
      delete options[position];
      Object.entries(options).forEach(
        ([key, value], index) => (updatedOptions[index + 1] = value)
      );
    } else {
      updatedOptions = {
        ...optionsList,
        [position]: value,
      };
    }
    // Add validators and update final questions list  with all possible answers
    handleFieldsChange({
      ...question,
      dataType: type === 'single' ? DataTypes.string : DataTypes.stringArray,
      possibleAnswers: Object.values(updatedOptions),
      validators: [
        {
          name:
            type === 'single'
              ? Validators.arrayLengthValidator
              : Validators.minLengthValidator,
          value: '1',
          errorMessage: 'Required',
        },
      ],
    });
  };

  const addNewOption = () => handleChange(totalOptions + 1, '');

  /**
   * @param options
   * @description Updates local state
   */
  const updatedOptions = (options: IQuestion['possibleAnswers']) => {
    const optionsObj: typeof optionsList = {};
    options?.forEach((option, index) => (optionsObj[index + 1] = option));
    setOptionsList({ ...optionsObj });
  };

  useEffect(() => {
    if (question?.possibleAnswers?.length) {
      updatedOptions(question?.possibleAnswers);
    }
  }, [question?.possibleAnswers]);

  return (
    <>
      {Object.entries(optionsList).map(([position, value], index) => (
        <Option
          key={position}
          value={value}
          handleChange={handleChange}
          position={Number(index + 1)}
          showDelete={totalOptions > 1}
        />
      ))}
      <Typography
        color="blue"
        variant="subHeading2"
        onClick={addNewOption}
        sx={{
          cursor: 'pointer',
          marginLeft: 3.5,
        }}
      >
        Add option
      </Typography>
    </>
  );
};

export default MultiOption;
