import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";

import { ContentAPI } from "../../apis/ContentAPI";
import { getAuthenticatedUserId } from "../../utils/authDetails";

import Loader from "../Loader";

import "./ImageUpload.scss";

const ImageUpload = ({ image, loading, handleLoading, onChange }: any) => {
  const [decodedImage, setDecodedImage] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const decodeImage = (encodedImage: any) => {
    const byteCharacters = atob(encodedImage);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let image = new Blob([byteArray], { type: "image/jpeg" });
    let imageUrl = URL.createObjectURL(image);
    setDecodedImage(imageUrl);
  };

  const handleImageUpload = async (event: any) => {
    const formData = new FormData();
    setDecodedImage(null);
    formData.append("image", event.target.files[0]);
    setShowLoader(true);
    handleLoading(true);
    const response = await ContentAPI.postUploadImage(getAuthenticatedUserId(), formData);
    if (!!response) {
      if (response.fileName) {
        const { fileName } = response;
        onChange(fileName);
        const imgResponse = await ContentAPI.getUrlByFileName(fileName);
        if (!!imgResponse) {
          decodeImage(imgResponse.body);
        }
      }
    }
    setShowLoader(false);
    handleLoading(false);
  };

  useEffect(() => {
    setDecodedImage(image);
  }, [image]);

  useEffect(() => {
    setShowLoader(loading);
  }, [loading]);

  return (
    <IconButton
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent !important",
      }}
      color="primary"
      disableRipple
      aria-label="upload picture"
      component="label"
    >
      <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
      <Box
        sx={{
          width: 116,
          height: 116,
          border: "2px solid #AFAEAE",
          borderRadius: "18px",
          p: 1,
          mb: 1,
        }}
      >
        {decodedImage ? (
          <img className="ImageUpload-img" alt="profile pic" src={decodedImage} />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#EFEFEF",
              borderRadius: 3,
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showLoader ? (
              <Loader />
            ) : (
              <Typography variant="subText1" color="blackTint5">
                Add avatar
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Typography width={140} variant="smallText" color="secondary">
        (JPG, PNG, NFT's, Animated Avatars, Characters, Icons)
      </Typography>
    </IconButton>
  );
};

export default ImageUpload;
