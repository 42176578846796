import COUNTRY_GEOCODES_MAPPING from "../constants/geoCodeMapping";

const getDataFromGeoCoderResult = (geoLocationDescription: any) => {
  const geoCoderData = geoLocationDescription.address_components;

  if (!geoCoderData) return {};

  return geoCoderData.reduce(
    (acc: any, { types, long_name: value, short_name: shortValue }: any) => {
      const type = types[0];

      switch (type) {
        case "country":
          return {
            ...acc,
            continent: COUNTRY_GEOCODES_MAPPING[shortValue],
            country: value,
          };
        case "administrative_area_level_1":
          return { ...acc, state: value };
        case "administrative_area_level_2":
        case "sublocality_level_1":
        case "postal_town":
        case "locality":
        case "administrative_area_level_3":
          return { ...acc, city: value };
        case "postal_code":
          return { ...acc, zipCode: value };
        default:
          return acc;
      }
    },
    {}
  );
};

const getCompleteGeoLocationDetails = (
  locationDetails: any,
  geoLocationDescription: any
) => {
  const geoCoderData = getDataFromGeoCoderResult(geoLocationDescription);
  const locationDetailsObj = {
    ...geoCoderData,
    locationName: locationDetails.formattedSuggestion.mainText,
    address: locationDetails.formattedSuggestion.secondaryText,
  };

  return locationDetailsObj;
};

export default getCompleteGeoLocationDetails;
