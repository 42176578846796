import disasterEmoji from "../assets/images/disaster.svg";
import veryBadEmoji from "../assets/images/very_bad.svg";
import badEmoji from "../assets/images/bad.svg";
import belowAverageEmoji from "../assets/images/below_average.svg";
import averageEmoji from "../assets/images/average.svg";
import aboveAverageEmoji from "../assets/images/above_average.svg";
import goodEmoji from "../assets/images/good.svg";
import veryGoodEmoji from "../assets/images/very_good.svg";
import excellentEmoji from "../assets/images/excellent.svg";
import outstandingEmoji from "../assets/images/outstanding.svg";

export const ratingStyleMap = [
  {
    image: disasterEmoji,
    sliderClass: "not-set",
  },
  {
    image: disasterEmoji,
    sliderClass: "disaster",
  },
  {
    image: veryBadEmoji,
    sliderClass: "very_bad",
  },
  {
    image: badEmoji,
    sliderClass: "bad",
  },
  {
    image: belowAverageEmoji,
    sliderClass: "below_average",
  },
  {
    image: averageEmoji,
    sliderClass: "average",
  },
  {
    image: aboveAverageEmoji,
    sliderClass: "above_average",
  },
  {
    image: goodEmoji,
    sliderClass: "good",
  },
  {
    image: veryGoodEmoji,
    sliderClass: "very_good",
  },
  {
    image: excellentEmoji,
    sliderClass: "excellent",
  },
  {
    image: outstandingEmoji,
    sliderClass: "outstanding",
  },
];

export const ratingTextMap: { [key: string]: any } = {
  outstanding: "Outstanding",
  excellent: "Excellent",
  very_good: "Very Good",
  good: "Good",
  above_average: "Above Average",
  average: "Average",
  below_average: "Below Average",
  bad: "Bad",
  very_bad: "Very Bad",
  disaster: "Disaster",
};

export const EXPERIENCE_RATINGS = [
  {
    value: 1,
    label: `1 - ${ratingTextMap.disaster}`,
  },
  {
    value: 2,
    label: `2 - ${ratingTextMap.very_bad}`,
  },
  {
    value: 3,
    label: `3 - ${ratingTextMap.bad}`,
  },
  {
    value: 4,
    label: `4 - ${ratingTextMap.below_average}`,
  },
  {
    value: 5,
    label: `5 - ${ratingTextMap.average}`,
  },
  {
    value: 6,
    label: `6 - ${ratingTextMap.above_average}`,
  },
  {
    value: 7,
    label: `7 - ${ratingTextMap.good}`,
  },
  {
    value: 8,
    label: `8 - ${ratingTextMap.very_good}`,
  },
  {
    value: 9,
    label: `9 - ${ratingTextMap.excellent}`,
  },
  {
    value: 10,
    label: `10 - ${ratingTextMap.outstanding}`,
  },
];
