import { ApexOptions } from "apexcharts";

const barChartOptions: ApexOptions = {
  chart: {
    height: 350,
    toolbar: {
      show: false,
    },

    zoom: {
      enabled: false,
    },
  },

  plotOptions: {
    bar: {
      columnWidth: "20%",
      borderRadius: 4,
      dataLabels: {
        position: "top",
      },
    },
  },

  xaxis: {
    categories: [],

    type: "datetime",
    axisBorder: {
      show: false,
    },
    crosshairs: { show: false },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      rotate: 0,
      trim: true,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    decimalsInFloat: 0,
    crosshairs: { show: false },
    labels: {
      show: true,
      formatter: function (val) {
        return parseFloat(val.toFixed(1)) + "%";
      },
    },
  },
};
export default barChartOptions;
