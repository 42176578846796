import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";

import { UserProfileAPI } from "../../apis/UserProfileAPI";
import { getAuthenticatedUserId } from "../../utils/authDetails";
import { getCoordinate } from "../../constants/getCordinate";

import AppLayout from "../../layouts/AppLayout";
import Logo from "../../components/Logo";
import { toast } from "../../components/ToastNotification/ToastManager";

import UserDetailsForm from "./form";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  nickname: yup.string().required("Nick name is required"),
  ageId: yup.number().required("Please select age group"),
  genderId: yup.number().required("Please select gender"),
  occupationId: yup.number().required("Please select occupation"),
  nationalityId: yup.number().required("Please select nationality"),
  isConsent: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

const UserDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const isEditMode = mode === "edit";
  const [userImage, setUserImage] = useState<string | null>(null);
  const [values, setValues] = useState<any>({
    name: "",
    nickname: "",
    ageId: "",
    genderId: "",
    occupationId: "",
    nationalityId: "",
    relationshipStatusId: "",
    isConsent: false,
  });

  const handleImageChange = (userImage: string) => {
    setUserImage(userImage);
  };

  const handleSubmit = (data: any) => {
    submitData(data);
  };

  const submitData = async (data: any) => {
    if (data.relationshipStatusId === "") delete data.relationshipStatusId;
    const nationalityCoordinate = getCoordinate(data.nationalityId);
    const currentLatLng = [nationalityCoordinate.lng, nationalityCoordinate.lat];
    const body = {
      ...data,
      ...(userImage && { imageUrl: userImage }),
      location: currentLatLng.length ? currentLatLng : [],
    };
    const response = await UserProfileAPI.updateUserProfile(getAuthenticatedUserId(), body, isEditMode);
    if (!!response) {
      if (isEditMode) {
        toast.show({
          message: "Profile updated successfully!",
        });
      } else {
        navigate("/", {
          state: {
            currentLatLng,
            userDetailsFlow: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await UserProfileAPI.getUserProfile(getAuthenticatedUserId(), false);
      if (isEditMode) {
        if (response.imageUrl) {
          setUserImage(response.imageUrl);
        }
        setValues(response);
      } else if (!!response) navigate("/");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppLayout showBackButton={isEditMode} logo={<Logo />} title={isEditMode ? "My Profile" : "Tell us about you"}>
        <Formik enableReinitialize initialValues={values} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(props) => (
            <UserDetailsForm
              userImage={userImage}
              isEditProfile={isEditMode}
              onImageChange={handleImageChange}
              {...props}
            />
          )}
        </Formik>
      </AppLayout>
    </>
  );
};

export default UserDetails;
