import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { useAuth0, User } from "@auth0/auth0-react";

import { UserExperienceAPI } from "../../../apis/UserExperienceAPI";
import { getAuthenticatedUserId } from "../../../utils/authDetails";

import FormActions from "../../FormActions";
import TagsTextarea from "../../TagsTextarea";
import logo from "../../../assets/logos/datehouse.svg";

const AddStoryStepThreeForm = (props: any) => {
  // const { user }: User = useAuth0();
  const {
    values: { hashTags },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    dirty,
    backHandler,
    storyAction,
    apiLoading,
  } = props;

  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleSuggestions = (suggestionText: string, action: string) => {
    if (action === "add") {
      setSuggestions([...suggestions, suggestionText]);
    } else {
      const remainingSuggestions = suggestions.filter((suggestion) => suggestionText !== suggestion);
      setSuggestions(remainingSuggestions);
    }
  };

  const getAllSuggestions = async () => {
    const response = await UserExperienceAPI.getHashtagsSuggestions(
      // user.sub.replace("|", "_")
      // "auth0_63e37f81b4b776e79a48af87"
      getAuthenticatedUserId()
    );
    setSuggestions(response?.hashTags || []);
  };

  useEffect(() => {
    props.validateForm();
    getAllSuggestions();
  }, []);

  const addTagToList = (tagValue: any, type: string) => {
    if (type === "add") {
      let tags = Object.assign([], hashTags);
      tags.push(tagValue);
      handleChange({ target: { name: "hashTags", value: tags } });
    } else {
      handleChange({ target: { name: "hashTags", value: tagValue } });
    }

    setFieldTouched("hashTags", true, false);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, props)} style={{ width: "100%" }}>
      <Grid container>
        <Grid xs={12} mb={6} item>
          <Typography variant="body1" mt={4}>
            Add hashtags for your date
          </Typography>
          <Typography variant="subHeading5" color="greyTint9" mt={2} mb={4}>
            (Max 10 hashtags)
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-flex align-items-center justify-content-center" px={2}>
          <TagsTextarea
            tags={hashTags}
            onChange={addTagToList}
            suggestions={suggestions}
            handleSuggestions={handleSuggestions}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={4}>
          <FormActions
            primaryButtonClickHandler={handleSubmit}
            primaryButtonLabel={storyAction === "add" ? "Submit" : "Update"}
            primaryButtonLoading={apiLoading}
            secondaryButtonLabel="Back"
            secondaryButtonClickHandler={() => backHandler(2)}
            primaryButtonDisabled={!isValid}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={4}>
        <img alt="logo" src={logo} width={150}/>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddStoryStepThreeForm;
