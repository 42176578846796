import { FunctionComponent, useState } from "react";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

import { DashboardAPI } from "../../../apis/DashboardAPI";

import ViewProfileDialog from "../../../components/ViewProfileDialog";
import ConfirmirmationDialog from "../../../components/ConfirmirmationDialog";

import disableUserImage from "./../../../assets/images/disable-user.svg";
import deleteUserImage from "./../../../assets/images/delete-user.svg";

interface AdminUserActionsProps {
  data?: any;
  showDelete?: boolean;
  currentTab?: number;
  updateTable: (value: boolean) => void;
}

const AdminUserActions: FunctionComponent<AdminUserActionsProps> = ({ data, currentTab, updateTable }) => {
  const [openProfileDialog, setOpenProfileDialog] = useState<boolean>(false);
  const [openDisableUserDialog, setOpenDisableUserDialog] = useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const handleDisableUser = async () => {
    setApiLoading(true);
    try {
      const response = await DashboardAPI.disableUser(data?.userId);
      setTimeout(() => {
        setApiLoading(false);
        if (!!response) setOpenDisableUserDialog(false);
        updateTable(true);
      }, 2000);
    } catch (error) {
      setApiLoading(false);
      return error;
    }
  };

  const handleDeleteUser = async () => {
    setApiLoading(true);
    try {
      const response = await DashboardAPI.deleteUser(data?.userId);
      setTimeout(() => {
        setApiLoading(false);
        if (!!response) setOpenDeleteUserDialog(false);
        updateTable(true);
      }, 2000);
    } catch (error) {
      setApiLoading(false);
      return error;
    }
  };

  return (
    <>
      <Tooltip title="View">
        <VisibilityIcon
          sx={{ color: "secondary.light", mr: 1.75, cursor: "pointer" }}
          onClick={() => setOpenProfileDialog(true)}
        />
      </Tooltip>
      {currentTab === 0 ? (
        <Tooltip title="Disable">
          <PersonOffIcon sx={{ color: "burgundy", cursor: "pointer" }} onClick={() => setOpenDisableUserDialog(true)} />
        </Tooltip>
      ) : (
        <Tooltip title="Delete">
          <DeleteIcon sx={{ color: "redCarnation", cursor: "pointer" }} onClick={() => setOpenDeleteUserDialog(true)} />
        </Tooltip>
      )}
      {openProfileDialog && <ViewProfileDialog userId={data?.userId} onClose={() => setOpenProfileDialog(false)} />}
      {openDisableUserDialog && (
        <ConfirmirmationDialog
          image={disableUserImage}
          title="Disable user?"
          description="Are you sure you want to disable this user from Datehouse"
          primaryButtonLabel="Disable"
          apiLoading={apiLoading}
          onClose={() => setOpenDisableUserDialog(false)}
          primaryButtonHandler={handleDisableUser}
        />
      )}
      {openDeleteUserDialog && (
        <ConfirmirmationDialog
          image={deleteUserImage}
          title="Delete user?"
          description="Are you sure you want to delete this user permanently from Datehouse"
          primaryButtonLabel="Delete"
          apiLoading={apiLoading}
          onClose={() => setOpenDeleteUserDialog(false)}
          primaryButtonHandler={handleDeleteUser}
        />
      )}
    </>
  );
};

export default AdminUserActions;
