import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_API_URL;
const USER_PROFILE_ENDPOINT = "/content";

export const GoogleMapGeoCodeApi = {
  getAddressByLatLng: async function (latLng: string, cancel = false) {
    const mapKey = process.env.REACT_APP_MAP_KEY || "";
    try{
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${mapKey}`);
        const {results} = response.data;
        return results;
    }
    catch(e){
        console.log(e);
        return null;
    }
  },
};

