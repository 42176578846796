import { useState } from "react";

import { Grid } from "@mui/material";

import DateStoryListItem from "./DateStoryListItem";
import Dialog from "../Dialog";
import StoryListSkeleton from "../StoryList/StoryListSkeleton";

import DateStoryDetails from "./DateStoryDetails";
// import { WorldMapStoriesAPI } from "../../apis/WorldMapStoriesAPI";

interface IDateStoryListProps {
  apiLoading: boolean;
  dateStories: any;
  pageSize: number;
  setReachedStoryLimit: (value: boolean) => void;
}

const DateStoryList = ({
  apiLoading,
  dateStories,
  pageSize,
  setReachedStoryLimit,
}: IDateStoryListProps) => {
  const [openStoryDialog, setOpenStoryDialog] = useState<boolean>(false);
  const [storyData, setStoryData] = useState<any>({});

  const setStoryDataHandler = (storyData: any) => {
    setStoryData(storyData);
  };

  return (
    <Grid container spacing={3}>
      {apiLoading
        ? Array(pageSize)
            .fill(1)
            .map((element, i) => (
              <Grid item xs={12} md={6} sx={{ my: 1.5 }} key={i}>
                <StoryListSkeleton />
              </Grid>
            ))
        : dateStories.map((dateStoryDetails: any) => (
            <Grid item xs={12} md={6} key={dateStoryDetails.id} mt={1.5}>
              <DateStoryListItem
                dateStoryDetails={dateStoryDetails}
                setOpenStoryDialog={setOpenStoryDialog}
                setStoryDataHandler={setStoryDataHandler}
                setStoryLimitHandler={setReachedStoryLimit}
              />
            </Grid>
          ))}
      {openStoryDialog && (
        <Dialog onClose={() => setOpenStoryDialog(false)}>
          <DateStoryDetails storyData={storyData} showPostedDate/>
        </Dialog>
      )}
    </Grid>
  );
};

export default DateStoryList;
