import Keycloak from "keycloak-js";
// import keycloakConfig from "./keycloak.json";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_DOMAIN,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "datehousemap",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT || "datehousemap-public",
  "public-client": true,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
