import Box from "@mui/material/Box";

import QuestionAnswer from "../../components/QuestionAnswer.tsx";
import Button from "../../components/Button";

interface ILongSurveyFormProps {
  longSurveyFormDetails: any;
  errorObj: any;
  loading?: boolean;
  onChange: (id: string, value: string, questionDetails?: any, index?: number, parentQuestionId?: string) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

const LongSurveyForm = ({ longSurveyFormDetails, errorObj, loading, onChange, onSubmit }: ILongSurveyFormProps) => {
  return (
    <>
      {longSurveyFormDetails?.questions?.length && (
        <form onSubmit={onSubmit}>
          {longSurveyFormDetails?.questions.map((questionDetails: any) => (
            <QuestionAnswer
              key={questionDetails.questionId}
              questionDetails={questionDetails}
              errorObj={errorObj}
              onChange={onChange}
            />
          ))}
          <Box textAlign="center">
            <Button
              color="burgundy"
              type="submit"
              label="Submit your answers"
              variant="primaryDark"
              loading={loading}
            />
          </Box>
        </form>
      )}
    </>
  );
};

export default LongSurveyForm;
