import { FunctionComponent, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AdminDashboardCard from "./AdminDashboardCard";
import TabGroup from "../../../components/TabGroup";
import ColumnGraph from "../../../components/Graph";
import { UserProfileAPI } from "../../../apis/UserProfileAPI";
import { ApexOptions } from "apexcharts";
import barChartOptions from "../../../components/Graph/barChartOptions";
import { getDropDownRange } from "../../../utils/dateMethods";
import numberToStringConverter from "../../../utils/numberToStringConverter";
import skeletonBarChartOptions from "../../../components/Graph/skeletonBarChartOptions";
import GraphNoDataFound from "./GraphNoDataFound";
interface UserTrendsGraphProps {}

const UserTrendsGraph: FunctionComponent<UserTrendsGraphProps> = () => {
  const [graphSeriesData, setGraphSeriesData] = useState<ApexAxisChartSeries>([
    {
      name: `Number of active users`,
      data: [],
    },
  ]);
  const [skeletonChartSeries, setSkeletonChartSeries] = useState<ApexAxisChartSeries>([
    {
      name: "",
      data: [],
    },
  ]);
  const [loadingChartData, toggleChartLoadingState] = useState(true);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    ...barChartOptions,
  });
  const [selectedTab, setSelectTab] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState<string>("last_7_days");
  const tabsList = [
    {
      id: "activeUser",
      label: "Active Users",
      color: "#0067AC",
    },
    {
      id: "newSignups",
      label: "New signups",
      color: "#218380",
    },
    {
      id: "disabled",
      label: "Disabled users",
      color: "#FA0202",
    },
  ];
  const getUsersGraphData = async () => {
    try {
      graphSeriesData[0].data = [];
      setGraphSeriesData([...graphSeriesData]);
      // Adding type any for a reason
      // Adding array of nnumbers is causing issues

      setSkeletonChartSeries([{ ...skeletonChartSeries, data: [4, 2, 3, 4] }]);
      toggleChartLoadingState(true);
      const response = await UserProfileAPI.getUsersGraph(
        tabsList[selectedTab].id,
        getDropDownRange(selectedDateRange)
      );

      populateUserListResponse(response);
    } finally {
      toggleChartLoadingState(false);
    }
  };

  const populateUserListResponse = (userResponse?: any) => {
    if (!userResponse) return;
    graphSeriesData[0].data = userResponse.map(({ doc_count }: { doc_count: number }) => doc_count);

    graphSeriesData[0].color = tabsList[selectedTab].color;
    const dateRangeLabels: any = {
      last_7_days: "Last 7 days",
      last_30_days: "Last 30 days",
      last_12_months: "Last 12 months",
    };

    setChartOptions({
      ...barChartOptions,
      dataLabels: {
        enabled: false,
        // formatter: function (val, { seriesIndex, dataPointIndex, w }) {
        //   const changeInValue =
        //     ((w.config.series[seriesIndex].data[dataPointIndex] -
        //       (w.config.series[seriesIndex].data[dataPointIndex - 1] || 0)) /
        //       Math.max(
        //         ...[
        //           w.config.series[seriesIndex].data[dataPointIndex] || 0,
        //           w.config.series[seriesIndex].data[dataPointIndex - 1] || 0,
        //         ]
        //       )) *
        //     100;

        //   return val ? `${changeInValue > 0 ? "⬆ " : "⬇ "}${changeInValue.toFixed(2)}` + "%" : "";
        // },
        // offsetY: -20,
        // style: {
        //   fontSize: "12px",

        //   colors: [
        //     function ({ seriesIndex, dataPointIndex, w }: any) {
        //       const changeInValue =
        //         ((w.config.series[seriesIndex].data[dataPointIndex] -
        //           (w.config.series[seriesIndex].data[dataPointIndex - 1] || 0)) /
        //           w.config.series[seriesIndex].data[dataPointIndex]) *
        //         100;
        //       if (isNaN(changeInValue) || changeInValue > 0) {
        //         return selectedTab !== 2 ? "#218380" : "#FA0202";
        //       } else {
        //         return selectedTab === 2 ? "#218380" : "#FA0202";
        //       }
        //     },
        //   ],
        // },
      },
      xaxis: {
        ...barChartOptions.xaxis,
        title: { text: dateRangeLabels[selectedDateRange] },
        categories: [...userResponse.map(({ key }: { key: number }) => key)] || [],
      },
      yaxis: {
        ...barChartOptions.yaxis,
        title: {
          text: `Number of ${tabsList[selectedTab].label.toLowerCase()}`,
          offsetX: 2,
        },
        max: Math.max(...userResponse.map(({ doc_count }: { doc_count: number }) => doc_count)) + 5,
        labels: {
          show: true,
          formatter: function (val) {
            return numberToStringConverter(parseFloat(val.toFixed(1)));
          },
        },
      },

      tooltip: {
        enabled: true,
        custom: (context) => {
          return `<div class="user_trend_tooltip">${numberToStringConverter(
            context.series[0][context.dataPointIndex]
          )} ${tabsList[selectedTab].label}</div>`;
        },
      },
    });
    setGraphSeriesData([...graphSeriesData]);
  };

  const noDataContainerStyle = {
    height: 494,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    getUsersGraphData();
  }, [selectedTab, selectedDateRange]);

  return (
    <AdminDashboardCard
      title={"User trend & Drop trend"}
      subtitle={"Total user active & dropped over time"}
      showDropDown
      onSelectChange={(e) => setSelectedDateRange(e.target.value)}
    >
      <Box>
        <TabGroup tabs={tabsList} getCurrentTabValue={(newValue: number) => setSelectTab(newValue)} />

        {Boolean(!loadingChartData && graphSeriesData?.[0]?.data?.length) && (
          <ColumnGraph type="bar" seriesData={graphSeriesData} chartOptions={chartOptions} />
        )}
        {!loadingChartData && !graphSeriesData?.[0]?.data?.length && (
          <Box sx={noDataContainerStyle}>
            <GraphNoDataFound />
          </Box>
        )}
        {loadingChartData && (
          <ColumnGraph type="bar" seriesData={skeletonChartSeries} chartOptions={{ ...skeletonBarChartOptions }} />
        )}
      </Box>
    </AdminDashboardCard>
  );
};

export default UserTrendsGraph;
