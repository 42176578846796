import React from "react";
import ReactDOM from "react-dom/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";

import store from "./store";
import keycloak from "./keycloak";
import reportWebVitals from "./reportWebVitals";
import { keycloakInit, keycloakSetToken } from "./redux/actions/keycloakActions";

import App from "./App";

import "./assets/scss/variables/_colors.scss";
import "./assets/scss/variables/_variables.scss";

const tokenLogger = (tokens: unknown) => {
  store.dispatch(keycloakInit(keycloak));
  if (tokens) {
    store.dispatch(keycloakSetToken(keycloak.token!));
  }
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <ReactKeycloakProvider authClient={keycloak}>
      <App />
      <div id="toast-container-div"></div>
    </ReactKeycloakProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
