import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const CentroidDatesCountCard = ({
  currentZoom,
  latLng,
  locationName,
  experienceCount,
  onCentroidCardClick,
}: any) => {
  const [zoom, setZoom] = useState(currentZoom);

  const handleCentroidDatesCountCardClick = () => {
    onCentroidCardClick(latLng, zoom);
  };

  useEffect(() => {
    setZoom(currentZoom);
  }, [currentZoom]);

  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: "0px 0px 10px rgba(207, 206, 206)",
      }}
      onClick={handleCentroidDatesCountCardClick}
    >
      <Box pr={2.75} pl={1.5} py={1.5}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography mr={0.5} variant="h2">
            {experienceCount}
          </Typography>
          <Typography mb={0.25} variant="subText2">
            date stories
          </Typography>
        </Box>
        <Typography
          mb={1}
          variant="h3"
          component="div"
          color="redCarnationShade3"
        >
          {locationName.toUpperCase()}
        </Typography>
        <Typography variant="subHeading4" color="secondary.light">
          Click on card for details
        </Typography>
      </Box>
    </Box>
  );
};

export default CentroidDatesCountCard;
