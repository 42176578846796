// import { useAuth0, User } from "@auth0/auth0-react";
import * as yup from "yup";
import { Formik } from "formik";
import AddStoryStepOneForm from "./form";

export const MAX_WORDS_COUNT = 25;

const validationSchema = yup.object({
    feeling: yup.string().required("Please select an option"),
    shortNote: yup.string().test(
        "min_word_count",
        `Minimum ${MAX_WORDS_COUNT} words required`,
        val => {
            let valueArr = val?.trim().split(" ") || [];
          return valueArr.length >= MAX_WORDS_COUNT;
        }
      ).required("Explanation is required"),
    songLink: yup.string()
});

const AddStoryStepOne = (props: any) => {
    const { successHandler, experienceData } = props;

    const values = {
        // feeling: experienceData.feeling ? experienceData.feeling === 1 ? "worst" : "amazing" : "",
        feeling: experienceData.feeling,
        shortNote: experienceData.shortNote || "",
        songLink: experienceData.songLink || ""
    };
    
    const handleSubmit = (data: any) => {
        successHandler(2, {
            ...data,
            // feeling: data.feeling === "worst" ? 1 : 2
            feeling: data.feeling
        });
    };

    return (
        <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => <AddStoryStepOneForm {...props} />}
        </Formik>
    );
};

export default AddStoryStepOne;
