import { Card, Skeleton, Stack } from "@mui/material";

import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const cardStyling = {
  padding: "1.5rem",
  borderRadius: "1rem",
};

type Props = {};

const StoryListSkeleton = (props: Props) => {
  const isMobileDevice = useCheckMobileScreen();
  
  return (
    <Card sx={cardStyling} className="d-flex justify-content-between align-items-end">
      <Stack>
        <Stack spacing={1.5} mb={3.5}>
          <Skeleton variant="rounded" width={isMobileDevice ? 200 : 344} height={24} />
          <Skeleton variant="rounded" width={isMobileDevice ? 150 : 223} height={12} />
        </Stack>
        <Skeleton variant="rounded" width={isMobileDevice ? 176 : 279} height={12} />
      </Stack>
      <Skeleton variant="rounded" width={isMobileDevice ? 100 : 157} height={isMobileDevice ? 32 : 42} />
    </Card>
  );
};

export default StoryListSkeleton;
