import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TabGroup from "../../../components/TabGroup";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";
import DownloadIcon from "@mui/icons-material/Download";

import getAdminReportFilters from "../../../utils/getAdminReportFilters";
import { UserExperienceAPI } from "../../../apis/UserExperienceAPI";
import { LongSurveyAPI } from "../../../apis/LongSurveyAPI";

import AdminLayout from "../../../layouts/AdminLayout";
import Table from "../../../components/Table";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import AdminReportActions from "./AdminReportActions";

const AdminReports = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [filtersData, setFiltersData] = useState<any>({
    ageGroups: [],
    genders: [],
    regions: [],
    nationalities: [],
    experienceRates: [],
  });
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: any }>({});
  const [currentVisibleUsersCount, setCurrentVisibleUsersCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [filtersSubmitted, setFiltersSubmitted] = useState(false);
  const [focused, setFocused] = useState("");
  const [downloading, setDownloading] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const shortStoriesColumns: any = [
    {
      id: "createdDate",
      label: "Posted date",
      minWidth: 211,
      format: (value: any) => format(new Date(value), "dd MMMM yyyy"),
    },
    { id: "locationName", label: "Date location", minWidth: 211 },
    { id: "continent", label: "Region", filterOptions: filtersData?.regions, minWidth: 120 },
    { id: "ageId", label: "Age", filterOptions: filtersData?.ageGroups, minWidth: 120 },
    { id: "genderId", label: "Gender", filterOptions: filtersData?.genders, minWidth: 73 },
    { id: "rating", label: "Experience rate", filterOptions: filtersData?.experienceRates, minWidth: 120 },
    { id: "actions", component: AdminReportActions, minWidth: 170, align: "right" },
  ];

  const longSurveyColumns: any = [
    {
      id: "datetime",
      label: "Posted date",
      minWidth: 211,
      format: (value: any) => format(new Date(value), "dd MMMM yyyy"),
    },
    { id: "name", label: "User name", minWidth: 211 },
    {
      id: "nationalityId",
      label: "Nationality",
      filterOptions: filtersData?.nationalities,
      minWidth: 120,
      typeMatchBypass: true,
    },
    { id: "ageId", label: "Age", filterOptions: filtersData?.ageGroups, minWidth: 120 },
    { id: "genderId", label: "Gender", filterOptions: filtersData?.genders, minWidth: 73 },
    { id: "actions", component: AdminReportActions, minWidth: 170, align: "right" },
  ];

  const tabsList = [
    { id: "shortStories", label: "Short stories" },
    { id: "longSurveys", label: "Long surveys" },
  ];

  const onFocus = (id: string) => setFocused(id);
  const onBlur = () => setFocused("");

  const handleFilterChange = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAppliedFilters({
      ...appliedFilters,
      [key]: value,
    });
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
    setAppliedFilters({});
    setFiltersSubmitted(true);
  };

  const handleDownloadShortStoriesCSV = async () => {
    setDownloading(true);
    try {
      const response = await UserExperienceAPI.downloadShortStoriesCSV(appliedFilters);
      if (!!response) window.open(response, "_blank");
      setDownloading(false);
    } catch (error) {
      return error;
    } finally {
      setDownloading(false);
    }
  };

  const handleSubmitFilters = async () => {
    setFiltersSubmitted(true);
  };

  const handleClearFilters = () => {
    setFiltersSubmitted(true);
    setAppliedFilters({});
  };

  useEffect(() => {
    (async () => {
      const filtersDataResponse: any = await getAdminReportFilters(true);
      setFiltersData(filtersDataResponse);
    })();
  }, []);

  return (
    <AdminLayout>
      <>
        <Box>
          <Typography mb={1} variant="h1">
            Reports
          </Typography>
          <Typography mb={1.25} variant="subText2" color="blackTint4" component="p">
            Here are the list of all the stories of Datehouse from all over the world
          </Typography>
        </Box>
        <Box mb={3}>
          <TabGroup tabs={tabsList} getCurrentTabValue={handleTabChange} />
        </Box>
        <Box mb={3}>
          <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box width={310} mr={2}>
                <InputField
                  placeholder={`Search by ${currentTab === 0 ? "date location" : "user name"}...`}
                  endAdornment={<SearchIcon fontSize="small" />}
                  value={appliedFilters?.searchTerm || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("searchTerm", e)}
                />
              </Box>
              {apiLoading ? (
                <Typography variant="subHeading1">Loading...</Typography>
              ) : (
                <Typography variant="subHeading1">
                  Showing {currentVisibleUsersCount} of {totalUsersCount} {tabsList[currentTab].label}
                </Typography>
              )}
            </Box>
            {currentTab === 0 && totalUsersCount ? (
              <Box>
                <Button
                  sx={{ mx: 1, backgroundColor: "secondary.light" }}
                  label="Download CSV"
                  loading={downloading}
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadShortStoriesCSV}
                />
              </Box>
            ) : null}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <InputField
                sx={{ mr: 1, width: 150 }}
                name="startDate"
                label="Start Date"
                type={appliedFilters.startDate || focused === "startDate" ? "date" : "text"}
                value={appliedFilters?.startDate}
                onFocus={() => onFocus("startDate")}
                onBlur={onBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("startDate", e)}
              />
              <InputField
                sx={{ mx: 1, width: 150 }}
                name="endDate"
                label="End Date"
                type={appliedFilters.endDate || focused === "endDate" ? "date" : "text"}
                value={appliedFilters?.endDate}
                onFocus={() => onFocus("endDate")}
                onBlur={onBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("endDate", e)}
              />
              {currentTab === 0 && (
                <InputField
                  sx={{ mx: 1, width: 200 }}
                  name="region"
                  label="Region"
                  value={appliedFilters?.region}
                  select
                  options={filtersData.regions}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("region", e)}
                />
              )}
              {currentTab === 1 && (
                <InputField
                  sx={{ mx: 1, width: 200 }}
                  name="nationalityId"
                  label="Nationality"
                  value={appliedFilters?.nationalityId}
                  select
                  options={filtersData.nationalities}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("nationalityId", e)}
                />
              )}
              <InputField
                sx={{ mx: 1, width: 150 }}
                name="ageGroup"
                label="Age Group"
                value={appliedFilters?.ageId}
                select
                options={filtersData.ageGroups}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("ageId", e)}
              />
              <InputField
                sx={{ mx: 1, width: 150 }}
                name="gender"
                label="Gender"
                value={appliedFilters?.genderId}
                select
                options={filtersData.genders}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("genderId", e)}
              />
              {currentTab === 0 && (
                <InputField
                  sx={{ mx: 1, width: 200 }}
                  name="experienceRate"
                  label="Experience Rate"
                  value={appliedFilters?.rating}
                  select
                  options={filtersData.experienceRates}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("rating", e)}
                />
              )}
            </Box>
            <Box>
              <Button sx={{ mx: 1 }} label="Apply" onClick={handleSubmitFilters} />
              <Typography
                sx={{ mx: 1, cursor: "pointer" }}
                variant="subHeading2"
                color="redCarnation"
                onClick={handleClearFilters}
              >
                Clear
              </Typography>
            </Box>
          </Box>
        </Box>
        <Table
          appliedFilters={appliedFilters}
          filtersSubmitted={filtersSubmitted}
          setFiltersSubmitted={(value: boolean) => setFiltersSubmitted(value)}
          columns={currentTab === 0 ? shortStoriesColumns : longSurveyColumns}
          apiMethods={[UserExperienceAPI.filterReports, LongSurveyAPI.filterLongSurveyReports]}
          updateCurrentCount={(currentCount: number) => setCurrentVisibleUsersCount(currentCount)}
          updateTotal={(total: number) => setTotalUsersCount(total)}
          rowsDataKey={["userDatingExperiences", "submitSurveys"]}
          currentTab={currentTab}
          updateLoadingStatus={(value: boolean) => setApiLoading(value)}
        />
      </>
    </AdminLayout>
  );
};

export default AdminReports;
