import { toast } from "../components/ToastNotification/ToastManager";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const DATING_EXPERIENCES_ENDPOINT = "/dating-experiences";
const GUEST_USER_DATING_EXPERIENCES_ENDPOINT = "/datingExperience";


export const WorldMapStoriesAPI = {
  getAllDatingExperiences: async function (data: any, successCallback = () => {}, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${DATING_EXPERIENCES_ENDPOINT}/search`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getAllDatingExperiences.name].handleRequestCancellation().signal
          : undefined,
      });

      // TODO: Add SuccessCallback

      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getAllDateStoriesByLocation: async function (
    data: any,
    pageNumber: number,
    successCallback = () => {},
    cancel = false
  ) {
    try {
      const response = await api.request({
        baseURL,
        url: `${DATING_EXPERIENCES_ENDPOINT}/locationSearch?offset=${pageNumber}&limit=10`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getAllDateStoriesByLocation.name].handleRequestCancellation().signal
          : undefined,
      });

      // TODO: Add SuccessCallback

      successCallback();
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getStoryForGuestUser: async function (storyId: string) {
    try {
      const response = await api.request({
        baseURL,
        url: `${DATING_EXPERIENCES_ENDPOINT}${GUEST_USER_DATING_EXPERIENCES_ENDPOINT}/${storyId}`,
        method: 'GET',
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: any) {
      toast.show({
        message: error.error,
        error: true,
      });
      return {
        status: error.statusCode,
        data: null,
      };
    }
  },
};

// defining the cancel API object for WorldMapStoriesAPI
const cancelApiObject: any = defineCancelApiObject(WorldMapStoriesAPI);
