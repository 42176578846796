import { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from "react-places-autocomplete";
import { Box, Divider, ListItem, ListItemButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import validateEnglishAddress from "../../utils/validateEnglishAddress";

import InputField from "../InputField";

import locatorIcon from "../../assets/icons/locator.svg";

import "./LocationSearchInput.scss";

const LocationSearchInput = ({ onLocationChange }: any) => {
  const [address, setAddress] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);

  const handleLocationClick = (location: Suggestion) => {
    let bounds: any;
    geocodeByAddress(location.description)
      .then((results: any) => {
        bounds = results[0].geometry.bounds;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        onLocationChange({ lat: latLng.lat, lng: latLng.lng }, location, bounds);
        setShowSuggestions(false);
      })
      .catch((error) => console.error("Error", error));
  };

  const handleChange = (address: string) => {
    if (!!address) {
      const isValidAddressInEnglish = validateEnglishAddress(address);
      if (!isValidAddressInEnglish) return;
    }
    setAddress(address);
    setShowSuggestions(true);
  };

  return (
    <PlacesAutocomplete value={address} onChange={handleChange}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box className="LocationSearchInput">
          <InputField
            {...getInputProps({
              placeholder: "Search country, state, city...",
            })}
            onBlur={undefined}
            endAdornment={<SearchIcon fontSize="small" />}
          />
          {loading && <Box>Loading...</Box>}
          {showSuggestions && (
            <Box
              sx={{
                backgroundColor: "white",
                width: "22.5rem",
                boxShadow: "0px 0px 22px #CFCECE",
                borderRadius: 1.5,
                mt: 1,
              }}
              className="autocomplete-dropdown-container"
            >
              {suggestions.map((suggestion) => (
                <Box key={suggestion.placeId}>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        alignItems: "start",
                        gap: 1.5,
                      }}
                      onClick={() => handleLocationClick(suggestion)}
                    >
                      <Box sx={{ mt: 0.5 }}>
                        <img src={locatorIcon} alt="locator-icon" />
                      </Box>
                      <Box>
                        <Box sx={{ mb: 0.5 }}>
                          <Typography variant="subHeading2">{suggestion.formattedSuggestion.mainText}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subText2">{suggestion.formattedSuggestion.secondaryText}</Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                  {suggestions.length === 1 ? <></> : <Divider sx={{ mx: 2, borderColor: "greyTint9" }} />}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
