import { Grid, Typography } from "@mui/material";

import FormActions from "../FormActions";
import Dialog from "../Dialog";

import "./ConfirmirmationDialog.scss";

const ConfirmirmationDialog = ({
  apiLoading = false,
  image,
  title,
  description,
  primaryButtonLabel,
  onClose,
  primaryButtonHandler,
}: any) => {
  return (
    <Dialog onClose={onClose}>
      <Grid
        container
        sx={{
          maxWidth: "100%",
          width: "16.75rem",
          mx: "auto",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <img src={image} className="confirmation_image" alt={title} />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1" mt={2}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} mt={6}>
          <FormActions
            primaryButtonLabel={primaryButtonLabel}
            secondaryButtonLabel="Cancel"
            primaryButtonLoading={apiLoading}
            primaryButtonClickHandler={primaryButtonHandler}
            secondaryButtonClickHandler={onClose}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmirmationDialog;
