import { Typography, Box } from "@mui/material"

interface IProfileDataItemProps {
    keyText: string,
    value: string
};

const ProfileDataItem: React.FC<IProfileDataItemProps> = ({
    keyText = "",
    value = ""
}) => {
    return (
        <Box>
            <Typography variant="body1" color="blackTint4" mb={0.5}>
                {keyText}
            </Typography>
            <Typography variant="h2">
                {value || "N/A"}
            </Typography>
        </Box>
    )
}

export default ProfileDataItem;