import { ListItem, ListItemButton } from "@mui/material"
import SingleSong from "./SingleSong";

const ResultItem = (props: any) => {
    const {
        handleSelect,
        song,
        setPlayingId,
        playingId
    } = props;

    return <ListItem disablePadding>
    <ListItemButton
      sx={{
        alignItems: "center",
        gap: 1.5,
        display: "flex",
      }}
      onClick={() => handleSelect(song)}
    >
        <SingleSong song={song} setPlayingId={setPlayingId} playingId={playingId}/>
    </ListItemButton>
  </ListItem>
}

export default ResultItem;