import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Typography } from "@mui/material";

import { PAGE_SIZE } from "../../constants/global";

import Pagination from "../common/Pagination";

interface TableProps {
  appliedFilters?: any;
  filtersSubmitted?: boolean;
  setFiltersSubmitted?: any;
  rowsDataKey: Array<string>;
  rows?: Array<any>;
  headerChecked?: boolean;
  columns: Array<any>;
  children?: any;
  loading?: boolean;
  APIURL?: string;
  APIURLKey?: string;
  apiMethods?: any;
  updateCurrentCount?: any;
  updateTotal?: any;
  currentTab?: number;
  updateLoadingStatus?: any;
}

const Table = ({
  appliedFilters,
  filtersSubmitted,
  setFiltersSubmitted,
  rowsDataKey,
  // rows,
  columns,
  loading,
  APIURL,
  apiMethods,
  updateCurrentCount,
  updateTotal,
  currentTab = 0,
  updateLoadingStatus,
}: TableProps) => {
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [updateTable, setUpdateTable] = useState<boolean>(false);

  const getData = async (page: number) => {
    setCurrentPage(page);
    setApiLoading(true);
    // update loading status in parent
    updateLoadingStatus(true);
    try {
      const response = await apiMethods[currentTab](appliedFilters, page - 1);
      setTotalPages(Math.ceil(response.count / PAGE_SIZE));
      setRows(response[rowsDataKey[currentTab]] || []);
      setApiLoading(false);
      updateTable && setUpdateTable(false);
      // updates in parent
      updateCurrentCount && updateCurrentCount(response[rowsDataKey[currentTab]].length);
      updateTotal && updateTotal(response.count);
      filtersSubmitted && setFiltersSubmitted(false);
    } catch (error) {
      return error;
    } finally {
      setApiLoading(false);
      updateLoadingStatus(false);
    }
  };

  const getToPage = (event: React.ChangeEvent<unknown>, value: number) => {
    getData(value);
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSubmitted]);

  useEffect(() => {
    getData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  // useEffect(() => {
  //   setCurrentRowsDataKey(rowsDataKey);
  //   setCurrentApiMethod(apiMethod);
  // }, [rowsDataKey, apiMethod]);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 396px)", borderRadius: 2 }}>
          <TableMui stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ backgroundColor: "burgundy", minWidth: column.minWidth, color: "white" }}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!apiLoading && (
              <TableBody>
                {rows.map((row: any, index: number) => {
                  return (
                    <TableRow key={index} tabIndex={-1}>
                      {columns.map((column) => {
                        const value = column.filterOptions
                          ? column?.filterOptions?.find((item: any) =>
                              column.typeMatchBypass ? item.value == row[column.id] : item.value === row[column.id]
                            )?.label
                          : row[column.id];
                        const { component: Component }: any = column;
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {Component ? (
                              <Component
                                data={row}
                                appliedFilters={appliedFilters}
                                currentTab={currentTab}
                                updateTable={(value: boolean) => setUpdateTable(value)}
                              />
                            ) : column.format && value ? (
                              column.format(value)
                            ) : (
                              value || "-"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </TableMui>
        </TableContainer>
        {apiLoading && (
          <Typography variant="subHeading2" p={2} textAlign="center" component="p">
            Loading...
          </Typography>
        )}
        {!apiLoading && rows.length === 0 && (
          <Typography variant="subHeading2" p={2} textAlign="center" component="p">
            No Data Found
          </Typography>
        )}
      </Paper>
      {totalPages ? (
        <Grid xs={12} mt={4}>
          <Pagination activePage={currentPage} totalPages={totalPages} handleChange={getToPage} />
        </Grid>
      ) : null}
    </>
  );
};
export default Table;
