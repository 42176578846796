import { Typography, Box, Grid } from "@mui/material";
import BackButton from "../../BackButton";

interface IMainContainerProps {
  titleIcon?: JSX.Element;
  title: string;
  subtitle: string;
  children: JSX.Element;
}

const MainContainer = ({ titleIcon, title, subtitle, children }: IMainContainerProps) => {
  return (
    <Grid
      container
      sx={{
        height: "100%",
        py: { md: "7rem", xs: "3rem" },
        px: { md: "5rem", xs: "1rem" },
        overflow: "auto",
        position: "relative",
      }}
    >
      <BackButton
        sx={{
          position: "absolute",
          left: { md: "5rem", xs: "1rem" },
          top: { md: "7rem", xs: "3rem" },
        }}
      />
      <Grid item xs={0} lg={2}></Grid>
      <Grid item xs={12} lg={8}>
        <Box
          sx={{
            mb: 6,
            maxWidth: {
              xs: "80%",
              md: "100%",
            },
            mx: "auto",
          }}
          textAlign="center"
        >
          {titleIcon}
          <Typography variant="h1" mt={0.5}>
            {title}
          </Typography>
          <Typography variant="body1" mt={1} color="blackTint4">
            {subtitle}
          </Typography>
        </Box>
        {children}
      </Grid>
      <Grid item xs={0} lg={2}></Grid>
    </Grid>
  );
};

export default MainContainer;
