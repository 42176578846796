import { Dialog as MuiDialog, DialogContent, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

interface IDialogProps {
  showClose?: boolean;
  children?: JSX.Element;
  onClose?: () => void;
}

const Dialog: React.FC<IDialogProps> = ({
  showClose = true,
  children = null,
  onClose = () => null,
}) => {
  return (
    <MuiDialog onClose={onClose} open>
      <DialogContent>
        {showClose && (
          <IconButton
            sx={{ color:"greyTint9", position: "absolute", top: "1rem", right: "2rem" }}
            onClick={onClose}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
