import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { ListItemIcon, ListItemText, Typography } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IOption {
  icon: JSX.Element;
  text: string;
  color?: string;
  clickHandler: () => void;
}

interface IDropdownMenu {
  id: string;
  options: IOption[];
}

const DropdownMenu: React.FC<IDropdownMenu> = ({ id, options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  /**
   * @param event
   * @description Menu toggle handler
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles({
    list: {
      padding: '0.5rem !important', // Using important to override global css classes
    },
    lisItem: {
      height: '2.5rem !important',
      padding: '0 0.25rem !important',
    },
    popover: {
      marginTop: '-0.75rem !important',
    },
  });

  const classes = useStyles();

  return (
    <div className="DropdownMenu">
      <IconButton
        aria-label="more"
        id={`${id}-long-button"`}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ padding: 0, color: 'secondary.light' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`${id}-long-menu`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
          classes: {
            root: classes.list,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PopoverClasses={{
          paper: classes.popover,
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={option.clickHandler}
            classes={{
              root: classes.lisItem,
            }}
          >
            <ListItemIcon sx={{ color: option.color ?? 'darkBurgundy' }}>
              {option.icon}
            </ListItemIcon>
            <ListItemText>
              <Typography
                component="span"
                variant="subHeading3"
                sx={{ color: 'greyTint8' }}
              >
                {option.text}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
