import { toast } from "../components/ToastNotification/ToastManager";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const USER_PROFILE_ENDPOINT = "/user/profile";

export const UserProfileAPI = {
  updateUserProfile: async function (id: string, data: any, isUpdate: boolean = false, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/${id}`,
        method: isUpdate ? "PATCH" : "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.updateUserProfile.name].handleRequestCancellation().signal : undefined,
      });
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getUsersGraph: async function (id: string, queryParams?: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `user/profile/${id}`,
        method: "GET",
        params: queryParams,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getUsersGraph.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  getUserProfile: async function (id: string, notifyUser = true, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/${id}`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getUserProfile.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      if (notifyUser) {
        toast.show({
          message: errors.message,
          error: true,
        });
      } else {
        console.error(errors);
      }
    }
  },
  checkUserExist: async function (id: string, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/exists/${id}`,
        // url: `${baseURL}${USER_PROFILE_ENDPOINT}/${id}`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.checkUserExist.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  getTermsAndConditions: async function (cancel = false) {
    try {
      const response = await api.request({
        url: "https://meta-love-feast-images.s3.eu-west-2.amazonaws.com/tnc.json",
        method: "GET",
        // retrieving the signal value by using the property name
        signal: cancel
          ? cancelApiObject[this.getTermsAndConditions.name].handleRequestCancellation().signal
          : undefined,
      });

      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },

  // ADMIN API
  /**
   *
   * @param cancel
   * @returns
   */
  getGenderStatistics: async function (cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/gendersCount`,
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.getGenderStatistics.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
};

// defining the cancel API object for UserProfileAPI
const cancelApiObject: any = defineCancelApiObject(UserProfileAPI);
