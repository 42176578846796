import { Grid, Typography } from "@mui/material";
import RatingSlider from "../../RatingSlider";
import RatingButtons from "../../RatingButtons";
import FormActions from "../../FormActions";
import { ratingTextMap } from "../../../utils/dateRating";
import { useEffect } from "react";
import logo from "../../../assets/logos/datehouse.svg";

const AddStoryStepTwoForm = (props: any) => {
  const {
    values: {
      rating
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    dirty,
    backHandler
  } = props;

  useEffect(() => {
    props.validateForm();
  }, []);

  const setDateRating = (r: number) => {
    handleChange({ target: {name: "rating", value: r} });
    setFieldTouched("rating", true, false);
  }

  return (
    <form onSubmit={(e) => handleSubmit(e, props)} style={{ width: "100%" }}>
      <Grid container>
        <Grid xs={12} item>
            <Typography variant="body1" my={4}>
              How do you rate your date?
            </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className="d-flex align-items-center justify-content-center"
          px={2}
        >
          <RatingButtons onChange={setDateRating} currentValue={rating}/>
          {/* <RatingSlider onChange={setDateRating} currentValue={rating}/> */}
        </Grid>
        <Grid item xs={12} md={12} mt={4}>
          <FormActions
            primaryButtonClickHandler={handleSubmit}
            primaryButtonLabel="Next"
            secondaryButtonLabel="Back"
            secondaryButtonClickHandler={() => backHandler(1)}
            primaryButtonDisabled={!isValid}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={4}>
        <img alt="logo" src={logo} width={150}/>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddStoryStepTwoForm;
