import { ApexOptions } from "apexcharts";

const skeletonDonutChartOptions: ApexOptions = {
  dataLabels: { enabled: false },
  plotOptions: {
    pie: {
      donut: {
        size: "40",
        labels: {
          show: false,
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            showAlways: false,
            show: false,
          },
        },
      },
    },
  },
  legend: { show: false },
  colors: ["#EAECEF", "#F3F4F6"],
};
export default skeletonDonutChartOptions;
