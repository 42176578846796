import { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addDays, differenceInCalendarDays } from "date-fns";

import { LongSurveyAPI } from "../../../apis/LongSurveyAPI";
import { getAuthenticatedUserId } from "../../../utils/authDetails";

import Button from "../../Button";
import FormActions from "../../FormActions";
import LongSurveyInfoBanner from "../../LongSurveyInfoBanner";
import WorldDateStoriesInfoBanner from "../../WorldDateStoriesInfoBanner";
import SocialShareStory from "../../SocialShareStory";

import addStorySuccessImage from "../../../assets/images/story_add_success.svg";

import "./AddSuccess.scss";

const AddStorySuccess = (props: any) => {
  const navigate = useNavigate();
  const { restartFlow, storyAction, storyCreatedDate } = props;
  const editTillDate = addDays(new Date(storyCreatedDate), 7);
  const remainingDays = differenceInCalendarDays(editTillDate, new Date());
  const [isLongSurveySubmitted, setIsLongSurveySubmitted] = useState<boolean>(false);
  const [longSurveyBannerLoadedText, setLongSurveyBannerLoadedText] = useState<string>("");
  // const { user }: User = useAuth0();

  const checkLongSurveyStatus = async (surveyId: string) => {
    const response = await LongSurveyAPI.getLongSurveyByUserId(
      // user.sub.replace("|", "_"),
      // "auth0_63e37f81b4b776e79a48af87",
      getAuthenticatedUserId(),
      surveyId
    );
    setLongSurveyBannerLoadedText("loaded");
    if (!!response) {
      setIsLongSurveySubmitted(true);
    }
  };

  const navigateToMyStories = () => {
    navigate("/my-stories");
  };

  useEffect(() => {
    const surveyId = localStorage.getItem("surveyId");
    if (surveyId) {
      checkLongSurveyStatus(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container sx={{ maxWidth: "100%" }}>
      <Grid item xs={12}>
        <img src={addStorySuccessImage} className="success_image" alt="add_success" />
      </Grid>
      <Grid item xs={12} mt={4} mb={5}>
        <Typography variant="h2">{storyAction === "add" ? "Submitted" : "Updated"} successfully</Typography>
        <Typography variant="body1" mt={2} color="greyTint8">
          Yours answers are successfully {storyAction === "add" ? "submitted" : "updated"}.
        </Typography>
        <Typography variant="body1" width="60%" mx="auto" color="greyTint8">
          You can still make any change within {storyAction === "add" ? 7 : remainingDays} days
        </Typography>
        <SocialShareStory />
      </Grid>
      <WorldDateStoriesInfoBanner />
      {!isLongSurveySubmitted && longSurveyBannerLoadedText === "loaded" && (
        <Box px={2} mt={5}>
          <LongSurveyInfoBanner shortStoryFlow />
        </Box>
      )}
      <Grid item xs={12} md={12} mt={6}>
        {storyAction === "add" ? (
          <FormActions
            primaryButtonClickHandler={restartFlow}
            primaryButtonLabel="Start new"
            secondaryButtonLabel="View Submitted"
            secondaryButtonClickHandler={navigateToMyStories}
          />
        ) : (
          <Button label="Go to surveys" onClick={restartFlow} variant="primaryDark" />
        )}
      </Grid>
    </Grid>
  );
};

export default AddStorySuccess;
