import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import pageNotFoundImage from "./../../assets/images/404.jpg";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 5 }}>
      <Box sx={{ width: "50%", textAlign: "center" }}>
        <img style={{ width: "inherit" }} alt="404" src={pageNotFoundImage} />
      </Box>
      <Typography sx={{ cursor: "pointer" }} variant="subHeading1" color="burgundy" onClick={() => navigate("/")}>
        Go to Home
      </Typography>
    </Grid>
  );
};

export default PageNotFound;
