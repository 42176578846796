import { Typography, Box, IconButton } from "@mui/material";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseIcon from '@mui/icons-material/Pause';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useEffect, useRef, useState } from "react";

const SingleSong = (props: any) => {
    const {
        song,
        setPlayingId = () => {},
        playingId = "",
        removeSong = () => {},
        editSong = () => {},
        showAction = false,
        isOpen
    } = props;

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement>(null);

    const audioControl = (e: any) => {
        e.stopPropagation();
        setPlayingId(song.id);
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef.current?.play();
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        if (!showAction && (playingId !== song.id && audioRef?.current)) {
            audioRef.current?.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }, [playingId]);

    return (
        <Box sx={{
            alignItems: "center",
            gap: 1.5,
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        }}
            id="spotify-menu"
            aria-controls={isOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
        >
            <Box sx={{
                display: "flex",
                alignItems: "center"
            }}>
                <Box>
                    <Box
                        sx={{
                            width: showAction ? "40px" : "28px",
                            height: showAction ? "40px" : "28px",
                            position: "relative"
                        }}
                    >
                        <img
                            className="song-image"
                            src={
                                song?.album?.images?.[
                                    (song?.album?.images || []).length - 1
                                ]?.url
                            }
                            alt="song-img"
                        />
                        <IconButton className="audio-icon" onClick={audioControl}>
                            {isPlaying ? <PauseIcon sx={{color: "white", fontSize: "0.875rem"}}/> : <PlayCircleFilledWhiteIcon sx={{color: "white", fontSize: "0.875rem"}}/>}
                        </IconButton>
                        <audio
                            ref={audioRef}
                            className="song-audio"
                            src={song.preview_url}
                        />
                    </Box>
                </Box>
                <Box sx={{
                    ml: "0.5rem"
                }}>
                    <Typography variant={showAction ? "h3" : "subHeading3"}>
                        {song.name}
                    </Typography>
                    <Typography variant="smallText" component="p" textAlign="left">
                        {song?.album?.artists?.[0]?.name}
                    </Typography>
                </Box>
            </Box>
            {showAction && <Box sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <IconButton onClick={editSong}>
                    <CreateIcon sx={{color: "secondary.main", fontSize: "1rem"}}/>
                </IconButton>
                <IconButton onClick={removeSong}>
                    <DeleteIcon sx={{color: "redCarnation", fontSize: "1rem", ml: "0.5rem"}}/>
                </IconButton>
            </Box>}
        </Box>
    )
}

export default SingleSong;