import { FC, useState, ChangeEvent, useEffect } from 'react';

import { IOption } from './model';

import { Box, TextField, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const Option: FC<IOption> = ({ position, value, showDelete, handleChange }) => {
  const [data, setData] = useState('');

  const handleUpdate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    setData(event.target.value);
  };

  const saveChange = () => {
    handleChange(position, data);
  };

  useEffect(() => {
    setData(value);
  }, [value]);
  return (
    <>
      <Box
        className="d-flex align-items-center"
        sx={{
          position: 'relative',
          '& .clearButton': {
            display: 'none',
          },
          '&:hover .clearButton': {
            display: 'flex',
          },
        }}
      >
        {`${position}.`}
        <TextField
          sx={{
            padding: 2,
          }}
          fullWidth
          variant="standard"
          placeholder={`Option ${position}`}
          value={data}
          onBlur={saveChange}
          onChange={handleUpdate}
        />
        {showDelete && (
          <IconButton
            onClick={() => handleChange(position, '', 'delete')}
            className="clearButton"
            sx={{
              position: 'absolute',
              right: 0,
              top: 5,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Option;
