import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

import longSurveyBannerImage from "./../../assets/images/long-survey-banner-image.png";

type ILongSurveyInfoBannerProps = {
  shortStoryFlow?: boolean;
};

const LongSurveyInfoBanner: React.FC<ILongSurveyInfoBannerProps> = ({
  shortStoryFlow = false,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      p={2}
      mb={shortStoryFlow ? 0 : 5}
      sx={{ backgroundColor: "black", borderRadius: 3.5, border:"1px solid #E6E6E6"}}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "center",
            md: "initial",
          },
        }}
      >
        <Box mr={3} sx={{ width: 164, height: 110 }}>
          <img alt="long survey banner" src={longSurveyBannerImage} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: {
              xs: "center",
              md: "initial",
            },
          }}
          py={1}
          textAlign={{
            xs: "center",
            md: "left",
          }}
        >
          <Box>
            <Typography
              component="h2"
              mb={1}
              variant="subHeading1"
              color="darkBurgundy"
            >
              Help us build the best relationship site in the world? Join us
            </Typography>
            {!shortStoryFlow && (
              <Typography mb={2} variant="body1">
                Quick Quiz on your dating loves & loathes! Help us build the
                community driven & curated dating app of the future
              </Typography>
            )}
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: 1.5,
                textTransform: "initial",
              }}
              variant="outlined"
              color="blackTint1"
              label="Tell us more about you"
              textVariant="subHeading3"
              onClick={() => navigate("/long-survey")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LongSurveyInfoBanner;
