import { toast } from "../components/ToastNotification/ToastManager";
import { PAGE_SIZE } from "../constants/global";
import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const USER_PROFILE_ENDPOINT = "/user/profile";

export const DashboardAPI = {
  filterUsers: async function (data: any, pageNumber: number, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/search?offset=${pageNumber}&limit=${PAGE_SIZE}`,
        method: "POST",
        data,
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.filterUsers.name].handleRequestCancellation().signal : undefined,
      });
      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  disableUser: async function (userId: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/disable/${userId}`,
        method: "PUT",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.disableUser.name].handleRequestCancellation().signal : undefined,
      });

      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
  deleteUser: async function (userId: any, cancel = false) {
    try {
      const response = await api.request({
        baseURL,
        url: `${USER_PROFILE_ENDPOINT}/${userId}`,
        method: "DELETE",
        headers: {
          Authorization: getAuthToken(),
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.deleteUser.name].handleRequestCancellation().signal : undefined,
      });

      // returning the age groups returned by the API
      return response.data;
    } catch (errors: any) {
      toast.show({
        message: errors.message,
        error: true,
      });
    }
  },
};

// defining the cancel API object for DashboardAPI
const cancelApiObject: any = defineCancelApiObject(DashboardAPI);
