import { getAuthToken } from "../utils/authDetails";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const REFERENCE_DATA_ENDPOINT = "/reference-data";

const USER_PROFILE_METADATA_ENDPOINTS = {
  ageGroup: `${REFERENCE_DATA_ENDPOINT}/age-group`,
  gender: `${REFERENCE_DATA_ENDPOINT}/gender`,
  occupation: `${REFERENCE_DATA_ENDPOINT}/occupation`,
  nationality: `${REFERENCE_DATA_ENDPOINT}/nationality`,
  relationshipStatus: `${REFERENCE_DATA_ENDPOINT}/relationship-status`,
};

export const UserProfileMetadataAPI = {
  getAllAgeGroups: async function (cancel = false) {
    const response = await api.request({
      baseURL,
      url: USER_PROFILE_METADATA_ENDPOINTS.ageGroup,
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getAllAgeGroups.name].handleRequestCancellation().signal : undefined,
    });
    // returning the age groups returned by the API
    return response.data;
  },
  getAllGender: async function (cancel = false) {
    const response = await api.request({
      baseURL,
      url: USER_PROFILE_METADATA_ENDPOINTS.gender,
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
      signal: cancel ? cancelApiObject[this.getAllGender.name].handleRequestCancellation().signal : undefined,
    });
    // returning the genders returned by the API
    return response.data;
  },
  getAllOccupation: async function (cancel = false) {
    const response = await api.request({
      baseURL,
      url: USER_PROFILE_METADATA_ENDPOINTS.occupation,
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getAllOccupation.name].handleRequestCancellation().signal : undefined,
    });
    // returning the occupations returned by the API
    return response.data;
  },
  getAllNationality: async function (cancel = false) {
    const response = await api.request({
      baseURL,
      url: USER_PROFILE_METADATA_ENDPOINTS.nationality,
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
      signal: cancel ? cancelApiObject[this.getAllNationality.name].handleRequestCancellation().signal : undefined,
    });
    // returning the nationality returned by the API
    return response.data;
  },
  getAllRelationshipStatus: async function (cancel = false) {
    const response = await api.request({
      baseURL,
      url: USER_PROFILE_METADATA_ENDPOINTS.relationshipStatus,
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
      signal: cancel
        ? cancelApiObject[this.getAllRelationshipStatus.name].handleRequestCancellation().signal
        : undefined,
    });
    // returning the relationship status returned by the API
    return response.data;
  },
};

// defining the cancel API object for UserProfileMetadataAPI
const cancelApiObject: any = defineCancelApiObject(UserProfileMetadataAPI);
