/* eslint-disable no-debugger */
import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import { toast } from "./ToastManager";

const TOAST_AUTOCLOSE_DEFAULT_TIME = 3000;

export interface ToastNotificationProps {
  id?: string;
  message: string;
  error?: boolean;
}

export const ToastNotification: React.FC<ToastNotificationProps> = ({ message, error = false }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    toast.destroy();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={TOAST_AUTOCLOSE_DEFAULT_TIME}
      onClose={handleClose}
    >
      <Alert severity={error ? "error" : "success"} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
