// import { useAuth0, User } from "@auth0/auth0-react";
import * as yup from "yup";
import { Formik } from "formik";
import AddStoryStepThreeForm from "./form";

const validationSchema = yup.object({
  hashTags: yup
    .array()
    .of(yup.string())
    .min(1, "Please add at least one tag")
    .required("Please add at least one tag"),
});

const AddStoryStepThree = (props: any) => {
  const {
    successHandler,
    experienceData,
    backHandler,
    storyAction,
    apiLoading,
  } = props;

  const values = {
    hashTags: experienceData.hashTags || [],
  };

  const handleSubmit = (data: any) => {
    successHandler(
      4,
      {
        ...data,
      },
      true
    );
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <AddStoryStepThreeForm
          storyAction={storyAction}
          apiLoading={apiLoading}
          backHandler={backHandler}
          {...props}
        />
      )}
    </Formik>
  );
};

export default AddStoryStepThree;
