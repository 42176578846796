import { useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import Chip from "../../common/Chip";
import "../style.scss";

interface ISingleSelectAnswerProps {
  id: string;
  possibleAnswers: string[];
  questionDetails?: any;
  errorMessages?: string[];
  onChange: (id: string, value: string, questionDetails?: any) => void;
}

const SingleSelectAnswer = ({
  id,
  possibleAnswers,
  questionDetails,
  errorMessages,
  onChange,
}: ISingleSelectAnswerProps) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleClick = (answer: string) => {
    setSelectedOption(answer);
    onChange(id, answer, questionDetails);
  };

  return (
    <Box>
      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
        {(possibleAnswers || []).map((answer: any, i: number) => (
          <Box className="singleAnswer" key={i} sx={{ mr: { xs: 1, md: 3 }, mb: { xs: 1, md: 1.5 } }}>
            <Chip
              label={answer}
              color={selectedOption === answer ? "primary" : undefined}
              onClick={() => handleClick(answer)}
            />
          </Box>
        ))}
      </Stack>
      {errorMessages?.length && (
        <Box>
          {errorMessages?.map((message: string, index: number) => (
            <Typography
              sx={{ display: "block" }}
              key={index}
              variant="smallText"
              color="redCarnation"
            >
              {message}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SingleSelectAnswer;
