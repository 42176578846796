import { Box, Typography } from "@mui/material";

const DateLocationInfoCard = ({ locationName, experienceCount, onCardClick }: any) => {
  return (
    <Box
      className="DateLocationInfoCard"
      sx={{
        cursor: "pointer",
        backgroundColor: "black",
        boxShadow: "0px 0px 10px rgba(207, 206, 206)",
      }}
      onClick={onCardClick}
    >
      <Box p={1.5} sx={{ background:"black" }}>
        <Typography mb={1} variant="subHeading1" component="div" color="darkBurgundy">
          {locationName}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography mb={1} variant="subHeading2" color="greyTint8">
            {experienceCount} date {experienceCount === 1 ? "story" : "stories"}
          </Typography>
        </Box>
        <Typography variant="subHeading3"  color="darkBurgundy">
          Click to read {experienceCount === 1 ? "the story" : "these stories"}
        </Typography>
      </Box>
    </Box>
  );
};

export default DateLocationInfoCard;
