import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TabGroup from "../../../components/TabGroup";
import SearchIcon from "@mui/icons-material/Search";

import getAdminReportFilters from "../../../utils/getAdminReportFilters";
import { DashboardAPI } from "../../../apis/DashboardAPI";

import AdminLayout from "../../../layouts/AdminLayout";
import Table from "../../../components/Table";
import AdminUserActions from "./AdminUserActions";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";

const AdminUsers = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [filtersData, setFiltersData] = useState<any>({
    ageGroups: [],
    genders: [],
    nationalities: [],
    occupations: [],
  });
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: any }>({ isEnable: currentTab === 0 });
  const [currentVisibleUsersCount, setCurrentVisibleUsersCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [filtersSubmitted, setFiltersSubmitted] = useState(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const columns: any = [
    { id: "name", label: "Name", minWidth: 211 },
    { id: "nickname", label: "Nick name", minWidth: 211 },
    { id: "ageId", label: "Age", filterOptions: filtersData?.ageGroups, minWidth: 120 },
    { id: "genderId", label: "Gender", filterOptions: filtersData?.genders, minWidth: 73 },
    { id: "occupationId", label: "Occupation", filterOptions: filtersData?.occupations, minWidth: 120 },
    { id: "nationalityId", label: "Nationality", filterOptions: filtersData?.nationalities, minWidth: 120 },
    { id: "actions", component: AdminUserActions, minWidth: 170, align: "right" },
  ];

  const tabsList = [
    { id: "active", label: "Active" },
    { id: "disabled", label: "Disabled" },
  ];

  const handleFilterChange = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAppliedFilters({
      ...appliedFilters,
      [key]: value,
    });
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
    setAppliedFilters({
      ...appliedFilters,
      isEnable: value === 0,
    });
    setFiltersSubmitted(true);
  };

  const handleSubmitFilters = async () => {
    setFiltersSubmitted(true);
  };

  const handleClearFilters = () => {
    setFiltersSubmitted(true);
    setAppliedFilters({});
  };

  useEffect(() => {
    (async () => {
      const filtersDataResponse: any = await getAdminReportFilters();
      setFiltersData(filtersDataResponse);
    })();
  }, []);

  return (
    <AdminLayout>
      <>
        <Box>
          <Typography mb={1} variant="h1">
            Users
          </Typography>
          <Typography mb={1.25} variant="subText2" color="blackTint4" component="p">
            Here are the list of all the users of Datehouse from all over the world
          </Typography>
        </Box>
        <Box mb={3}>
          <TabGroup tabs={tabsList} getCurrentTabValue={handleTabChange} />
        </Box>
        <Box mb={3}>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={310} mr={2}>
              <InputField
                placeholder="Search by user name..."
                endAdornment={<SearchIcon fontSize="small" />}
                value={appliedFilters?.searchTerm || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("searchTerm", e)}
              />
            </Box>
            {apiLoading ? (
              <Typography variant="subHeading1">Loading...</Typography>
            ) : (
              <Typography variant="subHeading1">
                Showing {currentVisibleUsersCount} of {totalUsersCount} users
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <InputField
              sx={{ mr: 1, width: 200 }}
              name="nationalityId"
              label="Nationality"
              value={appliedFilters?.nationalityId}
              required
              select
              options={filtersData.nationalities}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("nationalityId", e)}
            />
            <InputField
              sx={{ mx: 1, width: 200 }}
              name="ageGroup"
              label="Age Group"
              value={appliedFilters?.ageId}
              required
              select
              options={filtersData.ageGroups}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("ageId", e)}
            />
            <InputField
              sx={{ mx: 1, width: 200 }}
              name="gender"
              label="Gender"
              value={appliedFilters?.genderId}
              required
              select
              options={filtersData.genders}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("genderId", e)}
            />
            <InputField
              sx={{ mx: 1, width: 200 }}
              name="occupation"
              label="Occupation"
              value={appliedFilters?.occupationId}
              required
              select
              options={filtersData.occupations}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange("occupationId", e)}
            />
            <Box>
              <Button sx={{ mx: 1 }} label="Apply" onClick={handleSubmitFilters} />
              <Typography
                sx={{ mx: 1, cursor: "pointer" }}
                variant="subHeading2"
                color="redCarnation"
                onClick={handleClearFilters}
              >
                Clear
              </Typography>
            </Box>
          </Box>
        </Box>
        <Table
          appliedFilters={appliedFilters}
          filtersSubmitted={filtersSubmitted}
          setFiltersSubmitted={(value: boolean) => setFiltersSubmitted(value)}
          columns={columns}
          apiMethods={[DashboardAPI.filterUsers, DashboardAPI.filterUsers]}
          updateCurrentCount={(currentCount: number) => setCurrentVisibleUsersCount(currentCount)}
          updateTotal={(total: number) => setTotalUsersCount(total)}
          rowsDataKey={["userProfiles", "userProfiles"]}
          currentTab={currentTab}
          updateLoadingStatus={(value: boolean) => setApiLoading(value)}
        />
      </>
    </AdminLayout>
  );
};

export default AdminUsers;
