import { useState } from "react";
import Box from "@mui/material/Box";

import Card from "../../components/Card";
import NavBar from "../../components/NavBar";
import DateLocationSearchCard from "../../components/DateLocationSearchCard";

const HomeCardMobile = ({ onLocationChange, onGeoLocationChange }: any) => {
  const [isSearchCardExpanded, setIsSearchCardExpanded] =
    useState<boolean>(false);
  return (
    <>
      <Box sx={{ position: "absolute", zIndex: 2, top: 0, width: "100%" }}>
        <Box
          sx={{
            px: 2,
            py: 2.5,
            boxShadow: "0px 0px 22px #CFCECE",
            backgroundColor: "black",
          }}
        >
          <NavBar />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          transform: isSearchCardExpanded
            ? ""
            : "translateY(calc(100vh - 236px))",
          width: "100%",
          transition: "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <Card
          sx={{
            py: 3,
            borderRadius: "32px 32px 0  0",
            height: "calc(100vh - 48px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DateLocationSearchCard
            isMobile
            isSearchCardExpanded={isSearchCardExpanded}
            setIsSearchCardExpanded={setIsSearchCardExpanded}
            onLocationChange={onLocationChange}
            onGeoLocationChange={onGeoLocationChange}
          />
        </Card>
      </Box>
    </>
  );
};

export default HomeCardMobile;
