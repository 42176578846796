import { Box, Skeleton } from "@mui/material";

import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const cardStyling = {
  padding: "1.5rem",
  borderRadius: "1rem",
};

const skeletonArr = [8, 5, 4, 9, 11];

const LongSurveySkeleton = () => {
  const isMobileDevice = useCheckMobileScreen();

  return (
    <>
      {skeletonArr.map((item, index) => (
        <Box key={index} sx={cardStyling}>
          <Skeleton sx={{ mb: 2 }} variant="rounded" height={40} />
          <Box className="d-flex flex-wrap">
            {Array(item)
              .fill(null)
              .map((item, index) => (
                <Skeleton
                  key={index}
                  sx={{ mr: 2, mb: 2 }}
                  variant="rounded"
                  width={isMobileDevice ? 100 : 157}
                  height={32}
                />
              ))}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default LongSurveySkeleton;
