import { FunctionComponent, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "../../../components/Card";
import { MenuItem, TextField } from "@mui/material";

interface AdminDashboardCardProps {
  title?: string;
  subtitle?: string;
  children?: any;
  showDropDown?: boolean;
  onSelectChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectOptions?: Array<any>;
}

const AdminDashboardCard: FunctionComponent<AdminDashboardCardProps> = ({
  title,
  subtitle,
  children,
  onSelectChange,
  selectOptions,
  showDropDown,
}) => {
  const cardStyle = { p: 3, m: 2, borderRadius: 6 };
  const [selectedDropdown, setSelectedDropDown] = useState<string>("last_7_days");

  const options = selectOptions
    ? selectOptions
    : [
        {
          label: "Last 7 days",
          value: "last_7_days",
        },
        {
          label: "Last 30 days",
          value: "last_30_days",
        },
        {
          label: "Last 12 months",
          value: "last_12_months",
        },
      ];
  return (
    <Card sx={cardStyle}>
      <div>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <div>
              <Typography variant="subHeading1">{title}</Typography>
            </div>
            <div>
              <Typography variant="subText2" color="blackTint4">
                {subtitle}
              </Typography>
            </div>
          </Grid>
          <Grid item md={3}>
            {showDropDown && (
              <TextField
                className="w-100 AdminDashboard-DateRangeSelect"
                value={selectedDropdown}
                required
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedDropDown(e.target.value);
                  onSelectChange && onSelectChange(e);
                }}
              >
                {options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )) || null}
              </TextField>
            )}
          </Grid>
        </Grid>
        <div>{children}</div>
      </div>
    </Card>
  );
};

export default AdminDashboardCard;
