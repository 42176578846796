import { ApexOptions } from "apexcharts";

const donutChartOptions: ApexOptions = {
  chart: { animations: { enabled: false } },

  plotOptions: {
    pie: {
      expandOnClick: false,

      donut: {
        size: "40",
        labels: {
          show: true,
          name: {
            show: true,
            color: undefined,
            offsetY: 5,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontWeight: "700",
          },
          total: {
            showAlways: true,
            show: true,
            fontSize: "16px",
            fontWeight: "700",
            label: "Date",
            formatter: function (w) {
              return "Experience";
            },
          },
        },
      },
    },
  },
  tooltip: { enabled: false },
  dataLabels: { enabled: false },
  legend: { show: false },
  colors: ["#218380", "#FA0202"],
  stroke: {
    width: 0,
  },
};
export default donutChartOptions;
