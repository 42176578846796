import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

const PrivateRoute = ({ children }: any) => {
  const isNewUser = localStorage.getItem("isNewUser");
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (isNewUser && JSON.parse(isNewUser) && !isLoggedIn) {
      navigate("/welcome");
    } else if (!isLoggedIn) {
      keycloak?.login({ redirectUri: window.location.origin });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoggedIn ? children : null;
};

export default PrivateRoute;
