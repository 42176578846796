import { useState, useRef } from "react";

import { Box, IconButton, Grid, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Button from "../Button";

import "./TagsTextarea.scss";

const KeyCodes = {
  backSpace: 8,
  enter: 13,
};

interface ITagsTextareaProps {
  tags: Array<string>;
  onChange?: (tag: any, type: string) => void;
  suggestions?: Array<string>;
  handleSuggestions?: any;
}

const TagsTextarea: React.FC<ITagsTextareaProps> = ({
  tags = [],
  onChange = () => {},
  suggestions = [],
  handleSuggestions = () => {},
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const tagText = useRef<any>("");
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [pickedSuggestions, setPickedSuggestions] = useState<string[]>([]);

  const handleDelete = (i: number, suggestion?: string) => {
    const currentTags = tags.filter((t, index) => index !== i);
    onChange(currentTags, "delete");
    if (pickedSuggestions.includes(suggestion || "")) {
      handleSuggestions(suggestion, "add");
    }
  };

  const istagPresent = (t: string) => {
    return tags.find((tag) => tag === t);
  };

  const addSuggestionTag = (suggestion: string) => {
    if (!istagPresent(suggestion) && tags.length < 10 && suggestion) {
      onChange(suggestion, "add");
      handleSuggestions(suggestion, "delete");
      setPickedSuggestions([...pickedSuggestions, suggestion]);
    }
  };

  const addTagButtonAction = () => {
    const value = tagText.current.value.replaceAll("#", "");

    addTag(value);
    setIsAddButtonDisabled(true);
  };

  const addTag = (value: string) => {
    // Add only if
    // 1. tag not present in list
    // 2. tag length < 10
    // 3. tag text is non-empty
    if (!istagPresent(value) && tags.length < 10 && value) {
      tagText.current.value = "";
      onChange(value, "add");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = tagText.current.value.replaceAll("#", "");
    setIsAddButtonDisabled(!Boolean(value));

    const { keyCode } = event;

    // Key = "enter" then Add tag
    if (keyCode === KeyCodes.enter) {
      event.preventDefault();

      addTag(value);
      setIsAddButtonDisabled(true);
    }

    // Key = "backspace" then remove tag
    else if (keyCode === KeyCodes.backSpace) {
      if (tags.length && !value) {
        handleDelete(tags.length - 1);
      }
    }
  };
  return (
    <Grid container>
      <Grid
        sx={{
          textAlign: "end",
        }}
        xs={12}
        item
      >
        <Box
          className={
            isFocused ? "focused react-tags-wrapper" : "react-tags-wrapper"
          }
        >
          {(tags || []).map((tag, i) => (
            <Box key={i}>
              <span className="tag-wrapper">
                <span>#{tag}</span>
                <IconButton
                  className="ReactTags__remove"
                  aria-label="delete"
                  onClick={() => handleDelete(i, tag)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            </Box>
          ))}
          <input
            ref={tagText}
            className="ReactTags__tagInput"
            type="text"
            name="hashtag"
            id="hashtag"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(event) => handleKeyDown(event)}
            placeholder={
              tags.length === 0
                ? "Type hashtags or add from suggestions....."
                : ""
            }
          />
        </Box>
        <Box className="d-block d-md-none text-align-end addButtonBox">
          <Button
            label="Add"
            textVariant="subHeading3"
            onClick={addTagButtonAction}
            disabled={isAddButtonDisabled}
            sx={{
              py: 1,
              pax: 2,
              minWidth: "unset",
              mt: 1,
            }}
          />
        </Box>
      </Grid>
      {suggestions.length > 0 && tags.length < 10 && (
        <Grid xs={12} item sx={{ textAlign: "left" }} mt={3}>
          <Typography variant="subHeading1" component="p" mb={1}>
            Suggestions
          </Typography>
          <Box className="suggestion_container d-flex align-items-center flex-wrap">
            {suggestions.map((suggestion) => (
              <>
                <Box
                  key={suggestion}
                  onClick={() => addSuggestionTag(suggestion)}
                >
                  <span className="tag-wrapper suggestion">
                    <span>#{suggestion}</span>
                  </span>
                </Box>
              </>
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default TagsTextarea;
