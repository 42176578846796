import { StepperProps, StepperClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiStepperTheme: {
  defaultProps?: Partial<StepperProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof StepperClasses,
      "MuiStepper",
      Omit<Theme, "components">
    >
  >;
} = {
    styleOverrides: {
        root: ({theme}) => {
            return {
                maxWidth: '100%',
                justifyContent: "center",
                " .MuiStep-root": {
                    flex: "none",
                    paddingLeft: "0.25rem",
                    paddingRight: "0.25rem",
                    "&:first-child": {
                        ".step-icon": {
                            borderTopLeftRadius: "0.5rem",
                            borderBottomLeftRadius: "0.5rem"
                        }
                    },
                    "&:last-child": {
                        ".step-icon": {
                            borderTopRightRadius: "0.5rem",
                            borderBottomRightRadius: "0.5rem"
                        }
                    },
                    "&.Mui-completed": {
                        ".step-icon": {
                            backgroundColor: theme.palette.primary.main
                        }
                    }
                }
            }
        },
    }
};