import { alpha } from "@mui/material/styles";
import { TextFieldProps, TextFieldClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTextFieldTheme: {
  defaultProps?: Partial<TextFieldProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TextFieldClasses, "MuiTextField", Omit<Theme, "components">>>;
} = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        "& .MuiInputLabel-root": {
          lineHeight: 2.29,
          left: 14,
          "&.MuiInputLabel-shrink": {
            ...theme.typography.subHeading4,
            color: theme.palette.blackTint1,
            fontSize: "17px",
            top: 3,
            "&.Mui-error": {
              color: theme.palette.redCarnation,
            },
          },
        },
        "& .MuiFilledInput-root": {
          height: 64,
          overflow: "hidden",
          borderRadius: 12,
          backgroundColor: theme.palette.greyTint7,
          border: `2px solid ${theme.palette.greyTint7}`,
          // minWidth: 200,
          width: "100%",
          "&.MuiInputBase-multiline": {
            height: "auto",
          },
          "&:hover": {
            border: `2px solid ${theme.palette.greyTint7}`,
          },
          "&.Mui-focused": {
            backgroundColor: `${alpha(theme.palette.burgundyTint9, 0.5)}`,
            border: `2px solid ${theme.palette.greyTint7}`,
            color: theme.palette.darkGrey,
          },
          "& > input, & > .MuiSelect-select": {
            ...theme.typography.body1,
            paddingLeft: 24,
            paddingBottom: 14,
            textAlign: "left",
          },
          "& > input": {
            ...theme.typography.body1,
            boxSizing: "border-box",
            height: 64,
          },
          "& > input::placeholder": {
            opacity:1
          },
          "& > textarea": {
            ...theme.typography.body1,
            fontWeight: 500,
            boxSizing: "border-box",
          },
          "& > textarea::placeholder": {
            opacity:1
          },
          "& > .MuiSelect-select": {
            paddingTop: 32,
          },
          "&.Mui-error": {
            background: theme.palette.redCarnationTint9,
            borderColor: theme.palette.redCarnation,
            "& > input, & > .MuiSelect-select": {
              color: theme.palette.redCarnation,
            },
          },
        },
        "& .MuiFormHelperText-root": {
          ...theme.typography.smallText,
          marginTop: 4,
          marginLeft: 0,
          fontStyle: "italic",
          color: theme.palette.greyTint8,
          "&.Mui-error": {
            color: theme.palette.redCarnation,
          },
        },
        "& .MuiList-root": {
          marginTop: 4,
        },
      };
    },
  },
};
