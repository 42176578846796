import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";

import theme from "./theme";
import { rootState } from "./store";
import { keycloakInit, keycloakSetToken } from "./redux/actions/keycloakActions";

import AdminPanelRouter from "./containers/AdminPanelRouter";
import Home from "./pages/Home";
import UserDetails from "./pages/UserDetails";
import Welcome from "./pages/Welcome";
import MyStories from "./pages/MyStories";
import DateStories from "./pages/DateStories";
import LongSurvey from "./pages/LongSurvey";
import Logout from "./pages/Logout";
import PageNotFound from "./pages/PageNotFound";

import Loader from "./components/Loader";
import WorldDateStories from "./components/WorldDateStories";
import PrivateRoute from "./components/PrivateRoute";

import "./App.scss";
import "./assets/scss/global.scss";

const App = () => {
  const keycloakState: any = useSelector((state: rootState) => state.keycloak.keycloak);
  const { initialized, keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const isLoggedIn = keycloak.authenticated;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isNewUser = localStorage.getItem("isNewUser");

  // create isNewUser variable in local storage if not available to check on home page where to redirect
  useEffect(() => {
    if (!isNewUser) {
      localStorage.setItem("isNewUser", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized && isLoggedIn && !keycloakState) {
      dispatch(keycloakInit(keycloak));
      dispatch(keycloakSetToken(keycloak.token!));
      setIsAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, isLoggedIn]);

  if (isNewUser && JSON.parse(isNewUser) && !isAuthenticated && !keycloakState) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/welcome" />} />
            <Route index path="/welcome" element={<Welcome />} />
            <Route path="/world-date-stories" element={<WorldDateStories />} />
            <Route path="/date-stories" element={<DateStories />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else if (!keycloakState && !isAuthenticated && initialized) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/world-date-stories" element={<WorldDateStories />} />
            <Route path="/date-stories" element={<DateStories />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else if (keycloakState && initialized) {
    if (isAuthenticated) {
      if (keycloakState?.tokenParsed?.groups?.includes("/admin")) {
        return <AdminPanelRouter />;
      }
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route path="/welcome" element={<Welcome />} />
              <Route
                path="/user-details"
                element={
                  <PrivateRoute>
                    <UserDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-stories"
                element={
                  <PrivateRoute>
                    <MyStories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/world-date-stories"
                element={
                  <PrivateRoute>
                    <WorldDateStories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/date-stories"
                element={
                  <PrivateRoute>
                    <DateStories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/long-survey"
                element={
                  <PrivateRoute>
                    <LongSurvey />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else {
      return <h1>Unable to Authenticate</h1>;
    }
  } else {
    return <Loader />;
  }
};

export default App;
