import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

const Logout = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    keycloak?.login({ redirectUri: window.location.origin });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Logout;
