import { Box } from "@mui/material";
import Button from "../Button";

interface IFormActionsProps {
  primaryButtonLabel?: string;
  primaryButtonVariant?:
    | "primaryDark"
    | "primaryLight"
    | "outlined"
    | "lightOutlined"
    | "blackOutlined"
    | "bright"
    | "iconWithTextButton";
  primaryButtonDisabled?: boolean;
  primaryButtonClickHandler: () => void;
  primaryButtonLoading?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonVariant?:
    | "primaryDark"
    | "primaryLight"
    | "outlined"
    | "lightOutlined"
    | "blackOutlined"
    | "bright"
    | "iconWithTextButton";
  secondaryButtonDisabled?: boolean;
  secondaryButtonClickHandler: () => void;
  secondaryButtonLoading?: boolean;
}

const FormActions: React.FC<IFormActionsProps> = ({
  primaryButtonLabel = "Submit",
  primaryButtonVariant = "primaryDark",
  primaryButtonDisabled = false,
  primaryButtonClickHandler = () => {},
  primaryButtonLoading = false,
  secondaryButtonLabel = "Reset",
  secondaryButtonVariant = "primaryLight",
  secondaryButtonDisabled = false,
  secondaryButtonClickHandler = () => {},
  secondaryButtonLoading = false,
}) => {
  return (
    <Box className="d-flex align-items-center justify-content-center">
      <Button
        color="burgundy"
        label={secondaryButtonLabel}
        type="button"
        variant={primaryButtonVariant}
        disabled={secondaryButtonDisabled || primaryButtonLoading}
        textVariant=""
        onClick={secondaryButtonClickHandler}
        loading={secondaryButtonLoading}
        sx={{
          mx: "0.5em",
        }}
      />
      <Button
        color="burgundy"
        label={primaryButtonLabel}
        type="button"
        variant={primaryButtonVariant}
        disabled={primaryButtonDisabled || primaryButtonLoading}
        textVariant=""
        onClick={primaryButtonClickHandler}
        loading={primaryButtonLoading}
        sx={{
          mx: "0.5rem",
        }}
      />
    </Box>
  );
};

export default FormActions;
