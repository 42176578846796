import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Button from "../../Button";
import DateOption from "../../DateOption";
import TextArea from "../../TextArea";
import SpotifyPicker from "../../SpotifyPicker";
import { MAX_WORDS_COUNT } from ".";
import { SAMPLE_STORY } from '../../../constants/stories';

import amazingIcon from "../../../assets/images/outstanding.svg";
import worstIcon from "../../../assets/images/disaster.svg";
import logo from "../../../assets/logos/datehouse.svg";

const AddStoryStepOneForm = (props: any) => {
  const [options, setOptions] = useState<Array<any>>(
    [
      {
        key: "1",
        text: "Amazing",
        image: amazingIcon,
        rating: 1,
        isSelected: false,
      },
      {
        key: "2",
        text: "Worst",
        image: worstIcon,
        rating: 2,
        isSelected: false,
      },
      {
        key: "3",
        text: "Amazing",
        image: amazingIcon,
        rating: 3,
        isSelected: false,
      },
      {
        key: "4",
        text: "Worst",
        image: worstIcon,
        rating: 4,
        isSelected: false,
      },
      {
        key: "5",
        text: "Amazing",
        image: amazingIcon,
        rating: 5,
        isSelected: false,
      },
    ]
  );

  const {
    values: { feeling, shortNote, songLink },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    dirty,
  } = props;

  let lastRatingSelected = 0;

  useEffect(() => {
    props.validateForm();
    options.forEach((item, index) => item.isSelected = index <= feeling-1);
  }, []);

  const setFeelingHandler = (rating: number) => {
    const lastRating = options.reduce((a, option)=>{
      return option.isSelected ? a+1 : a;
    },0)
    // if(lastRating === rating && rating === 1){
    //   options[0].isSelected = false;
    // }else {
    //   options.forEach((item, index) => item.isSelected = index <= rating-1);
    // }
    options.forEach((item, index) => item.isSelected = index <= rating-1);
    setOptions(options);
    handleChange({ target: { name: "feeling", value: rating } });
    setFieldTouched("feeling", true, false);
  };

  const setSongLinkHandler = (url: string) => {
    handleChange({ target: { name: "songLink", value: url } });
    setFieldTouched("songLink", true, false);
  };

  const handleInputChange = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange(event);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, props)} style={{ width: "100%" }}>
      <Grid container>
        <Grid xs={12} item>
          <Typography variant="body1" my={4}>
            How do you want to describe your date?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          {options.map((option) => (
            <DateOption
              key={option.key}
              option={option}
              setOption={setFeelingHandler}
              selected={feeling}
            />
          ))}
        </Grid>
        <Grid item xs={12} my={3}>
          <Typography variant="body1" my={2} component="p">
            In just a few words explain your date
          </Typography>
          <TextArea
            name="shortNote"
            placeholder={SAMPLE_STORY}
            value={shortNote}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange("shortNote", e)
            }
            rows={6}
          />
          <Typography
            sx={{ textAlign: "left" }}
            variant="smallText"
            mt={0.5}
            mb={3}
            component="p"
          >
            {shortNote
              ? `Min ${MAX_WORDS_COUNT} words (${shortNote.trim().split(" ").length} words entered)`
              : `(Min ${MAX_WORDS_COUNT} words)`}
          </Typography>
          <SpotifyPicker
            activeSong={songLink}
            setSongLink={setSongLinkHandler}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Button
            color="burgundy"
            type="submit"
            label="Next"
            variant="primaryDark"
            disabled={!isValid}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={4}>
        <img alt="logo" src={logo} width={150}/>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddStoryStepOneForm;
