import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import worldMapConnected from "./../../assets/images/world-map-connected.svg";

const WorldDateStoriesInfoBanner = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ backgroundColor: "sapphireBlueTint9", p: 2, mx: -4, cursor: "pointer" }}
      onClick={() => navigate("/world-date-stories")}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "initial",
        }}
      >
        <Box sx={{ width: 142, height: 106 }} mr={{ xs: 1, md: 3 }}>
          <img alt="world map banner" src={worldMapConnected} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "initial",
          }}
          py={1}
        >
          <Box
            sx={{
              width: {
                xs: "80%",
                md: "65%",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography component="h2" variant="h2" color="secondary.light" mb={{ xs: 1, md: 0 }} textAlign="left">
              Click here to see others date stories & share your favourites
            </Typography>
          </Box>
          <Box alignSelf="center" color="secondary.light">
            <KeyboardArrowRightIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorldDateStoriesInfoBanner;
