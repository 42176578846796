import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import "./InputField.scss";

interface IOptionTypeProps {
  value: string | number;
  label: string | number;
}

interface IInputFieldProps {
  sx?: any;
  name?: string;
  helperText?: string;
  initialValue?: string;
  label?: string;
  value?: string;
  options?: IOptionTypeProps[];
  disabled?: boolean;
  error?: boolean;
  select?: boolean;
  readOnly?: boolean;
  required?: boolean;
  endAdornment?: JSX.Element;
  placeholder?: string;
  multiline?: boolean;
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: any;
  onBlur?: any;
}

const CustomTextField = (props: TextFieldProps & IInputFieldProps) =>
  props.select ? (
    <>
      {props.options && props.options.length ? (
        <TextField {...props}>
          {props.options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )) || null}
        </TextField>
      ) : null}
    </>
  ) : (
    <TextField className={props.label ? "" : "input-field--without-label"} {...props} />
  );

const InputField: React.FC<IInputFieldProps> = ({
  sx,
  name,
  label,
  helperText = "",
  value = "",
  options = [],
  select = false,
  disabled = false,
  error = false,
  readOnly = false,
  required = false,
  endAdornment = <></>,
  placeholder,
  type,
  onChange,
  onFocus,
  onBlur,
}) => {
  return (
    <CustomTextField
      sx={sx}
      id={name}
      name={name}
      helperText={helperText}
      options={options}
      value={value}
      variant="filled"
      fullWidth
      disabled={disabled}
      error={error}
      required={required}
      select={select}
      InputProps={{
        endAdornment,
        readOnly,
        disableUnderline: true,
      }}
      onChange={onChange}
      {...(onFocus && { onFocus })}
      {...(onBlur && { onBlur })}
      {...(label ? { label } : { placeholder })}
      {...(type && { type })}
    />
  );
};

export default InputField;
