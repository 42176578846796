import { useKeycloak } from '@react-keycloak/web';
import { Card, Box, Typography } from '@mui/material';

import { WorldMapStoriesAPI } from '../../../apis/WorldMapStoriesAPI';
import Button from '../../Button';

import { format } from 'date-fns';

import './style.scss';

interface IDateStoryListItemProps {
  dateStoryDetails: any;
  setOpenStoryDialog: (state: boolean) => void;
  setStoryDataHandler: (storyDate: any) => void;
  setStoryLimitHandler: (value: boolean) => void;
}

const DateStoryListItem: React.FC<IDateStoryListItemProps> = ({
  dateStoryDetails,
  setOpenStoryDialog,
  setStoryDataHandler,
  setStoryLimitHandler,
}: IDateStoryListItemProps) => {
  const { keycloak } = useKeycloak();
  const formattedPostedDate = dateStoryDetails?.createdDate
    ? format(new Date(dateStoryDetails?.createdDate), 'dd MMMM yyyy')
    : '';

  const fetchStoryForGuestUser = async (storyId: string) => {
    const response = await WorldMapStoriesAPI.getStoryForGuestUser(storyId);
    if (response.status === 200) {
      openStoryDialog();
    } else if (response.status === 429) {
      setStoryLimitHandler(true);
    }
  };

  const openStoryDialog = () => {
    setStoryDataHandler(dateStoryDetails);
    setOpenStoryDialog(true);
  };

  const handleViewStoryClick = () => {
    if (keycloak.authenticated) {
      openStoryDialog();
    } else {
      fetchStoryForGuestUser(dateStoryDetails.id);
    }
  };

  return (
    <Card className="date-story-card">
      <Box className="text-align-start">
        <Box>
          <Typography variant="subHeading1" mb={0.5} color="darkBurgundy">
            {dateStoryDetails?.nickName || ""}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subHeading2" color="white" mr={0.5}>
            Posted on:
          </Typography>
          <Typography variant="subText2" color="greyTint8">
            {formattedPostedDate}
          </Typography>
        </Box>
      </Box>
      <Button
        label="View story"
        variant="outlined"
        color="blackTint1"
        onClick={handleViewStoryClick}
      />
    </Card>
  );
};

export default DateStoryListItem;
