import { Box, Card, List, Typography, Divider, CircularProgress, Menu } from "@mui/material"
import InputField from "../../InputField"
import SearchIcon from "@mui/icons-material/Search"
import ResultItem from "./ResultItem"
import Button from "../../Button"
import { useCallback, useState } from "react"
import { SpotifyAPI } from "../../../apis/SpotifyAPI"
import debounce from "lodash.debounce";

const SearchResultDrop = (props: any) => {
    const {
        handleSelect,
        spotifyToken,
        getSpotifyToken,
        open,
        handleClose,
        anchorEl
    } = props;

    const [query, setQuery] = useState("");
    const [spotifyListError, setSpotifyListError] = useState<boolean>(false);
    const [spotifyListLoading, setSpotifyListLoading] = useState<boolean>(false);
    const [songList, setSongList] = useState<any>([]);
    const [playingId, setPlayingId] = useState<string>("");
    const [selectedSongDetails, setSelectedSongDetails] = useState<any>(null);

    const menuCloseHandler = () => {
        setQuery("");
        setSongList([]);
        handleClose();
    }

    const selectHandler = (song: any) => {
        setQuery("");
        setSongList([]);
        handleSelect(song);
    }

    const searchTrackHandler = async (value: string) => {
        if (value) {
            setSpotifyListLoading(true);
            setSpotifyListError(false);
            try {
            const res = await SpotifyAPI.searchTracks(value, localStorage.getItem("spotify_token") || "");
            setSongList(res.data.tracks.items);
            setSpotifyListLoading(false);
            } catch (err: any) {
            setSpotifyListLoading(false);
            if (err.error.status === 401) {
                setSpotifyListLoading(true);
                getSpotifyToken().then(() => {
                    setSpotifyListLoading(false);
                });
            } else {
                setSpotifyListError(true);
            }
            }
        } else {
            setSongList([]);
        }
    };
    
    const searchDebounce = useCallback(debounce(searchTrackHandler, 300), []);

    const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        searchDebounce(value);
    };
    return <Menu
            id="spotify-menu"
            anchorEl={anchorEl}
            className="spotify-search-box"
            open={open}
            onClose={menuCloseHandler}
            PaperProps={{sx: {width: anchorEl ? anchorEl.clientWidth : "auto"}}}
        >
            <Box className="input-field-container">
                <InputField
                    placeholder="Search song.."
                    value={query}
                    endAdornment={<SearchIcon fontSize="small" />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleSearch(e)
                    }
                />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    flex: { xs: 1 },
                }}
            >
                {spotifyListError ? <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                    <Typography variant="subHeading2" color="primary.light">
                        Failed to load Spotify Songs
                    </Typography>
                    <Button variant="primaryLight" onClick={() => searchTrackHandler(query)} label="Retry" />
                </Box> : <>
                    {spotifyListLoading ? <CircularProgress size={15}/> : <List>
                        {songList.map((song: any) => (
                            <Box key={song.id}>
                                <ResultItem handleSelect={selectHandler} song={song} setPlayingId={setPlayingId} playingId={playingId}/>
                                {songList.length === 1 ? <></> : <Divider sx={{ mx: 6 }} />}
                            </Box>
                        ))}
                    </List>}
                </>}
            </Box>
    </Menu>
    
    // <Card className="spotify-search-box">
    //     {/* <Select
    //       value={selectedSongDetails}
    //       onChange={setSelectedSongDetails}
    //       displayEmpty
    //     >
    //       <MenuItem value="">
    //         <em>None</em>
    //       </MenuItem>
    //       <MenuItem value={10}>Ten</MenuItem>
    //       <MenuItem value={20}>Twenty</MenuItem>
    //       <MenuItem value={30}>Thirty</MenuItem>
    //     </Select> */}
    //     <Box className="input-field-container">
    //         <InputField
    //             placeholder="Search song.."
    //             value={query}
    //             endAdornment={<SearchIcon fontSize="small" />}
    //             onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
    //                 handleSearch(e)
    //             }
    //         />
    //     </Box>
        
    // </Card>
}

export default SearchResultDrop;