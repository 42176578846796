import { DialogProps, DialogClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiDialogTheme: {
  defaultProps?: Partial<DialogProps> | undefined;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof DialogClasses,
      "MuiDialog",
      Omit<Theme, "components">
    >
  >;
} = {
  styleOverrides: {
    root: {
      ".MuiDialog-container": {
        boxSizing: "border-box"
      }
    },
    paper: ({ theme }) => {
        return {
          backgroundColor:"#000",
          boxShadow: "0px 0px 22px #CFCECE",
          ".MuiDialogContent-root": {
            padding: "3rem 2rem",
          },
          [theme.breakpoints.up("sm")]: {
            borderRadius: "3.75rem",
            width: "calc(100% - 64px)",
            margin: "32px"
          },
          [theme.breakpoints.down("sm")]: {
            borderRadius: "1.75rem",
            width: "calc(100% - 24px)",
            margin: "12px"
          }
        }
    }
  },
};